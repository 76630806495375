import { Box, Flex, Text, theme } from '@chakra-ui/react';
import Image from 'next/image';
import { VFC } from 'react';
import { Storyblok } from 'types';

type InvestorTestimonialProps = {
  brandLogo: Storyblok.Image;
  quote: string;
  investorAvatar: Storyblok.Image;
  investorName: string;
  investorRole: string;
};

const InvestorTestimonial: VFC<InvestorTestimonialProps> = ({
  brandLogo,
  quote,
  investorAvatar,
  investorName,
  investorRole,
}) => {
  return (
    <Box
      backgroundColor={theme.colors.white}
      border="1px solid #0000001A"
      borderRadius="lg"
      p="24px"
      width="324px"
      height="min-content"
      display="flex"
      flexDirection="column"
      justifyContent="left"
      gap="16px"
    >
      <Text color={theme.colors.current} lineHeight="28px" mb="24px">
        {quote}
      </Text>
      <Box
        border={theme.borders['1px']}
        borderColor="#DCE0E7"
        m="-24px"
        p="24px"
        borderBottomRadius="8px"
        backgroundColor="#F3F5F8"
      >
        <Box position="relative" width="72px" height="72px">
          <Image src={investorAvatar.filename} layout="fill" alt={investorAvatar.alt} />
          {
            brandLogo?.filename
              ? (
                <Box position="absolute" width="40px" height="40px" left="40px" bottom="0" borderRadius="8px">
                  <Image
                    src={brandLogo?.filename}
                    objectFit="cover"
                    layout="fill"
                    alt={brandLogo.alt}
                    style={{ borderRadius: '8px' }}
                  />
                </Box>
              ) : undefined
          }
        </Box>
        <Flex gap="4px" direction="column">
          <Text fontSize="20px" fontWeight={600} color={theme.colors.current} lineHeight="24px">
            {investorName}
          </Text>
          <Text fontSize="16px" fontWeight={400} color={theme.colors.gray[700]} lineHeight="20px">
            {investorRole}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default InvestorTestimonial;
