import { useEffect, useState } from 'react';
import { Box, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import Image from 'next/image';
import { Logo, Logotype } from 'components/common/logos';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { imageDimensions } from 'utils/storyblok';

const MotionBox = m(Box);

const SlideshowSection = ({ slides, title, description, content, buttons, direction = 'ltr', size }) => {
  const [slidesIndex, setSlidesIndex] = useState(0);
  const slidesCount = slides.length;
  const { backgroundImage, backgroundColor, foregroundLogo, foregroundLogoColorMode, foregroundLogoDotColorMode } =
    slides[slidesIndex];

  useEffect(() => {
    const index = slidesIndex >= slidesCount - 1 ? 0 : slidesIndex + 1;
    setTimeout(() => setSlidesIndex(index), 5000);
  }, [slidesIndex, slidesCount]);

  const foregroundLogos = {
    Logo: Logo,
    Logotype: Logotype,
  };

  const ForegroundLogo = foregroundLogos[foregroundLogo];

  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridColumnGap="80px" gridRowGap="40px">
        <Flex
          position="relative"
          order={{ base: 1, desktop: direction === 'ltr' ? 1 : 2 }}
          alignItems="center"
          minHeight={{ base: '250px', desktop: '420px' }}
        >
          <LazyMotion features={domAnimation}>
            <AnimatePresence initial={false}>
              <MotionBox
                key={slidesIndex}
                as="figure"
                position="absolute"
                display="flex"
                borderRadius="4px"
                boxShadow="xl"
                alignItems="center"
                overflow="hidden"
                width="100%"
                height="100%"
                bg={backgroundColor}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  opacity: { duration: 1 },
                }}
              >
                {backgroundImage.filename && (
                  <Image
                    src={backgroundImage.filename}
                    alt={backgroundImage.alt}
                    height={imageDimensions(backgroundImage.filename).height}
                    width={imageDimensions(backgroundImage.filename).width}
                  />
                )}
              </MotionBox>
            </AnimatePresence>
          </LazyMotion>
          {foregroundLogo && (
            <Flex position="absolute" height="100%" width="100%" alignItems="center" justifyContent="center">
              <ForegroundLogo
                height={{ base: 30, desktop: 55 }}
                colorMode={foregroundLogoColorMode}
                dotColorMode={foregroundLogoDotColorMode}
              />
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center" order={{ base: 1, desktop: direction === 'rtl' ? 1 : 2 }}>
          <Box>
            <Heading as="h2" size="lg" mb="16px">
              {title}
            </Heading>
            <Text mb="24px">{description}</Text>
            <RichText content={content} indentList={false} />
            <Box mt="24px">
              <ButtonGroup buttons={buttons} alignItems="flex-start" width="auto" />
            </Box>
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
};

export default SlideshowSection;
