import { Grid } from "@chakra-ui/react";
import HubSpotCTA, { HubSpotCTAProps } from "components/common/RichText/HubSpotCTA";

type CallToActionsProps = {
  callToActions: HubSpotCTAProps[];
};

function CallToActions({ callToActions }: CallToActionsProps) {
  return (
    <Grid>
      {callToActions.map((callToAction, i) => {
        return <HubSpotCTA key={i} {...callToAction} />;
      })}
    </Grid>
  );
}

export default CallToActions;
