import { Box, Container, Heading, HStack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { VFC } from 'react';
import { theme } from 'theme';
import Image from 'next/image';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';
import DividerSection from './Divider';

export type CustomerStorySummaryProps = {
  challenges: string;
  clearedInvoices: string;
  clearedInvoicesLogo: Storyblok.Image;
  dsoReduction: string;
  dsoReductionLogo: Storyblok.Image;
  mainUsers: string;
  maturityStage: string;
  maturityAnchor: string;
  revenue: string;
  weeklyTimeSaved: string;
  weeklyTimeSavedLogo: Storyblok.Image;
};

const CustomerStorySummary: VFC<CustomerStorySummaryProps> = ({
  challenges,
  clearedInvoices,
  clearedInvoicesLogo,
  dsoReduction,
  dsoReductionLogo,
  mainUsers,
  maturityStage,
  revenue,
  weeklyTimeSaved,
  weeklyTimeSavedLogo,
}) => {
  const isMobile = useBreakpointValue({ base: true, desktop: false });
  return isMobile ? (
    <Container as="section" px="0">
      <VStack alignItems="flex-start">
        <Heading as="h2" size="lg" alignSelf="flex-start" pb="24px">
          Summary
        </Heading>
        <SummaryCard title="Revenue" value={revenue} />
        <Box py="24px" width="100%">
          <DividerSection color="#EEF0F3" />
        </Box>
        <SummaryCard title="Challenges" value={challenges} />
        <Box py="24px" width="100%">
          <DividerSection color="#EEF0F3" />
        </Box>
        <SummaryCard title="Main Users" value={mainUsers} />
        <Box py="24px" width="100%">
          <DividerSection color="#EEF0F3" />
        </Box>
        <SummaryCard title="Cash Collection Maturity Stage" value={maturityStage} />
        <Box py="24px" width="100%">
          <DividerSection color="#EEF0F3" />
        </Box>
        <HStack justifyContent="space-between" width="100%">
          <Text fontWeight={600} fontSize="16px" lineHeight="20px" color="#0B254B">
            {dsoReduction}{' '}
            <Text as="span" fontWeight={400} fontSize="15px" lineHeight="20px" color="#0B254BB2">
              reduction in DSO
            </Text>
          </Text>
          <Box width="32px">
            <Image
              src={dsoReductionLogo.filename}
              width={imageDimensions(dsoReductionLogo.filename).width}
              height={imageDimensions(dsoReductionLogo.filename).height}
              quality={100}
              alt={dsoReductionLogo.alt}
            />
          </Box>
        </HStack>
        <Box py="24px" width="100%">
          <DividerSection color="#EEF0F3" />
        </Box>
        <HStack justifyContent="space-between" width="100%">
          <Text fontWeight={600} fontSize="16px" lineHeight="20px" color="#0B254B">
            {clearedInvoices}{' '}
            <Text as="span" fontWeight={400} fontSize="15px" lineHeight="20px" color="#0B254BB2">
              of invoices cleared in 30 days
            </Text>
          </Text>
          <Box width="32px">
            <Image
              src={clearedInvoicesLogo.filename}
              width={imageDimensions(clearedInvoicesLogo.filename).width}
              height={imageDimensions(clearedInvoicesLogo.filename).height}
              quality={100}
              alt={clearedInvoicesLogo.alt}
            />
          </Box>
        </HStack>
        <Box py="24px" width="100%">
          <DividerSection color="#EEF0F3" />
        </Box>
        <HStack justifyContent="space-between" width="100%">
          <Text fontWeight={600} fontSize="16px" lineHeight="20px" color="#0B254B">
            {weeklyTimeSaved}{' '}
            <Text as="span" fontWeight={400} fontSize="15px" lineHeight="20px" color="#0B254BB2">
              saved weekly
            </Text>
          </Text>
          <Box width="32px">
            <Image
              src={weeklyTimeSavedLogo.filename}
              width={imageDimensions(weeklyTimeSavedLogo.filename).width}
              height={imageDimensions(weeklyTimeSavedLogo.filename).height}
              quality={100}
              alt={weeklyTimeSavedLogo.alt}
            />
          </Box>
        </HStack>
      </VStack>
    </Container>
  ) : (
    <Container as="section">
      <VStack>
        <Heading as="h2" size="lg" alignSelf="flex-start" pb="24px">
          Summary
        </Heading>
        <HStack gap="24px" pb="24px">
          <SummaryCard title="Revenue" value={revenue} />
          <SummaryCard title="Challenges" value={challenges} />
        </HStack>
        <HStack gap="24px" pb="24px">
          <SummaryCard title="Main Users" value={mainUsers} />
          <SummaryCard title="Cash Collection Maturity Stage" value={maturityStage} />
        </HStack>
        <HStack
          boxShadow={theme.shadows.lg}
          width="100%"
          px="24px"
          py="40px"
          gap="8px"
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <VStack width="240px">
            <Image
              src={dsoReductionLogo.filename}
              width={imageDimensions(dsoReductionLogo.filename).width}
              height={imageDimensions(dsoReductionLogo.filename).height}
              alt={dsoReductionLogo.alt}
            />
            <Text fontWeight={700} fontSize="24px" lineHeight="28px" color="#164992">
              {dsoReduction}
            </Text>
            <Text fontWeight={400} fontSize="14px" lineHeight="18px" color="#0B254B80">
              reduction in DSO
            </Text>
          </VStack>
          <VStack width="240px">
            <Image
              src={clearedInvoicesLogo.filename}
              width={imageDimensions(clearedInvoicesLogo.filename).width}
              height={imageDimensions(clearedInvoicesLogo.filename).height}
              alt={clearedInvoicesLogo.alt}
            />
            <Text fontWeight={700} fontSize="24px" lineHeight="28px" color="#164992">
              {clearedInvoices}
            </Text>
            <Text fontWeight={400} fontSize="14px" lineHeight="18px" color="#0B254B80">
              of invoices cleared in 30 days
            </Text>
          </VStack>
          <VStack width="240px">
            <Image
              src={weeklyTimeSavedLogo.filename}
              width={imageDimensions(weeklyTimeSavedLogo.filename).width}
              height={imageDimensions(weeklyTimeSavedLogo.filename).height}
              alt={weeklyTimeSavedLogo.alt}
            />
            <Text fontWeight={700} fontSize="24px" lineHeight="28px" color="#164992">
              {weeklyTimeSaved}
            </Text>
            <Text fontWeight={400} fontSize="14px" lineHeight="18px" color="#0B254B80">
              saved weekly
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Container>
  );
};

const SummaryCard = ({ title, value }) => {
  const isMobile = useBreakpointValue({ base: true, desktop: false });
  return isMobile ? (
    <VStack alignItems="flex-start" gap="4px">
      <Text fontWeight={500} fontSize="14px" lineHeight="20px" color="#0B254BB2">
        {title}
      </Text>
      <Text fontWeight={400} fontSize="14px" lineHeight="18px" color="#0B254B" mt="0 !important">
        {value}
      </Text>
    </VStack>
  ) : (
    <VStack
      boxShadow={theme.shadows.lg}
      width="388px"
      height="120px"
      p="24px"
      gap="8px"
      borderRadius="8px"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Text fontWeight={700} fontSize="16px" lineHeight="24px" color="#0B254B">
        {title}
      </Text>
      <Text fontWeight={400} fontSize="16px" lineHeight="20px" color="#0B254BB2">
        {value}
      </Text>
    </VStack>
  );
};

export default CustomerStorySummary;
