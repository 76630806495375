import { Container, Box, Text, Heading } from '@chakra-ui/react';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';

export default function TimelineSection({ size, title, description, image }) {
  return (
    <Container as="section" size={size}>
      <Box maxWidth="3xl" mx="auto" textAlign="center" mb="56px">
        <Heading as="h2" size="lg" mb="16px">
          {title}
        </Heading>
        <Text>{description}</Text>
      </Box>
      <Image
        src={image.filename}
        alt={image.alt}
        height={imageDimensions(image.filename).height}
        width={imageDimensions(image.filename).width}
      />
    </Container>
  );
}
