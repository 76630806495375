import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { ChevronDown } from 'components/common/icons/assets/ChevronDown';
import Image from "next/image";
import { useState, VFC } from 'react';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';

/* Types */

type Performance = {
  title: string,
  text: string,
  image: Storyblok.Image,
}

type FrmPerformancesProps = {
  title: string;
  performances: Performance[];
};

/* Widgets */

function PerformanceWidget(props: {
  performance: Performance,
  opened: boolean,
  onClick: () => void,
}) {
  const {
    performance,
    opened,
    onClick,
  } = props;
  return (
    <button onClick={onClick}>
      <Box
        backgroundColor={opened ? "white" : undefined}
        _hover={{
          backgroundColor: opened ? undefined : "white"
        }}
        padding="16px"
        maxWidth="lg"
        border="1px"
        borderColor="gray.200"
        rounded="md"
        textAlign="start"
        transitionDuration="300ms"
        minHeight={opened ? 100 : 0}
      >
        <Box
          display="flex"
          alignItems="start"
          gap="12px"

        >
          <Heading
            as="h2"
            size="sm"
            fontWeight="bold"
          >
            {performance.title}
          </Heading>
          <Box
            transform={`rotate(${opened ? "180deg" : "0deg"})`}
            transitionDuration="100ms"
          >
            <ChevronDown />
          </Box>
        </Box>
        {
          opened
            ? (
              <Text as="h3" size="xs" mt="16px">
                {performance.text}
              </Text>
            ) : undefined
        }
      </Box>
    </button >
  );
}

const FrmPerformances: VFC<FrmPerformancesProps> = ({
  title,
  performances,
}) => {

  const [openedIndex, setOpenIndex] = useState(0);

  return (
    <Box bgColor="orange.8" width="100%">
      <Container as="section">
        <Heading
          as="h1"
          size="lg"
          mb="64px"
          fontWeight="bold"
          textAlign="center"
        >
          {title}
        </Heading>
        <Box
          display="flex"
          flexDirection={{ base: "column", desktop: "row" }}
        >
          <Box
            gap="16px"
            display="flex"
            flexDirection="column"
            width={{ base: "auto", desktop: "50%" }}
          >
            {
              performances.map((e, i) => (
                <PerformanceWidget
                  performance={e}
                  opened={openedIndex === i}
                  onClick={() => setOpenIndex(i)}
                  key={e.title}
                />
              ))
            }
          </Box>
          <Box width={{ base: "auto", desktop: "50%" }}>
            <Image
              src={performances[openedIndex].image.filename}
              alt={performances[openedIndex].image.alt}
              width={imageDimensions(performances[openedIndex].image.filename).width}
              height={imageDimensions(performances[openedIndex].image.filename).height}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FrmPerformances;
