import styled from '@emotion/styled';

const HamburgerIconContainer = styled.div<{ isOpen: boolean; color: string }>`
  $size: 30px;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div {
    width: 17px;
    height: 9px;
    position: relative;
    span {
      display: inline-block;
      background-color: ${(props) => props.color};
      width: 100%;
      border-radius: 10px;
      height: 2px;
      position: absolute;
      left: 0;
      transition: transform 200ms ease-out;
      top: 0;
      &:first-of-type {
        ${(props) => props.isOpen && `transform: translateY(3px) rotate(45deg);`}
      }
      &:last-child {
        transform: ${(props) => (props.isOpen ? `translateY(3px) rotate(-45deg)` : `translateY(7px)`)};
      }
    }
  }
`;

export const HamburgerIcon: React.FC<{ isOpen: boolean, color: string }> = ({ isOpen, color }) => {
  return (
    <HamburgerIconContainer color={color} isOpen={isOpen}>
      <div>
        <span />
        <span />
      </div>
    </HamburgerIconContainer>
  );
};
