export const Waves = () => (
  <svg width="100%" viewBox="0 0 781 781" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M781 807.5C795.636 807.5 807.5 795.636 807.5 781C807.5 766.364 795.636 754.5 781 754.5C766.364 754.5 754.5 766.364 754.5 781C754.5 795.636 766.364 807.5 781 807.5Z"
      fill="url(#paint0_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.19"
      d="M781 861.5C825.459 861.5 861.5 825.459 861.5 781C861.5 736.541 825.459 700.5 781 700.5C736.541 700.5 700.5 736.541 700.5 781C700.5 825.459 736.541 861.5 781 861.5Z"
      fill="url(#paint1_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.18"
      d="M781.5 915.5C855.506 915.5 915.5 855.506 915.5 781.5C915.5 707.494 855.506 647.5 781.5 647.5C707.494 647.5 647.5 707.494 647.5 781.5C647.5 855.506 707.494 915.5 781.5 915.5Z"
      fill="url(#paint2_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.17"
      d="M781.5 969.5C885.33 969.5 969.5 885.33 969.5 781.5C969.5 677.67 885.33 593.5 781.5 593.5C677.67 593.5 593.5 677.67 593.5 781.5C593.5 885.33 677.67 969.5 781.5 969.5Z"
      fill="url(#paint3_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.16"
      d="M781.5 1023.5C915.153 1023.5 1023.5 915.153 1023.5 781.5C1023.5 647.847 915.153 539.5 781.5 539.5C647.847 539.5 539.5 647.847 539.5 781.5C539.5 915.153 647.847 1023.5 781.5 1023.5Z"
      fill="url(#paint4_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.15"
      d="M781 1076.5C944.2 1076.5 1076.5 944.2 1076.5 781C1076.5 617.8 944.2 485.5 781 485.5C617.8 485.5 485.5 617.8 485.5 781C485.5 944.2 617.8 1076.5 781 1076.5Z"
      fill="url(#paint5_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.14"
      d="M781 1130.5C974.023 1130.5 1130.5 974.023 1130.5 781C1130.5 587.976 974.023 431.5 781 431.5C587.976 431.5 431.5 587.976 431.5 781C431.5 974.023 587.976 1130.5 781 1130.5Z"
      fill="url(#paint6_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.13"
      d="M781 1184.5C1003.85 1184.5 1184.5 1003.85 1184.5 781C1184.5 558.153 1003.85 377.5 781 377.5C558.153 377.5 377.5 558.153 377.5 781C377.5 1003.85 558.153 1184.5 781 1184.5Z"
      fill="url(#paint7_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.12"
      d="M781 1238.5C1033.67 1238.5 1238.5 1033.67 1238.5 781C1238.5 528.33 1033.67 323.5 781 323.5C528.33 323.5 323.5 528.33 323.5 781C323.5 1033.67 528.33 1238.5 781 1238.5Z"
      fill="url(#paint8_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.11"
      d="M781.5 1292.5C1063.72 1292.5 1292.5 1063.72 1292.5 781.5C1292.5 499.283 1063.72 270.5 781.5 270.5C499.283 270.5 270.5 499.283 270.5 781.5C270.5 1063.72 499.283 1292.5 781.5 1292.5Z"
      fill="url(#paint9_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.1"
      d="M781.5 1346.5C1093.54 1346.5 1346.5 1093.54 1346.5 781.5C1346.5 469.459 1093.54 216.5 781.5 216.5C469.459 216.5 216.5 469.459 216.5 781.5C216.5 1093.54 469.459 1346.5 781.5 1346.5Z"
      fill="url(#paint10_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.09"
      d="M781.5 1400.5C1123.36 1400.5 1400.5 1123.36 1400.5 781.5C1400.5 439.636 1123.36 162.5 781.5 162.5C439.636 162.5 162.5 439.636 162.5 781.5C162.5 1123.36 439.636 1400.5 781.5 1400.5Z"
      fill="url(#paint11_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.08"
      d="M781.5 1454.5C1153.19 1454.5 1454.5 1153.19 1454.5 781.5C1454.5 409.812 1153.19 108.5 781.5 108.5C409.812 108.5 108.5 409.812 108.5 781.5C108.5 1153.19 409.812 1454.5 781.5 1454.5Z"
      fill="url(#paint12_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.07"
      d="M781 1507.5C1182.23 1507.5 1507.5 1182.23 1507.5 781C1507.5 379.765 1182.23 54.5 781 54.5C379.765 54.5 54.5 379.765 54.5 781C54.5 1182.23 379.765 1507.5 781 1507.5Z"
      fill="url(#paint13_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <path
      opacity="0.06"
      d="M781 1561.5C1212.06 1561.5 1561.5 1212.06 1561.5 781C1561.5 349.942 1212.06 0.5 781 0.5C349.942 0.5 0.5 349.942 0.5 781C0.5 1212.06 349.942 1561.5 781 1561.5Z"
      fill="url(#paint14_linear_4_3)"
      fillOpacity="0.1"
      stroke="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4_3"
        x1="754.103"
        y1="754"
        x2="754.103"
        y2="807.793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint1_linear_4_3" x1="700" y1="700" x2="700" y2="862" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint2_linear_4_3" x1="647" y1="647" x2="647" y2="916" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint3_linear_4_3" x1="593" y1="593" x2="593" y2="970" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint4_linear_4_3" x1="539" y1="539" x2="539" y2="1024" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint5_linear_4_3" x1="485" y1="485" x2="485" y2="1077" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint6_linear_4_3" x1="431" y1="431" x2="431" y2="1131" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint7_linear_4_3" x1="377" y1="377" x2="377" y2="1185" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint8_linear_4_3" x1="323" y1="323" x2="323" y2="1239" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint9_linear_4_3" x1="270" y1="270" x2="270" y2="1293" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint10_linear_4_3" x1="216" y1="216" x2="216" y2="1347" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint11_linear_4_3" x1="162" y1="162" x2="162" y2="1401" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint12_linear_4_3" x1="108" y1="108" x2="108" y2="1455" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient id="paint13_linear_4_3" x1="54" y1="54" x2="54" y2="1508" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_4_3"
        x1="-6.73964e-06"
        y1="-1.15325e-08"
        x2="-6.73964e-06"
        y2="1562"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.996173" stopColor="white" stopOpacity="0.01" />
      </linearGradient>
    </defs>
  </svg>
);
