import { VFC } from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';
import { theme } from 'theme';
import * as _ from 'lodash';
import { DynamicComponent } from '.';
import ResponsiveScrollableWrapper from 'components/common/ResponsiveScrollableWrapper';

type CustomerTestimonialGridProps = {
  title: string;
  customerTestimonials: any; // todo: type
  layerStyle: string;
};

const CustomerTestimonialGrid: VFC<CustomerTestimonialGridProps> = ({
  title,
  customerTestimonials,
  layerStyle = 'default',
}) => {
  return (
    <Box backgroundColor={theme.colors.orange[8]} width="100%">
      <Container as="section" layerStyle={layerStyle}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Heading as="h2" mb={{ base: '24px', desktop: '48px' }}>
            {title}
          </Heading>
          <ResponsiveScrollableWrapper width="100%" cardWidth="80%" gap={{ base: '24px', desktop: '38px' }}>
            {customerTestimonials &&
              _.map(customerTestimonials, (customerTestimonial) => (
                <Box key={customerTestimonial._uid} flex={{ base: '0 0 auto', lg: '1 1 calc(33.333%)' }}>
                  <DynamicComponent block={customerTestimonial} />
                </Box>
              ))}
          </ResponsiveScrollableWrapper>
        </Box>
      </Container>
    </Box>
  );
};

export default CustomerTestimonialGrid;
