import { FC } from 'react';
import NextLink from 'next/link';
import { Link, ButtonProps, Button } from '@chakra-ui/react';

type SmartButtonLinkProps = {
  href: string;
  children: React.ReactNode;
} & ButtonProps;

const SmartButtonLink: FC<SmartButtonLinkProps> = ({ href, children, ...buttonProps }) => {
  if (href.startsWith('http')) {
    return (
      <Link variant="unstyled" href={href}>
        <Button {...buttonProps}>{children}</Button>
      </Link>
    );
  }

  return (
    <NextLink href={href} passHref>
      <Button as="a" {...buttonProps}>
        {children}
      </Button>
    </NextLink>
  );
};

export default SmartButtonLink;
