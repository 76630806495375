import { VFC } from 'react';
import { Storyblok } from 'types';
import { Box, Container, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';

type CustomerStoriesHeroProps = {
  title: string;
  subtitle: string;
  companyImage: Storyblok.Image;
  customerPicture: Storyblok.Image;
  customerName: string;
  customerJobTitle: string;
};

const CustomerStoriesHero: VFC<CustomerStoriesHeroProps> = ({
  title,
  subtitle,
  customerPicture,
  companyImage,
  customerName,
  customerJobTitle,
}) => {
  return (
    <Container as="section" size="ptHeader">
      <Grid templateColumns={{ base: '1fr', tablet: 'repeat(5, 1fr)' }} gap={{ base: '64px', desktop: '144px' }}>
        <GridItem colSpan={{ base: 1, tablet: 3 }}>
          <Heading size="xxl" mb="22px">
            {title}
          </Heading>
          <Text size="md" lineHeight="34px">
            {subtitle}
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 1, tablet: 2 }} alignSelf="center">
          <Flex justifyContent="center">
            <Box padding="0 24px 94px 0" position="relative">
              <Box as="figure" borderRadius="4px">
                <Image
                  src={companyImage.filename}
                  alt={companyImage.alt}
                  height={imageDimensions(companyImage.filename).height}
                  width={imageDimensions(companyImage.filename).width}
                />
              </Box>
              <Box as="figure" position="absolute" right={0} bottom={0} borderRadius="4px" textAlign="right">
                <Image src={customerPicture.filename} alt={customerPicture.alt} height="110px" width="110px" />
                <Text align="right" mt="16px" fontWeight="bold" color="sapphire.100">
                  {customerName}
                </Text>
                <Text align="right" size="sm">
                  {customerJobTitle}
                </Text>
              </Box>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default CustomerStoriesHero;
