import { useEffect, useState } from 'react';

export function useAnchorsObserver(className): string {
  const [currentAnchor, setCurrentAnchor] = useState<string>();

  useEffect(() => {
    const liveNodeList = document.getElementsByClassName(className);

    const onScroll = () => {
      let minNodeHeight = window.innerHeight / 2;
      for (let i = 0; i < liveNodeList.length; i += 1) {
        const node = liveNodeList[i];
        const nextNode = liveNodeList[i + 1];
        if (!(node instanceof HTMLElement)) continue;
        minNodeHeight = Math.min(
          minNodeHeight,
          nextNode instanceof HTMLElement
            ? nextNode.offsetTop - node.offsetTop
            : document.documentElement.scrollHeight + window.innerHeight - node.offsetTop,
        );
      }

      const breakpoint = minNodeHeight - 1;
      for (let i = 0; i < liveNodeList.length; i += 1) {
        const node = liveNodeList[i];
        const nextNode = liveNodeList[i + 1];
        if (!(node instanceof HTMLElement)) continue;
        const { top } = node.getBoundingClientRect();
        if (
          (i === 0 && breakpoint < top) ||
          (breakpoint >= top &&
            (!(nextNode instanceof HTMLElement) || breakpoint < nextNode.getBoundingClientRect().top))
        ) {
          return setCurrentAnchor(node.id);
        }
      }
    };

    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [className]);

  return currentAnchor;
}
