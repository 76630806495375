import { Box, Container, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';
import { VFC } from 'react';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { ButtonGroup } from 'components/common/ButtonGroup';

type GridThreeSectionProps = SectionProps<{
  layerStyle: string;
  title: string;
  description: string;
  textAlign: 'center' | 'left';
  cardSize: GridThreeCardProps['cardSize'];
  iconSize: GridThreeCardProps['iconSize'];
  cards: Omit<GridThreeCardProps, 'cardSize' | 'iconSize'>[];
}>;

export default function GridThreeSection({
  size,
  layerStyle = 'default',
  title,
  description,
  cards,
  cardSize,
  iconSize,
  textAlign = 'center',
}: GridThreeSectionProps) {
  return (
    <Container as="section" size={size} layerStyle={layerStyle}>
      {title && (
        <Box textAlign={textAlign} mb={{ base: '40px', desktop: '80px' }}>
          <Heading as="h2" size="lg" mb="16px" maxWidth="1024px" mx={textAlign === 'center' && 'auto'}>
            {title}
          </Heading>
          {description && (
            <Text maxWidth="560px" mx={textAlign === 'center' && 'auto'}>
              {description}
            </Text>
          )}
        </Box>
      )}
      <Grid gridTemplateColumns={{ base: '1fr', desktop: 'repeat(3, 1fr)' }} gridGap="48px">
        {cards.map((card, i) => (
          <GridItem key={i}>
            <GridThreeCard {...card} cardSize={cardSize} iconSize={iconSize} />
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
}

type GridThreeCardProps = {
  title: string;
  description: string;
  icon: string;
  iconColor: string;
  iconBackgroundColor: string;
  buttons: Storyblok.Button[];
  cardSize?: 'sm' | 'md';
  iconSize?: 'sm' | 'md';
};

const cardSizeMap = {
  sm: { mb: '16px', textSize: 'sm' },
  md: { mb: '24px', textSize: 'md' },
};

const iconSizeMap = {
  sm: '14px',
  md: '24px',
};

const GridThreeCard: VFC<GridThreeCardProps> = ({
  title,
  description,
  icon,
  iconColor,
  iconBackgroundColor,
  buttons,
  cardSize = 'sm',
  iconSize = 'md',
}) => {
  return (
    <Box>
      {icon && (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
          w="40px"
          h="40px"
          mb={cardSizeMap[cardSize].mb}
          bg={iconBackgroundColor}
        >
          <DynamicIcon icon={icon} fontSize={iconSizeMap[iconSize]} color={iconColor} />
        </Flex>
      )}
      <Heading as="h3" size="sm" mb={cardSizeMap[cardSize].mb}>
        {title}
      </Heading>
      <Text size={cardSizeMap[cardSize].textSize}>{description}</Text>
      {buttons.length > 0 && <ButtonGroup buttons={buttons} width="auto" alignItems="flex-start" mt="8px" />}
    </Box>
  );
};
