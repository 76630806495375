import { AspectRatio, Box, Container, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import Image from "next/image";
import { VFC } from 'react';
import { Storyblok } from 'types';

/* Types */

type FrmWorkMotionProps = {
  title: string;
  icon: Storyblok.Image;
  text: string;
  author: string;
  authorPosition: string;
  buttons: Storyblok.Button[];
  image: Storyblok.Image;
};

/* Widgets */

const FrmWorkMotion: VFC<FrmWorkMotionProps> = ({
  title,
  icon,
  text,
  author,
  authorPosition,
  buttons,
  image,
}) => {
  return (
    <Container
      as="section"
      display="flex"
      flexDirection={{ base: "column", desktop: "row" }}
      gap="8"
    >
      <Box>
        <Image
          src={icon.filename}
          height={64}
          width={200}
          objectFit="contain"
          alt={icon.alt}
        />
        <Heading
          as="h1"
          size="md"
          fontWeight="bold"
          textColor="sapphire.100"
          marginY="8"
        >
          {title}
        </Heading>
        <Text
          as="h2"
          size="md"
          textAlign="left"
          textColor="sapphire.70"
          marginBottom="8"
        >
          {text}
        </Text>
        <Heading
          as="h3"
          size="sm"
          fontWeight="bold"
          textColor="sapphire.100"
        >
          {author}
        </Heading>
        <Text
          as="h4"
          size="sm"
          textAlign="left"
          textColor="sapphire.70"
          marginBottom="8"
        >
          {authorPosition}
        </Text>
        <ButtonGroup buttons={buttons} />
      </Box>
      <AspectRatio
        width={{ base: "full", desktop: "1000px" }}
        ratio={1}
        position="relative"
        rounded="2xl"
        overflow="hidden"
      >
        <Image
          src={image.filename}
          alt={image.alt}
          objectFit="contain"
          layout="fill"
        />
      </AspectRatio>
    </Container>
  );
};

export default FrmWorkMotion;
