import RichText from "components/common/RichText";
import { Storyblok } from "types";

type ArticleRichTextProps = {
  anchorClassName: string,
  content: Storyblok.RichText,
};

function ArticleRichText(props: ArticleRichTextProps) {
  const {
    anchorClassName,
    content,
  } = props;

  const listStyles = {
    paddingLeft: '1em',
    fontSize: {
      base: '16px',
      tablet: '20px',
    },
    lineHeight: {
      base: '26px',
      tablet: '34px',
    },
    marginBottom: {
      base: '24px',
      tablet: '32px',
    },
    li: {
      p: { margin: 0 },
      listStylePosition: 'outside',
      padding: 0,
      _notFirst: {
        marginTop: '16px',
      },
    },
  };

  const ulStyles = {
    ...listStyles,
    listStyle: 'disc',
  };

  const olStyles = { ...listStyles };

  return (
    <RichText
      anchorClassName={anchorClassName}
      content={content}
      anchorHeadings
      textFontFamily="reading"
      textColor="sapphire.100"
      _ul={ulStyles}
      _ol={olStyles}
      _p={{ whiteSpace: 'pre-line' }}
    />
  );
}

export default ArticleRichText;
