import { Currency, Locale } from 'types';
import { useTranslation } from 'lib/hooks/useTranslation';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getCookie, setCookie } from 'lib/cookieHelper';

const DEFAULT_CURRENCY: Record<Locale, Currency> = {
  en: Currency.USD,
  fr: Currency.EUR,
};

const EURO_COUNTRIES = [
  'AT',
  'BE',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SK',
  'SI',
  'ES',
];
const CURRENCY_COOKIE_KEY = 'currency';

export function useCurrency(): Currency {
  const { locale } = useTranslation();
  const [currency, setCurrency] = useState<Currency>(DEFAULT_CURRENCY[locale]);

  useEffect(() => {
    const cachedCurrency = getCookie(CURRENCY_COOKIE_KEY);
    if (cachedCurrency && isValidCurrency(cachedCurrency)) {
      setCurrency(cachedCurrency);
      return;
    }

    axios
      .get('/api/country-code')
      .then((response) => {
        const countryCode = response.data.countryCode;
        let currency = Currency.USD;

        if (countryCode === 'GB') {
          currency = Currency.GBP;
        }

        if (EURO_COUNTRIES.includes(countryCode) || locale === 'fr') {
          currency = Currency.EUR;
        }

        setCookie(CURRENCY_COOKIE_KEY, currency);
        setCurrency(currency);
        return;
      })
      .catch
      // failed to fetch, don't do anything as it will use default currency
      ();
  }, [locale]);

  return currency;
}

function isValidCurrency(currency: string): currency is Currency {
  return Object.values<string>(Currency).includes(currency);
}
