import { VFC } from 'react';
import { Box, Container, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { ArrowRightDash } from 'components/common/icons/assets/ArrowRightDash';
import { colors } from '../../utils/colorScheme';

export type BeforeAfterContentProps = {
  beforeText: string;
  afterText: string;
  icon?: string;
  color: string;
};

type BeforeAfterProps = {
  beforeAfterContent: BeforeAfterContentProps[];
  beforeTitle: string;
  afterTitle: string;
};

const BeforeAfter: VFC<BeforeAfterProps> = ({ beforeTitle, afterTitle, beforeAfterContent }) => {
  return (
    <Container as="section" size="py50">
      <Box overflowX={{ base: 'scroll', tablet: 'auto' }}>
        <Flex
          justifyContent={{ base: 'start', tablet: 'center' }}
          alignItems="center"
          p={{ base: '16px', desktop: '32px' }}
        >
          <Grid
            gridTemplateColumns="minmax(160px, 300px) auto auto minmax(160px, 300px)"
            boxShadow="md"
            border="1px"
            borderColor="azure.8"
            borderRadius="4px"
            minWidth={'min-content'}
            p={{ base: '24px', desktop: '32px' }}
            alignItems="center"
          >
            <GridItem colSpan={3} py="8px" pr="16px">
              <Text size="sm" casing="uppercase" fontWeight="semibold" letterSpacing="2px">
                {beforeTitle}
              </Text>
            </GridItem>
            <GridItem py="8px" pl="16px">
              <Text
                size="sm"
                casing="uppercase"
                fontWeight="semibold"
                letterSpacing="2px"
                color={colors['blue'].primary}
              >
                {afterTitle}
              </Text>
            </GridItem>
            {beforeAfterContent.map((content, i) => (
              <>
                <GridItem key={i} py="16px" pr={{ base: '4px', tablet: '8px' }}>
                  <Text size="md">{content.beforeText}</Text>
                </GridItem>
                <GridItem py="16px" px="16px">
                  <ArrowRightDash boxSize="46px" color={colors['blue'].primary} />
                </GridItem>
                <GridItem py="16px" px="16px">
                  {content.icon && (
                    <Box boxShadow="base" borderRadius="100%" padding="12px">
                      <DynamicIcon icon={content.icon} color={colors[content.color].primary} boxSize="24px" />
                    </Box>
                  )}
                </GridItem>
                <GridItem py="16px" pl={{ base: '4px', tablet: '8px' }}>
                  <Text size="md">{content.afterText}</Text>
                </GridItem>
                {i < beforeAfterContent.length - 1 && (
                  <GridItem colSpan={4} borderBottomWidth="1px" borderColor="azure.8"></GridItem>
                )}
              </>
            ))}
          </Grid>
        </Flex>
      </Box>
    </Container>
  );
};

export default BeforeAfter;
