import { StatisticsIconProps } from 'components/common/StatisticsIcon';
import { VFC } from 'react';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { colors } from '../../utils/colorScheme';

type StatisticsGroupProps = {
  statisticIcons: StatisticsIconProps[];
  subText?: string;
};

const StatisticsGroup: VFC<StatisticsGroupProps> = ({ statisticIcons, subText }) => {
  return (
    <Container as="section" size="py0">
      <Flex flexDirection="column" justifyContent="center">
        <Flex
          justifyContent={statisticIcons.length === 3 ? 'space-around' : 'space-evenly'}
          alignItems="flex-start"
          flexWrap="wrap"
          rowGap="60px"
        >
          {statisticIcons.map((stat, i) => {
            return (
              <Flex
                key={i}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                width={{ base: '100%', tablet: '270px' }}
                marginTop={statisticIcons.length === 3 && i !== 1 ? { tablet: '95px' } : '0px'}
              >
                <Box
                  borderRadius="100%"
                  bg={colors[stat.colorScheme].light}
                  padding={statisticIcons.length === 3 && i === 1 ? { base: '14px', tablet: '40px' } : '14px'}
                  mb="16px"
                >
                  <DynamicIcon
                    icon={stat.icon}
                    color={colors[stat.colorScheme].primary}
                    boxSize={statisticIcons.length === 3 && i === 1 ? { base: '36px', tablet: '80px' } : '36px'}
                  />
                </Box>
                <Box>
                  <Heading size="xxl">{stat.value}</Heading>
                  <Text size="md">{stat.name}</Text>
                </Box>
              </Flex>
            );
          })}
        </Flex>

        {subText && (
          <Flex justifyContent="space-evenly">
            <Text size="sm" mt="40px">
              {subText}
            </Text>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default StatisticsGroup;
