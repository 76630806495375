import RichTextContent, { RichTextProps } from 'components/common/RichText';
import { Container } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';
import { VFC } from 'react';

type RichTextSectionProps = SectionProps<{
  content: Storyblok.RichText;
  richTextSize?: RichTextProps['size'];
}>;

const RichTextSection: VFC<RichTextSectionProps> = ({ size, content, richTextSize }) => {
  return (
    <Container as="section" size={size}>
      <RichTextContent content={content} size={richTextSize} />
    </Container>
  );
};

export default RichTextSection;
