import { Box, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { Waves } from 'components/common/Shapes';

export default function CallToActionOneSection({ size, title, description, buttons, layerStyle }) {
  return (
    <Container as="section" size={size}>
      <Box
        layerStyle={layerStyle}
        p={{ base: '40px', desktop: '80px' }}
        borderRadius="4px"
        position="relative"
        overflow="hidden"
      >
        <Box position="absolute" width="75%" bottom="0" right="0">
          <Waves />
        </Box>
        <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr auto' }} gridGap="10">
          <Flex justifyContent={{ base: 'center', desktop: 'flex-start' }}>
            <Box textAlign={{ base: 'center', desktop: 'left' }}>
              <Heading as="h2" size="lg" mb="16px">
                {title}
              </Heading>
              <Text variant="unstyled" fontSize={{ base: 'lg', desktop: 'xl' }}>
                {description}
              </Text>
            </Box>
          </Flex>
          <Flex justifyContent={{ base: 'center', desktop: 'flex-end' }} alignItems="center">
            <ButtonGroup buttons={buttons} />
          </Flex>
        </Grid>
      </Box>
    </Container>
  );
}
