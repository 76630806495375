import { VFC } from 'react';
import { Storyblok } from 'types';
import AnalyticsForm, { AnalyticsFormProps } from 'components/forms/AnalyticsForm';
import PartnersForm, { PartnersFormProps } from 'components/forms/PartnersForm';
import DemoForm, { DemoFormProps } from 'components/forms/DemoForm';
import HomeHeroForm, { HomeHeroFormProps } from 'components/forms/HomeHeroForm';

type FormRouterProps = {
  form: Storyblok.Form;
  formConfig: Storyblok.FormConfig;
};

const FormRouter: VFC<FormRouterProps> = ({ form, formConfig }) => {
  // Reformat text from array of objects to object
  const text = !!form.content.text
    ? form.content.text.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {})
    : undefined;

  if (form.slug === 'analytics')
    return <AnalyticsForm formConfig={formConfig} text={text as AnalyticsFormProps['text']} />;
  if (form.slug === 'partners')
    return <PartnersForm formConfig={formConfig} text={text as PartnersFormProps['text']} />;
  if (form.slug === 'demo') return <DemoForm formConfig={formConfig} text={text as DemoFormProps['text']} />;
  if (form.slug === 'home') return <HomeHeroForm formConfig={formConfig} text={text as HomeHeroFormProps['text']} />;

  return null;
};

export default FormRouter;
