import { AspectRatio, Box, Container, Grid, Heading } from "@chakra-ui/react";
import ArticleRichText from "components/common/ArticleRichText";
import CallToActions from "components/common/CallToActions";
import { HubSpotCTAProps } from "components/common/RichText/HubSpotCTA";
import { ShareButtons } from "components/common/ShareButtons";
import { Summary } from "components/common/Summary";
import { ThreeColumnGrid } from "components/common/ThreeColumnGrid";
import { useAnchorsObserver } from "lib/hooks/useAnchorsObserver";
import { VFC } from 'react';
import { Storyblok } from "types";


/* AudioPlayer */

type AudioPlayerProps = {
  spotifyEmbedLink: string,
}

const AudioPlayer: VFC<AudioPlayerProps> = (props: {
  spotifyEmbedLink,
}) => {
  const {
    spotifyEmbedLink,
  } = props;
  return (
    <iframe
      style={{ borderRadius: '12px', marginBottom: "32px" }}
      src={spotifyEmbedLink}
      width="100%"
      height="152"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      allowFullScreen
      loading="lazy"
    />
  );
}

/* PodcastBody */

type PodcastBodyProps = {
  // Podcast
  title: string,
  image: Storyblok.Image,
  time: string,
  description: string,
  content: Storyblok.RichText,
  date: string;
  youtubeVideoId: string,
  spotifyEmbedLink: string,
  // Other
  leftSidebarTitle: string;
  anchorHeadings: { id: string; name: string; }[];
  customAnchorHeadings: { url: string; newName: string; }[];
  rightSidebarCallToActions: HubSpotCTAProps[];
  metadata: Storyblok.Metadata;
};

const PodcastBody: VFC<PodcastBodyProps> = ({
  // Podcast
  title,
  image,
  time,
  description,
  content,
  date,
  youtubeVideoId,
  spotifyEmbedLink,
  // Other
  leftSidebarTitle,
  anchorHeadings,
  customAnchorHeadings,
  rightSidebarCallToActions,
  metadata,
}) => {

  const OBSERVED_ANCHOR_CLASS_NAME = 'podcast-anchor';
  const currentAnchorId = useAnchorsObserver(OBSERVED_ANCHOR_CLASS_NAME);

  return (
    <Container as="article">
      <ThreeColumnGrid
        center={
          <Heading as="h1" size="2xl">
            {title}
          </Heading>
        }
      />
      <ThreeColumnGrid
        left={
          <Summary
            activeItemId={currentAnchorId}
            title={leftSidebarTitle}
            listItems={anchorHeadings}
            customListItems={customAnchorHeadings}
          />
        }
        center={
          <>
            <AspectRatio
              as="figure"
              width="100%"
              borderRadius="8px"
              overflow="hidden"
              my="32px"
              ratio={16 / 9}
            >
              <Box as="iframe"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                allowFullScreen
                height="full"
                width="full"
                frameBorder="0"
              />
            </AspectRatio>
            <AudioPlayer spotifyEmbedLink={spotifyEmbedLink} />
            <ArticleRichText
              anchorClassName={OBSERVED_ANCHOR_CLASS_NAME}
              content={content}
            />
          </>
        }
        right={
          <Grid gridGap="16px" >
            {rightSidebarCallToActions?.length > 0 && <CallToActions callToActions={rightSidebarCallToActions} />}
            <ShareButtons title={metadata.title} description={metadata.description} />
          </Grid >
        }
      />
    </Container >
  );
};

export default PodcastBody;
