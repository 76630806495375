import { FC } from 'react';
import NextLink from 'next/link';
import { LinkOverlay, LinkOverlayProps } from '@chakra-ui/react';

type SmartLinkOverlayProps = {
  href: string;
} & LinkOverlayProps;

const SmartLinkOverlay: FC<SmartLinkOverlayProps> = ({ href, ...linkProps }) => {
  if (href.startsWith('http')) {
    return <LinkOverlay href={href} {...linkProps} />;
  }

  return (
    <NextLink href={href} passHref>
      <LinkOverlay {...linkProps} />
    </NextLink>
  );
};

export default SmartLinkOverlay;
