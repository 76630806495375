import { Icon } from '@chakra-ui/react';

export const Link = (props) => (
  <Icon viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62082 1.27549C6.30048 0.61257 7.21397 0.24415 8.16337 0.25007C9.1128 0.25599 10.0216 0.63576 10.6929 1.3071C11.3643 1.97844 11.7441 2.88727 11.75 3.83667C11.7559 4.78607 11.3875 5.69956 10.7246 6.37922L10.718 6.38591L9.3418 7.76206C9.0489 8.05495 8.57402 8.05495 8.28113 7.76206C7.98824 7.46917 7.98824 6.99429 8.28113 6.7014L9.6538 5.32878C10.0393 4.93192 10.2534 4.3994 10.25 3.84602C10.2465 3.2912 10.0246 2.76008 9.6323 2.36776C9.24 1.97543 8.70884 1.7535 8.15402 1.75004C7.60064 1.74659 7.06814 1.96077 6.67128 2.34627L5.29864 3.71891C5.00575 4.0118 4.53087 4.0118 4.23798 3.71891C3.94509 3.42601 3.94509 2.95114 4.23798 2.65825L5.62082 1.27549ZM8.20563 3.7945C8.49852 4.08739 8.49852 4.56227 8.20563 4.85516L4.85528 8.2055C4.56239 8.4984 4.08751 8.4984 3.79462 8.2055C3.50173 7.91261 3.50173 7.43774 3.79462 7.14484L7.14497 3.7945C7.43786 3.50161 7.91273 3.50161 8.20563 3.7945ZM3.71891 5.2986C4.0118 5.00571 4.0118 4.53084 3.71891 4.23794C3.42601 3.94505 2.95114 3.94505 2.65825 4.23794L1.28201 5.61409L1.27549 5.62079C0.61257 6.30044 0.24415 7.21393 0.25007 8.16333C0.25599 9.1127 0.63576 10.0216 1.3071 10.6929C1.97844 11.3642 2.88727 11.744 3.83667 11.7499C4.78607 11.7558 5.6996 11.3875 6.37926 10.7246L7.76206 9.3418C8.05495 9.0489 8.05495 8.57399 7.76206 8.28109C7.46917 7.9882 6.99429 7.9882 6.7014 8.28109L5.32878 9.6537C4.93192 10.0392 4.3994 10.2534 3.84602 10.25C3.2912 10.2465 2.76008 10.0246 2.36776 9.6322C1.97543 9.2399 1.7535 8.7088 1.75004 8.15398C1.74659 7.6006 1.96077 7.0681 2.34627 6.67124L3.71891 5.2986Z"
      fill="#0B254B"
    />
  </Icon>
);
