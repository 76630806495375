import { Box } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Image from "next/image";
import { Storyblok } from "types";
import { imageDimensions, parseBreakpointStr } from "utils/storyblok";

export type CustomerLogos = {
  image: Storyblok.Image,
  width?: number,
  height?: number,
};


function CarrouselAuto(props: {
  logos: CustomerLogos[],
  filter?: string,
  fadeColor?: { from: string, to: string },
}) {
  const {
    logos,
    filter,
    fadeColor = { from: "rgba(255, 255, 255, 1)", to: "rgba(255, 255, 255, 0)" },
  } = props;

  /* CSS */

  const scroll = keyframes`
  0% { transform: translateX(0); }
	100% { transform: translateX(-50%)}
`;

  const SliderWidget = styled.div`
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    background: linear-gradient(to right, ${fadeColor.from} 0%, ${fadeColor.to} 100%);
    content: '';
    height: 100px;
    position: absolute;
    width: 50px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

  const SlideTrack = styled.div<{ logosLength: number }>`
    animation: ${scroll} ${({ logosLength }) => `${logosLength * 3}s`} linear infinite;
    overflow: hidden;
    height: 100%;
    white-space: nowrap;
    margin: 0;
    font-size: 0;
    display: flex;
    justify-content: space-between;
    width: fit-content;
  `;

  const Slide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 20px;
    min-width: 120px;
  `;


  return (
    <SliderWidget>
      <SlideTrack logosLength={logos.length}>
        {logos.map((logo, i) => (
          <Slide key={i}>
            <Box as="figure" filter={filter}>
              <Image
                src={logo.image.filename}
                alt={logo.image.alt}
                height={parseBreakpointStr(logo.height)}
                width={
                  (imageDimensions(logo.image.filename).width * logo.height) /
                  imageDimensions(logo.image.filename).height
                }
              />
            </Box>
          </Slide>
        ))}
      </SlideTrack>
    </SliderWidget>
  );
}


export default CarrouselAuto;
