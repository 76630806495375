import { Box, Container, Grid, Heading, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

const COLOR_SCHEME = {
  grey: { bg: 'neutral.60', color: 'sapphire.100' },
  blue: { bgImage: "url('/assets/blue-background.jpg')", bgPosition: 'center', bgSize: 'cover', color: 'white' },
};

type GridTwoProps = {
  title?: string;
  description?: string;
  cards: {
    title: string;
    description: string;
    icon: string;
  }[];
  columns?: number;
  align?: 'left' | 'center';
  colorScheme?: keyof typeof COLOR_SCHEME;
};

const GridTwo: VFC<GridTwoProps> = ({
  title,
  description,
  cards,
  columns = 2,
  align = 'left',
  colorScheme = 'grey',
}) => {
  return (
    <Container as="section" maxWidth="100%" px={{ base: 0, tablet: '40px' }}>
      <Box
        maxWidth="1360px"
        mx="auto"
        p={{ base: '40px', desktop: '80px' }}
        borderRadius={{ base: 'none', tablet: '16px' }}
        {...COLOR_SCHEME[colorScheme]}
      >
        <Box textAlign={align}>
          <Heading as="h2" size="xl" mb={{ base: '16px', desktop: '24px' }}>
            {title}
          </Heading>
          <Text color={colorScheme === 'grey' ? 'sapphire.100' : 'white'} mb={{ base: '40px', desktop: '80px' }}>
            {description}
          </Text>
        </Box>
        <Grid gridTemplateColumns={{ base: '1fr', desktop: `repeat(${columns}, 1fr)` }} gridGap="10">
          {cards.map((card, i) => (
            <Card key={i} {...card} colorScheme={colorScheme} />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default GridTwo;

function Card({ title, description, icon, colorScheme }) {
  return (
    <Box>
      <DynamicIcon icon={icon} fontSize={42} />
      <Heading as="h3" size="md" mt="24px" mb="12px">
        {title}
      </Heading>
      <Text color={colorScheme === 'grey' ? 'sapphire.70' : 'white'}>{description}</Text>
    </Box>
  );
}
