/*
  Mapped ranges
  On the left are actual values coming from the slider
  On the right are values we want to restrict to

  0 - 12: 0 - 3 in intervals of 0.25
  13 - 26: 3.5 - 10 in intervals of 0.5
  27 - 40: 11 - 24 in intervals of 1
  41 - 52: 26 - 50 in intervals of 2
*/
export const computeTurnoverFromSliderValue = (value: number): number => {
  let turnover;

  if (value < 13) {
    turnover = value * 0.25;
  } else if (value < 27) {
    turnover = 3 + (value - 12) * 0.5;
  } else if (value < 41) {
    turnover = 10 + (value - 26) * 1;
  } else {
    // Between 41 and 53
    turnover = 24 + (value - 40) * 2;
  }

  return turnover;
};

/*
 Inverse function of getInterpolatedSliderValue
 */
export const computeSliderValueFromTurnover = (value: number): number => {
  if (value <= 3) {
    return Math.max(0, Math.round(value * 4));
  }
  if (value <= 10) {
    return Math.round((value - 3) * 2 + 12);
  }
  if (value <= 24) {
    return Math.round(value - 10 + 26);
  }
  return Math.min(Math.round((value - 24) / 2 + 40), 53);
};

/**
 * @param annualTurnoverMillions 
 * @param averagePaymentDelayDays 
 * @param wacc Weighted average cost of capital, in %
 */
export const getCostOfLatePayments = (annualTurnoverMillions: number, averagePaymentDelayDays: number, wacc = 0.3): number => {
  return Math.round((((annualTurnoverMillions * 1000000) / 12) * averagePaymentDelayDays * wacc) / 360);
};

export const getCostOfUnpaidInvoices = (annualTurnoverMillions: number, unpaidInvoiceRatePercent: number): number => {
  return Math.round((((annualTurnoverMillions * 1000000) / 12) * unpaidInvoiceRatePercent) / 100);
};
