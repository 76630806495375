export const containerHeight = {
  base: 70,
  desktop: 90,
};

export const bannerHeight = {
  base: 100,
  desktop: 56,
};

export const containerStyles = ({ yDistance, mobileMenuIsOpen, yMobileMenuDistance, topSpace, background }) => ({
  width: '100%',
  zIndex: mobileMenuIsOpen ? 1500 : 'sticky',
  background: {
    base: yDistance > topSpace.base || mobileMenuIsOpen ? background : 'transparent',
    desktop: yDistance > topSpace.desktop ? background : 'transparent',
  },
  position: {
    base: yDistance > topSpace.base ? 'fixed' : 'absolute',
    desktop: yDistance > topSpace.desktop ? 'fixed' : 'absolute',
  },
  top: {
    base: yDistance > topSpace.base ? 0 : `${topSpace.base}px`,
    desktop: yDistance > topSpace.desktop ? 0 : `${topSpace.desktop}px`,
  },
  boxShadow: {
    base: (yDistance > topSpace.base && !mobileMenuIsOpen) || yMobileMenuDistance > 0 ? 'sm' : 'none',
    desktop: yDistance > topSpace.desktop ? 'sm' : 'none',
  },
});
