import { Icon } from '@chakra-ui/react';

export const Twitter = (props) => (
  <Icon viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      d="M12,2.15384615 C11.55,2.38461538 11.1,2.46153846 10.575,2.53846154 C11.1,2.23076923 11.475,1.76923077 11.625,1.15384615 C11.175,1.46153846 10.65,1.61538462 10.05,1.76923077 C9.6,1.30769231 8.925,1 8.25,1 C6.975,1 5.85,2.15384615 5.85,3.53846154 C5.85,3.76923077 5.85,3.92307692 5.925,4.07692308 C3.9,4 2.025,3 0.825,1.46153846 C0.6,1.84615385 0.525,2.23076923 0.525,2.76923077 C0.525,3.61538462 0.975,4.38461538 1.65,4.84615385 C1.275,4.84615385 0.9,4.69230769 0.525,4.53846154 C0.525,4.53846154 0.525,4.53846154 0.525,4.53846154 C0.525,5.76923077 1.35,6.76923077 2.475,7 C2.25,7.07692308 2.025,7.07692308 1.8,7.07692308 C1.65,7.07692308 1.5,7.07692308 1.35,7 C1.65,8 2.55,8.76923077 3.675,8.76923077 C2.85,9.46153846 1.8,9.84615385 0.6,9.84615385 C0.375,9.84615385 0.225,9.84615385 0,9.84615385 C1.125,10.5384615 2.4,11 3.75,11 C8.25,11 10.725,7.15384615 10.725,3.84615385 C10.725,3.76923077 10.725,3.61538462 10.725,3.53846154 C11.25,3.15384615 11.7,2.69230769 12,2.15384615 Z"
    />
  </Icon>
);
