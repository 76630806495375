import { Container, Heading, Text, Box, Flex, keyframes } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import Image from 'next/image';

export default function FloatingLogosSection({ size, title, description, buttons, logos }) {
  return (
    <Container as="section" size={size}>
      {title && (
        <Box textAlign="center" maxWidth="1024px" mx="auto" mb={{ base: '40px', desktop: '80px' }}>
          <Heading as="h2" size="lg" mb="16px">
            {title}
          </Heading>
          {description && <Text>{description}</Text>}
          <ButtonGroup buttons={buttons} justifyContent="center" mt="34px" />
        </Box>
      )}
      <Box background="url('/assets/waves.svg') no-repeat center bottom" height="425px" mx="-40px" overflow="hidden">
        <Flex position="relative" width="20px" height="100%" mx="auto">
          {logos?.map((logo, i) => {
            const wave = keyframes`
              from { transform: translateY(${logo.animationTranslateY}px); } 
              to { translateY(0); }
            `;

            return (
              <Box
                key={i}
                position="absolute"
                bg="white"
                boxShadow="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={logo.size}
                height={logo.size}
                borderRadius="full"
                top={logo.top}
                left={logo.left}
                animation={`3s cubic-bezier(.5,0,.5,1) ${logo.animationDuration}s infinite alternate ${wave}`}
              >
                <Image
                  src={logo.image.filename}
                  alt={logo.image.alt}
                  width={logo.imageWidth}
                  height={logo.imageHeight}
                />
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Container>
  );
}
