import { VFC } from 'react';
import { Container, HStack, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

type BlogNavigationProps = {
  selectedCategory: string;
  categories: {
    slug: string;
    name: string;
  }[];
};

const BlogNavigation: VFC<BlogNavigationProps> = ({ selectedCategory, categories }) => {
  return (
    <Container size="ptHeader0.pb0" as="nav">
      <HStack
        spacing="8px"
        wrap={{ base: 'nowrap', desktop: 'wrap' }}
        overflowX={{ base: 'auto', desktop: 'hidden' }}
        maxWidth="100%"
      >
        {categories.map(({ slug, name }, index) => {
          const selected = slug === selectedCategory;
          return (
            <NextLink href={`/blog/${slug}`} key={index} passHref>
              <Link variant="tab" aria-selected={selected}>
                {name}
              </Link>
            </NextLink>
          );
        })}
      </HStack>
    </Container>
  );
};

export default BlogNavigation;
