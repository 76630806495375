import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import { Storyblok } from 'types';

type FrmExplanationProps = {
  title: string;
  text: string;
  buttons: Storyblok.Button[];
};

const FrmExplanation: VFC<FrmExplanationProps> = ({
  title,
  text,
  buttons,
}) => {
  return (
    <Container as="section">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading
          as="h1"
          size="lg"
          mb="24px"
          fontWeight="bold"
          textAlign="center"
        >
          {title}
        </Heading>
        <Text
          as="h3"
          size="md"
          marginBottom="36px"
          textAlign="center"
        >
          {text}
        </Text>
        {/* <ButtonGroup
          buttons={buttons}
          marginBottom="36px"
          direction="row"
          _button={{
            width: "fit"
          }}
        /> */}
      </Box>
    </Container>
  );
};

export default FrmExplanation;
