import { Box, Button, Container, Flex, Heading, Tag, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { Storyblok } from 'types';
import { VFC } from 'react';
import { theme } from 'theme';
import { DynamicComponent } from '.';
import * as _ from 'lodash';
import { CustomerStoryCardProps } from './CustomerStoryCard';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

type Props = {
  tag: string;
  title: string;
  description?: string;
  button?: Storyblok.Button[];
  customerStoryCard?: CustomerStoryCardProps[];
  layerStyle?: string;
};

const CustomerHeroSection: VFC<Props> = ({ layerStyle = 'default', title, description, button, customerStoryCard }) => {
  return (
    <Box backgroundColor={theme.colors.azure[8]} width="100%">
      <Container
        as="section"
        layerStyle={layerStyle}
        size="ptHeader"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box mx="auto" textAlign="center" maxWidth="4xl">
          <Heading as="h1" size="xl" mb="24px">
            {title}
          </Heading>
          {description && <Text>{description}</Text>}
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: 'column', desktop: 'row' }}
          gap="24px"
          mt="48px"
          maxWidth="1280px"
          alignContent="center"
          justifyContent="center"
        >
          {customerStoryCard && _.map(customerStoryCard, (card) => <DynamicComponent key={card._uid} block={card} />)}
        </Box>

        <ButtonGroup
          buttons={button}
          width={{ base: 'min-content' }}
          mt="48px"
          alignItems="center"
          justifyContent="center"
        >
          <Box mr="9px">{button[0].text}</Box>
          <DynamicIcon icon="ArrowRightAnimated" />
        </ButtonGroup>
      </Container>
    </Box>
  );
};

export default CustomerHeroSection;
