import { Box, Button, Container, Flex, HStack, Link, List, ListItem } from '@chakra-ui/react';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import SmartButtonLink from 'components/common/SmartButtonLink';
import Image from 'next/image';
import NextLink from 'next/link';
import { useState } from 'react';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import DesktopMenu from '../menu/desktop';
import MobileMenu from '../menu/mobile';
import { containerStyles } from './styles';

const Header = ({ topSpace, logoLink, navItems = [], actionItems = [], slug }) => {
  const [yMobileMenuDistance, setYMobileMenuDistance] = useState(0);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const { y: yDistance } = useWindowScroll();

  const layouts = {
    default: {
      logo: '/assets/logo-default.svg',
      width: { base: '75px', desktop: '100px' },
    },
    blog: {
      logo: '/assets/logo-blog.svg',
      width: { base: '110px', desktop: '140px' },
    },
    podcast: {
      logo: '/assets/logo-white.svg',
      width: { base: '75px', desktop: '100px' },
    },
  };

  const backgrounds = {
    default: 'white',
    blog: 'white',
    analytics: '#030b17',
    podcast: '#6C1AFF',
  };

  const mobileMenuColor = {
    default: 'sapphire.100',
    blog: 'sapphire.100',
    analytics: 'sapphire.100',
    podcast: 'white',
  };

  return (
    <Box
      as="header"
      sx={{
        ...containerStyles({
          yDistance,
          mobileMenuIsOpen,
          yMobileMenuDistance,
          topSpace,
          background: backgrounds[slug],
        }),
      }}
    >
      <Container
        size="py0"
        display="grid"
        gridTemplateColumns={{ base: 'auto 1fr', desktop: 'auto 1fr auto' }}
        gridGap="12"
        height={{ base: '70px', desktop: '90px' }}
      >
        <Flex alignItems="center">
          <NextLink href={logoLink?.url} passHref>
            <Link height="100%" width={layouts[slug]?.width} position="relative">
              <Image src={layouts[slug]?.logo} layout="fill" alt="Accounts Receivable Software" />
            </Link>
          </NextLink>
        </Flex>
        <Flex display={{ base: 'none', desktop: 'flex' }}>
          <nav>
            <HStack as={List} alignItems="center" spacing="10" height="100%">
              {navItems.map((navItem, i) => {
                if (navItem.component === 'menu') {
                  return (
                    <ListItem key={i}>
                      <DesktopMenu {...navItem} />
                    </ListItem>
                  );
                }
                return (
                  <ListItem key={i}>
                    <NextLink href={navItem.link.url} passHref>
                      <Button
                        as="a"
                        variant={navItem.variant}
                        colorScheme={navItem.colorScheme}
                        fontWeight={navItem.variant === 'link' ? 'normal' : 'semibold'}
                      >
                        {navItem.text}
                      </Button>
                    </NextLink>
                  </ListItem>
                );
              })}
            </HStack>
          </nav>
        </Flex>
        <Flex display={{ base: 'none', desktop: 'flex' }}>
          <HStack alignItems="center" spacing="8">
            {actionItems.map((actionItem, i) => (
              <SmartButtonLink
                key={i}
                href={actionItem.link.url}
                variant={actionItem.variant}
                colorScheme={actionItem.colorScheme}
                fontWeight={actionItem.variant === 'link' ? 'normal' : 'semibold'}
                rightIcon={actionItem.rightIcon && <DynamicIcon icon={actionItem.rightIcon} />}
                borderRadius={actionItem.borderRadius}
              >
                {actionItem.text}
              </SmartButtonLink>
            ))}
          </HStack>
        </Flex>
        <Flex display={{ base: 'flex', desktop: 'none' }} justifyContent="flex-end" alignItems="center">
          <MobileMenu
            topSpace={topSpace}
            yDistance={yDistance}
            setYMobileMenuDistance={setYMobileMenuDistance}
            mobileMenuIsOpen={mobileMenuIsOpen}
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            navItems={[...navItems, ...actionItems.slice(0, -1)]}
            footerItems={actionItems.slice(-1)}
            color={mobileMenuColor[slug]}
          />
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
