import { Box, Container, CSSObject, Flex, Grid, Heading, Tag, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { Storyblok } from 'types';
import React, { useState, VFC } from 'react';

type PricingHeroProps = {
  size: string;
  title: string;
  subtitle: string;
  monthlyText: string;
  yearlyText: string;
  percentageSaving: string;
  plans: PlanCardProps[];
};

const tabActiveStyles: CSSObject = {
  cursor: 'pointer',
  color: 'azure.100',
  borderColor: 'azure.100',
  borderBottomWidth: '1px',
};

const tabInactiveStyles: CSSObject = {
  cursor: 'pointer',
  color: 'sapphire.100',
  borderColor: 'sapphire.10',
  borderBottomWidth: '1px',
};

const PricingHero: VFC<PricingHeroProps> = ({
  size,
  title,
  subtitle,
  monthlyText,
  yearlyText,
  percentageSaving,
  plans,
}) => {
  const [showMonthly, setShowMonthly] = useState(false);

  return (
    <Container as="section" size={size}>
      <Box textAlign="center" mb="62px" maxWidth="lg" mx="auto">
        <Heading as="h1" size="2xl" mb="24px">
          {title}
        </Heading>
        <Text>{subtitle}</Text>
      </Box>

      <Flex align="center" justify="center" mb="56px" mt="78px">
        <Flex
          px="32px"
          py="8px"
          gap="8px"
          sx={showMonthly ? tabInactiveStyles : tabActiveStyles}
          onClick={() => setShowMonthly(false)}
        >
          <Text size="sm" color={showMonthly ? 'sapphire.100' : 'azure.100'}>
            {yearlyText}
          </Text>
          <Tag size="sm" borderRadius="4px" bg={showMonthly ? 'sapphire.50' : 'azure.100'} variant={'solid'}>
            {percentageSaving}
          </Tag>
        </Flex>
        <Text
          sx={showMonthly ? tabActiveStyles : tabInactiveStyles}
          size="sm"
          px="32px"
          py="8px"
          onClick={() => setShowMonthly(true)}
        >
          {monthlyText}
        </Text>
      </Flex>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          tablet: 'repeat(2, 1fr)',
          desktop: plans.length > 3 ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
        }}
        gridAutoRows="column"
        alignItems="end"
        gridGap="24px"
      >
        {plans.map((plan, i) => (
          <PlanCard key={i} {...plan} showMonthly={showMonthly} />
        ))}
      </Grid>
    </Container>
  );
};

export default PricingHero;

type PlanCardProps = {
  name: string;
  period?: string;
  monthlySubtitle?: string;
  yearlySubtitle?: string;
  monthlyAmount: string;
  yearlyAmount: string;
  description: string;
  buttons: Storyblok.Button[];
  showMonthly: boolean;
};

function PlanCard({
  name,
  period,
  monthlySubtitle,
  yearlySubtitle,
  monthlyAmount,
  yearlyAmount,
  description,
  buttons,
  showMonthly,
}: PlanCardProps) {
  const subtitle = showMonthly ? monthlySubtitle : yearlySubtitle;

  return (
    <Flex
      boxShadow="lg"
      borderRadius="4px"
      py="40px"
      px="32px"
      textAlign="center"
      bg="white"
      height="100%"
      flexDir="column"
      justifyContent="flex-start"
    >
      <Text
        fontWeight="bold"
        fontSize="sm"
        textTransform="uppercase"
        color="sapphire.100"
        letterSpacing={1.5}
        lineHeight={1}
        mb="16px"
      >
        {name}
      </Text>

      <Box mt="40px" flexGrow={1}>
        <Flex align="center" justify="center" gap="8px">
          <Heading as="h3" size="lg">
            {showMonthly ? monthlyAmount : yearlyAmount}
          </Heading>
          {period && (
            <Text size="sm" mt="6px" color="sapphire.50">
              {period}
            </Text>
          )}
        </Flex>

        {subtitle && (
          <Text mt="6px" fontSize="12px" lineHeight="16px" color="azure.100">
            {subtitle}
          </Text>
        )}
      </Box>

      <Flex flexDir="column" mt="40px">
        <Text size="sm">{description}</Text>
        <ButtonGroup buttons={buttons} justifyContent="center" mt="16px" width="100%" />
      </Flex>
    </Flex>
  );
}
