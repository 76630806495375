import { Container, Box, Grid, Heading, Text } from '@chakra-ui/react';
import RichText from 'components/common/RichText';

export default function GridOneSection({ size, title, description, cards }) {
  return (
    <Container as="section" size={size}>
      <Box maxWidth="3xl" mx="auto" textAlign="center" mb={{ base: '24px', desktop: '56px' }}>
        <Heading as="h2" size="lg" mb="16px">
          {title}
        </Heading>
        <Text>{description}</Text>
      </Box>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: 'repeat(3, 1fr)' }} gridGap="4">
        {cards.map((card, i) => (
          <Card key={i} {...card} />
        ))}
      </Grid>
    </Container>
  );
}

function Card({ title, content }) {
  return (
    <Box borderRadius="4px" p="24px" boxShadow="xl">
      <Heading as="h3" size="sm" mb="24px">
        {title}
      </Heading>
      <RichText content={content} indentList={false} />
    </Box>
  );
}
