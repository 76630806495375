import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import * as _ from 'lodash';
import { VFC } from 'react';
import { theme } from 'theme';
import { DynamicComponent } from '.';

type InvestorTestimonialGridProps = {
  title: string;
  investorTestimonials: any; // todo: type
  layerStyle: string;
};

const InvestorTestimonialGrid: VFC<InvestorTestimonialGridProps> = ({
  title,
  investorTestimonials,
  layerStyle = 'default',
}) => {
  return (
    <Box backgroundColor={theme.colors.white[90]} width="100%">
      <Container as="section" layerStyle={layerStyle}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Heading as="h2" mb={{ base: '24px', desktop: '48px' }}>
            {title}
          </Heading>
          <Flex
            width="100%"
            wrap="nowrap"
            overflowX="scroll"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
            }}
            gap={{ base: '24px', desktop: '38px' }}
          >
            {investorTestimonials &&
              _.map(investorTestimonials, (investoreTestimonial) => (
                <Box key={investoreTestimonial._uid}>
                  <DynamicComponent block={investoreTestimonial} />
                </Box>
              ))}
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default InvestorTestimonialGrid;
