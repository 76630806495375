import { Box, Container, Heading, Icon, Text } from '@chakra-ui/react';
import { Quote } from 'components/common/icons/assets/Quote';
import Image from 'next/image';
import { Storyblok } from 'types';

type TestimonialTwoSectionProps = {
  size: string;
  layerStyle: string;
  quote: string;
  authorAvatar: Storyblok.Image;
  authorName: string;
  authorJobTitle: string;
};

export default function TestimonialTwoSection({
  size,
  layerStyle,
  quote,
  authorAvatar,
  authorName,
  authorJobTitle,
}: TestimonialTwoSectionProps) {
  return (
    <Container as="section" size={size} layerStyle={layerStyle}>
      <Box mx="auto" maxWidth="4xl" textAlign="center">
        <Icon as={Quote} fontSize={{ base: '25px', desktop: '50px' }} mb={{ base: '16px', desktop: '32px' }} />
        <Heading size="md" mb="32px">
          {quote}
        </Heading>
        <Box as="figure" borderRadius="full" overflow="hidden">
          <Image src={authorAvatar.filename} alt={authorAvatar.alt} height={56} width={56} />
          <Text className="text-accent" mb="4px" mt="16px" fontWeight="medium">
            {authorName}
          </Text>
          <Text fontSize="md">{authorJobTitle}</Text>
        </Box>
      </Box>
    </Container>
  );
}
