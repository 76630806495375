import { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Flex, Grid, Heading, Text, VStack } from '@chakra-ui/react';
import { getJobPostDepartments } from 'lib/ashby';
import RichText from 'components/common/RichText';
import { SectionProps, Storyblok } from 'types';

type Props = SectionProps<{
  title?: string;
  description?: Storyblok.RichText;
  applyButtonText?: string;
  anchorId?: string;
  errorMessage: string;
}>;

export default function JobPostsSection({ size, title, description, errorMessage, applyButtonText, anchorId }: Props) {
  const [jobPostDepartments, setJobPostDepartments] = useState([]);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const jobPostDepartments = await getJobPostDepartments();
      setJobPostDepartments(jobPostDepartments);
    } catch (e) {
      setHasError(true);
    }
  }

  return (
    <Container as="section" size={size} maxWidth="100%" px={{ base: 0, tablet: '40px' }} id={anchorId}>
      <Box
        border="1px solid"
        borderColor="sapphire.10"
        p={{ base: '30px', desktop: '80px' }}
        borderRadius={{ base: 'none', tablet: '16px' }}
        maxWidth="1360px"
        mx="auto"
      >
        <Box mb={{ base: '40px', desktop: '80px' }}>
          <Heading mb="16px">{title}</Heading>
          <RichText content={description} textSpaces={false} />
        </Box>
        <VStack spacing="48px" alignItems="flex-start" divider={<Divider bg="sapphire.10" />}>
          {hasError ? (
            <Text>{errorMessage}</Text>
          ) : (
            jobPostDepartments.map((jobPostDepartment, i) => (
              <Box key={i} width="100%">
                <Heading size="md" mb="32px" opacity="0.7">
                  {jobPostDepartment.department}
                </Heading>
                <Grid gridGap="32px">
                  {jobPostDepartment.jobs.map((jobPost: any, i: number) => (
                    <Grid key={i} gridTemplateColumns={{ base: '1fr', desktop: '1fr auto' }} gridGap="10px">
                      <Box>
                        <Heading size="sm" fontWeight="500" mb="8px">
                          {jobPost.title}
                        </Heading>
                        <Text>{jobPost.location}</Text>
                      </Box>
                      <Flex alignItems="center">
                        <a href={jobPost.applyUrl} target="_blank" rel="noreferrer">
                          <Button
                            size="lg"
                            fontSize={{ base: 'sm', desktop: 'md' }}
                            height={{ base: 8, desktop: '48px' }}
                            px={{ base: 3, desktop: '24px' }}
                            minWidth={{ base: 8, desktop: 12 }}
                          >
                            {applyButtonText}
                          </Button>
                        </a>
                      </Flex>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))
          )}
        </VStack>
      </Box>
    </Container>
  );
}
