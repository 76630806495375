import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Heading,
  List,
  ListItem,
  Stack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LinkRouter } from 'components/common/link-router';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { SectionProps, Storyblok } from 'types';
import { VFC } from 'react';

type FeatureTwoProps = SectionProps<{
  tag?: string;
  title: string;
  description: string;
  listItems?: { icon: string; text: string }[];
  buttons?: Storyblok.Button[];
  image: Storyblok.Image;
  direction: 'ltr' | 'rtl';
  footerListItems?: { title: string; description?: string }[];
  titleAs?: string;
  titleSize?: string;
  layerStyle?: string;
  gridTemplateColumns?: string;
}>;

const FeatureTwoSection: VFC<FeatureTwoProps> = ({
  size,
  tag,
  title,
  description,
  listItems = [],
  buttons,
  image,
  direction = 'ltr',
  footerListItems,
  titleAs = 'h2',
  titleSize = 'xl',
  layerStyle = 'default',
  gridTemplateColumns = '1fr 1fr',
}) => {
  return (
    <Container as="section" size={size} layerStyle={layerStyle}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: gridTemplateColumns }} gridColumnGap="5">
        <Flex alignItems="center" justifyContent="center" order={{ base: 2, desktop: direction === 'ltr' ? 1 : 2 }}>
          <Box
            textAlign={{ base: 'center', desktop: 'left' }}
            mr={{ base: 0, desktop: direction === 'ltr' ? 24 : 0 }}
            ml={{ base: 0, desktop: direction === 'rtl' ? 24 : 0 }}
          >
            {tag && (
              <Tag colorScheme="whiteAlpha" mb="16px">
                {tag}
              </Tag>
            )}
            <Heading as={titleAs as any} size={titleSize} mb="24px">
              {title}
            </Heading>
            <Text mb="24px">{description}</Text>
            {listItems && (
              <List mb="24px" spacing="8px">
                {listItems.map((listItem, i) => {
                  return (
                    <ListItem key={i} lineHeight="22px" display="flex">
                      <Flex as="span" mr="2" alignItems="flex-start">
                        <DynamicIcon icon={listItem.icon} fontSize={18} />
                      </Flex>
                      {listItem.text}
                    </ListItem>
                  );
                })}
              </List>
            )}
            <Stack spacing="2" direction={{ base: 'column', desktop: 'row' }} mb="32px">
              {buttons?.map((button, i) => {
                return (
                  <LinkRouter key={i} href={button.link.url} newWindow>
                    <Button
                      size="lg"
                      variant={button.variant}
                      leftIcon={button.leftIcon && <DynamicIcon icon={button.leftIcon} fontSize="16px" />}
                      rightIcon={button.rightIcon && <DynamicIcon icon={button.rightIcon} fontSize="16px" />}
                    >
                      {button.text}
                    </Button>
                  </LinkRouter>
                );
              })}
            </Stack>
            {footerListItems.length > 0 && (
              <>
                <Flex justifyContent={{ base: 'center', desktop: 'flex-start' }}>
                  <Divider width="240px" mb="32px" bg="#eef0f3" opacity="1" />
                </Flex>
                <VStack spacing="24px" align={{ base: 'center', desktop: 'flex-start' }}>
                  {footerListItems.map((listItem, i) => {
                    return (
                      <Box key={i} textAlign={{ base: 'center', desktop: 'left' }}>
                        <Text fontWeight="medium" color="sapphire.100">
                          {listItem.title}
                        </Text>
                        {listItem.description && <Text>{listItem.description}</Text>}
                      </Box>
                    );
                  })}
                </VStack>
              </>
            )}
          </Box>
        </Flex>
        <Flex pt="25px" order={{ base: 2, desktop: direction === 'ltr' ? 2 : 1 }}>
          <Flex as="figure" alignItems="center" borderRadius="4px" overflow="hidden">
            <Image
              src={image.filename}
              alt={image.alt}
              height={imageDimensions(image.filename).height}
              width={imageDimensions(image.filename).width}
            />
          </Flex>
        </Flex>
      </Grid>
    </Container>
  );
};

export default FeatureTwoSection;
