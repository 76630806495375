import { Icon } from '@chakra-ui/react';

export const Cube = (props) => (
  <Icon viewBox="0 0 25 25" fill="none" {...props}>
    <path d="M12.2 12.2V16.7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20.7 15.9472V8.45277C20.7 8.10171 20.6076 7.75683 20.4321 7.4528C20.2565 7.14877 20.004 6.8963 19.7 6.72077L13.2 2.96799C12.896 2.79246 12.5511 2.70004 12.2 2.70004C11.8489 2.70004 11.5041 2.79246 11.2 2.96799L4.70001 6.72072C4.39598 6.89625 4.14351 7.14872 3.96797 7.45275C3.79244 7.75678 3.70002 8.10166 3.70001 8.45272V15.9472C3.70002 16.2982 3.79244 16.6431 3.96797 16.9471C4.14351 17.2512 4.39598 17.5036 4.70001 17.6792L11.2 21.432C11.5041 21.6076 11.8489 21.7 12.2 21.7C12.5511 21.7 12.896 21.6076 13.2 21.432L19.7 17.6793C20.0041 17.5037 20.2565 17.2512 20.4321 16.9472C20.6076 16.6432 20.7 16.2983 20.7 15.9472V15.9472Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2 12.2L16.2 9.7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2 12.2L8.20001 9.7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
