import { VFC } from 'react';
import RichTextContent from 'components/common/RichText';
import { Container } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';

type TranslatableSectionsProps = SectionProps<{
  content: Storyblok.RichText;
}>;

const TranslatableSections: VFC<TranslatableSectionsProps> = ({ size, content }) => {
  return (
    <Container as="section" size={size} px={0}>
      <RichTextContent content={content} _p={{ mb: 0 }} />
    </Container>
  );
};

export default TranslatableSections;
