import { As, Box, Container, Divider, Flex, Grid, Heading, HStack, Link, List, ListItem, Text } from '@chakra-ui/react';
import Breadcrumbs from 'components/common/Breadcrumbs';
import { ButtonGroup } from 'components/common/ButtonGroup';
import FormRouter from 'components/common/FormRouter';
import RichText from 'components/common/RichText';
import Image from 'next/image';
import { GridProps, SectionProps, Storyblok } from 'types';
import { imageDimensions, parseBreakpointStr } from 'utils/storyblok';
import CustomGrid from '../common/Grid';

type HeroOneProps = SectionProps<{
  headline: string;
  headlineAs: As;
  title: string;
  titleAs: As;
  description: string;
  form: Storyblok.Form;
  formConfig: Storyblok.FormConfig;
  formCaption: Storyblok.RichText;
  formCaptionButton: any;
  underFormGrid?: GridProps[];
  buttons: any;
  buttonsDescription: string;
  listItems: any;
  footerItems: any;
  image: Storyblok.Image;
  titleSize: string;
  gridMinHeight: string;
  imagePosition: string;
  imageMargin: string;
  imageWidth: string;
}>;

export default function HeroOneSection({
  size,
  headline,
  headlineAs,
  title,
  titleAs,
  description,
  form,
  formConfig,
  formCaption,
  formCaptionButton,
  underFormGrid,
  buttons,
  buttonsDescription,
  listItems,
  footerItems,
  image,
  titleSize,
  gridMinHeight,
  imagePosition,
  imageMargin,
  imageWidth,
}: HeroOneProps) {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }} minHeight={parseBreakpointStr(gridMinHeight)}>
        <Flex direction="column">
          <Breadcrumbs />
          <Flex alignItems="center" justifyContent={{ base: 'center', desktop: 'flex-start' }} grow={1}>
            <Box maxWidth="lg" textAlign={{ base: 'center', desktop: 'left' }} my={{ base: '64px', desktop: 0 }}>
              {headline && (
                <Heading as={headlineAs} size="12px" fontWeight="medium" mb="12px" color="azure.100">
                  {headline}
                </Heading>
              )}
              <Heading as={titleAs} size={titleSize} mb={{ base: '16px', desktop: '32px' }}>
                {title}
              </Heading>
              <Text mb={{ base: '24px', desktop: '48px' }}>{description}</Text>
              {form && (
                <Grid gridGap="16px">
                  <FormRouter form={form} formConfig={formConfig} />
                  <HStack spacing="4px" display={{ base: 'none', desktop: 'flex' }}>
                    <RichText content={formCaption} size="sm" textSpaces={false} />
                    <ButtonGroup buttons={formCaptionButton} />
                  </HStack>
                </Grid>
              )}
              {buttons?.length > 0 && (
                <Grid gridTemplateColumns={{ base: '1fr', desktop: 'auto 1fr' }} gridColumnGap="24px" gridRowGap="10px">
                  <ButtonGroup buttons={buttons} />
                  {buttonsDescription && (
                    <Flex alignItems="center" justifyContent={{ base: 'center', desktop: 'flex-start' }}>
                      <Text color="sapphire.50" fontSize="sm">
                        {buttonsDescription}
                      </Text>
                    </Flex>
                  )}
                </Grid>
              )}
              {underFormGrid && (
                <Container py={{ base: '16px', desktop: '32px' }} px={0}>
                  <Grid>
                    {underFormGrid.map((gridProps, index) => (
                      <CustomGrid {...gridProps} key={index} />
                    ))}
                  </Grid>
                </Container>
              )}
              {listItems?.length > 0 && (
                <>
                  <Flex justifyContent={{ base: 'center', desktop: 'flex-start' }}>
                    <Divider width="240px" mb="32px" bg="#eef0f3" opacity="1" />
                  </Flex>
                  <List spacing="24px">
                    {listItems.map((listItem, i) => {
                      return (
                        <ListItem key={i}>
                          <Text fontWeight="500" mb="12px" lineHeight="1" color="sapphire.100">
                            {listItem.title}
                          </Text>
                          <Text lineHeight="1">{listItem.description}</Text>
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              )}
              {footerItems?.map((footerItem, i) => {
                if (footerItem.component === 'heroOneFooterLogoItem') {
                  return (
                    <Flex key={i} mt="14" flexDirection="column" alignItems={{ base: 'center', desktop: 'flex-start' }}>
                      <Box as="figure" mb="4" height={footerItem.height + 'px'} width="100%" position="relative">
                        <Image
                          src={footerItem.logo.filename}
                          alt={footerItem.logo.alt}
                          layout="fill"
                          objectFit="contain"
                          objectPosition="top left"
                          priority
                        />
                      </Box>
                      <Link href={footerItem.link.url} target="_blank" variant="visible">
                        {footerItem.text}
                      </Link>
                    </Flex>
                  );
                }
              })}
            </Box>
          </Flex>
        </Flex>
        <Flex position="relative">
          <Flex
            as="figure"
            position={{ base: 'static', desktop: imagePosition === 'extendRight' ? 'absolute' : 'static' }}
            left="0"
            width={{ base: 'auto', desktop: imagePosition === 'extendRight' ? imageWidth : 'auto' }}
            margin={parseBreakpointStr(imageMargin)}
            borderRadius="4px"
            overflow="hidden"
          >
            <Image
              src={image.filename}
              alt={image.alt}
              height={imageDimensions(image.filename).height}
              width={imageDimensions(image.filename).width}
              priority
            />
          </Flex>
        </Flex>
      </Grid>
    </Container>
  );
}
