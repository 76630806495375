import { Icon } from '@chakra-ui/react';

export const Triangle = (props) => (
  <Icon viewBox="0 0 23 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.144 9.21115C22.6181 9.94819 22.6181 12.0518 21.144 12.7889L3.61611 21.5528C2.2863 22.2177 0.72168 21.2507 0.72168 19.7639V2.23607C0.72168 0.749305 2.2863 -0.217687 3.61611 0.447214L21.144 9.21115Z"
      fill="currentColor"
    />
  </Icon>
);
