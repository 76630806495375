import { Container, CSSObject, Flex, Heading, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import IconBackground from 'components/common/IconBackground';
import { LinkRouter } from 'components/common/link-router';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

const PageSuggestionsSection = ({ size, title, description, pages }) => {
  return (
    <Container as="section" textAlign="center" size={size}>
      <Heading as="h2" size="lg" mb="16px">
        {title}
      </Heading>
      <Text mb="24px">{description}</Text>
      <Flex justifyContent="center" direction={{ base: 'column', desktop: 'row' }} mx={{ base: 0, desktop: '-2Opx' }}>
        {pages.map(({ _uid, title, description, icon, colorScheme, link }) => (
          <LinkBox key={_uid} sx={cardStyles} role="group" cursor="pointer">
            <Flex alignItems="center" direction={{ base: 'row', desktop: 'column' }}>
              <IconBackground
                colorScheme={colorScheme}
                size={96}
                mb={{ base: 0, desktop: '32px' }}
                mr={{ base: '32px', desktop: 0 }}
              >
                <DynamicIcon icon={icon} fontSize="40px" />
              </IconBackground>
              <div>
                <LinkRouter href={link.url}>
                  <LinkOverlay>
                    <Heading as="h3" size="sm" textAlign={{ base: 'left', desktop: 'center' }}>
                      {title}
                    </Heading>
                  </LinkOverlay>
                </LinkRouter>
                <Text mt="4px" align={{ base: 'left', desktop: 'center' }} size="sm">
                  {description}
                </Text>
              </div>
            </Flex>
          </LinkBox>
        ))}
      </Flex>
    </Container>
  );
};

export default PageSuggestionsSection;

const cardStyles: CSSObject = {
  boxShadow: 'xl',
  borderRadius: '4px',
  background: 'white',
  width: '100%',
  margin: {
    base: '16px 0 0',
    desktop: '40px 20px 0',
  },
  padding: {
    base: '32px',
    desktop: '48px',
  },
  maxWidth: {
    desktop: '373px',
  },
  transition: 'transform 200ms ease-out',
  _hover: {
    transform: 'translateY(-6px)',
  },
};
