import { Box, Button, Container, Grid, Heading, Text } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import { containerHeight as headerHeight } from 'components/layout/header/styles';
import NextLink from 'next/link';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { useAnchorsObserver } from 'lib/hooks/useAnchorsObserver';

const OBSERVED_ANCHOR_CLASS_NAME = 'sidebar-anchor';

const StickyNavContentSection = ({ size, title, description, navTitle, navItems, navButtons, content }) => {
  const defaultNavId = navItems[0].anchorId;
  const currentAnchorId = useAnchorsObserver(OBSERVED_ANCHOR_CLASS_NAME) ?? defaultNavId;
  const navIndex = navItems.findIndex((navItem) => navItem.anchorId === currentAnchorId);

  return (
    <Container as="section" size={size}>
      <Box textAlign="center" mb="80px">
        <Heading as="h2" size="lg" mb="16px" maxWidth="1024px" mx="auto">
          {title}
        </Heading>
        <Text maxWidth="560px" mx="auto">
          {description}
        </Text>
      </Box>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '368px auto' }} gridColumnGap="48px" position="relative">
        <Box display={{ base: 'none', desktop: 'block' }}>
          <Box
            position="sticky"
            top={`calc(40px + ${headerHeight.desktop}px)`}
            borderRadius="4px"
            boxShadow="xl"
            width="100%"
            p="40px"
          >
            <Text variant="uppercase" opacity=".5" size="sm" mb="24px">
              {navTitle}
            </Text>
            <Grid gridGap="8px" position="relative" mb="24px">
              <Box
                as="span"
                height="30px"
                bg="azure.100"
                width="4px"
                borderRadius="2px"
                position="absolute"
                left="-43px"
                top={`${navIndex * 30 + navIndex * 8}px`}
                transition="all .5s cubic-bezier(.215,.61,.355,1)"
              />
              {navItems.map((navItem) => (
                <NextLink key={navItem.index} href={`#${navItem.anchorId}`} passHref>
                  <Button
                    as="a"
                    variant="link"
                    color={currentAnchorId === navItem.anchorId ? 'sapphire.100' : 'sapphire.50'}
                    fontWeight="semibold"
                    height="30px"
                    justifyContent="flex-start"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    display="block"
                    textAlign="left"
                  >
                    {navItem.title}
                  </Button>
                </NextLink>
              ))}
            </Grid>
            <ButtonGroup buttons={navButtons} width="100%" />
          </Box>
        </Box>
        <Box>
          <RichText content={content} indentList={false} anchorClassName={OBSERVED_ANCHOR_CLASS_NAME} />
        </Box>
      </Grid>
    </Container>
  );
};
export default StickyNavContentSection;
