import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import CarrouselAuto, { CustomerLogos } from 'components/common/CarrouselAuto';
import Image from "next/image";
import { useState, VFC } from 'react';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';


type FrmHeroProps = {
  tag: string;
  heading: string;
  description: string;
  buttons: Storyblok.Button[];
  video: Storyblok.Video;
  placeholder: Storyblok.Image;
  leaderTitle: string;
  logos: CustomerLogos[];
};

const FrmHero: VFC<FrmHeroProps> = ({
  tag,
  heading,
  description,
  buttons,
  video,
  placeholder,
  leaderTitle,
  logos,
}) => {

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Box bgColor="azure.8" width="100%">
      <Container as="section">
        <Box
          display={{ base: 'block', desktop: 'flex' }}
          gap="32"
        >
          <Box>
            <Box
              paddingX="3"
              paddingY="2"
              bgColor="azure.10"
              width="fit-content"
              rounded="md"
              marginY="32px"
            >
              <Text
                as="h2"
                size="sm"
                textColor="blue.600"
                fontWeight="semibold"
              >
                {tag}
              </Text>
            </Box>
            <Heading
              as="h1"
              size="lg"
              mb="24px"
              fontWeight="bold"
            >
              {heading}
            </Heading>
            <Text
              as="h3"
              size="md"
              marginBottom="36px"
            >
              {description}
            </Text>
            <ButtonGroup
              buttons={buttons}
              marginBottom="36px"
              direction="row"
              _button={{
                width: "fit"
              }}
            />
          </Box>
          <Box
            height="280px"
            width="100%"
            display={{ base: "none", desktop: "block" }}
            position="relative"
          >
            {/* Placeholder Image */}
            {!isVideoLoaded && ( // Hide the placeholder when video is loaded
              <Box
                position="absolute"
                inset={0}
                zIndex={1} // Placeholder will be underneath the video
              >
                <Image
                  src={placeholder.filename}
                  alt={placeholder.alt}
                  width={imageDimensions(placeholder.filename).width}
                  height={imageDimensions(placeholder.filename).height}
                />
              </Box>
            )}

            {/* Video Element on top */}
            <Box
              position="absolute"
              inset={0}
              zIndex={2}
            >
              <Box>
                <video
                  autoPlay
                  muted
                  playsInline
                  loop
                  style={{ width: '100%', height: '100%' }}
                  onLoadedData={handleVideoLoad} // Set aspect ratio when video loads
                >
                  <source src={video.filename} type={video.mime_type} />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Box>
          </Box>
        </Box>
        <Text
          as="h4"
          size="sm"
          marginBottom="36px"
          textAlign="center"
          fontWeight="semibold"
          textColor="blue.600"
        >
          {leaderTitle}
        </Text>
        <CarrouselAuto
          logos={logos}
          fadeColor={{ from: "#ebf3ff", to: "#ebf3ff00" }}
        />
      </Container>
    </Box>
  );
};

export default FrmHero;
