import { Link, LinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

type LinkRouterProps = LinkProps & {
  newWindow?: boolean;
  children: React.ReactNode;
};

export function LinkRouter({ href, newWindow = false, width = 'auto', children }: LinkRouterProps) {
  if (href.startsWith('http')) {
    return (
      <Link
        variant="unstyled"
        href={href}
        width={width}
        {...(newWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        {children}
      </Link>
    );
  }
  return <NextLink href={href}>{children}</NextLink>;
}
