import kebabCase from 'lodash/kebabCase';

export function slugify(str) {
  if (!str) {
    return null;
  }
  const sanitizedStr = str
    .replace(/^\s+/g, '')
    .replace(/\s+$/g, '')
    .replace(/^\d/, '')
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, '')
    .toLowerCase();
  const slug = kebabCase(sanitizedStr);
  return slug;
}
