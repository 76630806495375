import { Icon } from '@chakra-ui/react';

const colorModes = {
  dark: '#0B254B',
  light: 'white',
};

const dotColorModes = {
  default: '#267FFD',
  dark: '#0B254B',
  light: 'white',
};

export const Logotype = ({ colorMode = 'dark', dotColorMode = 'default', ...props }) => (
  <Icon
    height="26px"
    width="auto"
    viewBox="0 0 215 56"
    fill="none"
    sx={{ path: { transition: 'fill 1s cubic-bezier(.215,.61,.355,1)' } }}
    {...props}
  >
    <path
      d="M184.386 9.80522L190.668 32.1828L196.951 9.80522H204.489L194.438 45.6094H186.899L180.617 23.2318L174.334 45.6094H166.795L156.744 9.80522H164.283L170.565 32.1828L176.847 9.80522H184.386Z"
      fill={colorModes[colorMode]}
    />
    <path
      d="M209.383 36.5815H209.438C211.938 36.5815 213.965 38.637 213.965 41.1725V41.2608C213.965 43.7963 211.938 45.8517 209.438 45.8517H209.383C206.883 45.8517 204.856 43.7963 204.856 41.2608V41.1725C204.856 38.637 206.883 36.5815 209.383 36.5815Z"
      fill={dotColorModes[dotColorMode]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.975 9.80522H136.934C127.23 9.80522 119.364 17.8203 119.364 27.7073C119.364 37.5943 127.23 45.6094 136.934 45.6094H136.975C146.679 45.6094 154.545 37.5943 154.545 27.7073C154.545 17.8203 146.679 9.80522 136.975 9.80522ZM126.903 27.7073C126.903 22.0575 131.398 17.4775 136.943 17.4775H136.966C142.511 17.4775 147.006 22.0575 147.006 27.7073C147.006 33.357 142.511 37.937 136.966 37.937H136.943C131.398 37.937 126.903 33.357 126.903 27.7073Z"
      fill={colorModes[colorMode]}
    />
    <path
      d="M104.286 34.1C104.286 40.456 109.343 45.6085 115.581 45.6085H115.595V37.9362C113.513 37.9362 111.825 36.2167 111.825 34.0955V23.2432V-0.000843048H104.286V34.1Z"
      fill={colorModes[colorMode]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.7477 14.7907H92.9783V11.513C92.9783 9.39184 94.6659 7.67232 96.7477 7.67232V0H96.7344C90.4964 0 85.4395 5.15252 85.4395 11.5085V14.7907H81.6701V22.463H85.4395V45.6094H92.9783V22.463H96.7477V14.7907ZM50.2585 55.8391V42.4069C53.1027 44.4256 56.5605 45.6094 60.2896 45.6094H60.3308C70.0344 45.6094 77.9007 37.5943 77.9007 27.7073C77.9007 17.8202 70.0344 9.80522 60.3308 9.80522H60.2896C56.5605 9.80522 53.1027 10.9889 50.2585 13.0077V9.80522H42.7197V55.8391H50.2585ZM60.2984 17.4775C54.7535 17.4775 50.2585 22.0576 50.2585 27.7073C50.2585 33.357 54.7535 37.9371 60.2984 37.9371H60.322C65.8669 37.9371 70.3619 33.357 70.3619 27.7073C70.3619 22.0576 65.8669 17.4775 60.322 17.4775H60.2984ZM0 9.80522V27.7073C0 37.5943 7.86629 45.6094 17.5698 45.6094H17.6111C21.3402 45.6094 24.798 44.4256 27.6422 42.4069V45.6094H35.181V9.80522H27.6422V20.3575V27.7073C27.6422 33.357 23.1472 37.937 17.6023 37.937H17.5787C12.0338 37.937 7.53878 33.357 7.53878 27.7073V13.0077V9.80522H0Z"
      fill={colorModes[colorMode]}
    />
  </Icon>
);

export const Logo = ({ colorMode = 'dark', dotColorMode = 'default', ...props }) => (
  <Icon
    height="26px"
    width="auto"
    viewBox="0 0 70 48"
    fill="none"
    sx={{ path: { transition: 'fill 1s cubic-bezier(.215,.61,.355,1)' } }}
    {...props}
  >
    <path
      d="M0 23.9997V0H10.2857V4.29332V23.9997C10.2857 31.5738 16.4186 37.7139 23.9838 37.7139H24.016C31.5813 37.7139 37.7142 31.5738 37.7142 23.9997V14.1465V0H47.9999V47.9995H37.7142V43.7061C33.8336 46.4126 29.116 47.9995 24.0281 47.9995H23.9718C10.7325 47.9995 0 37.2544 0 23.9997Z"
      fill={colorModes[colorMode]}
    />
    <ellipse cx="63.4974" cy="41.7825" rx="6.22522" ry="6.21755" fill="#267FFD" />
  </Icon>
);

export const LogotypeBlog = (props) => (
  <Icon height="26px" width="auto" viewBox="0 0 141 26" fill="none" {...props}>
    <path
      d="M85.5955 4.55704L88.5118 14.9563L91.4282 4.55704H94.9278L90.2617 21.1958H86.762L83.8456 10.7966L80.9293 21.1958H77.4296L72.7634 4.55704H76.2631L79.1794 14.9563L82.0958 4.55704H85.5955Z"
      fill="#0B254B"
    />
    <path
      d="M97.1995 17.0004H97.225C98.3856 17.0004 99.3265 17.9556 99.3265 19.1339V19.1749C99.3265 20.3532 98.3856 21.3084 97.225 21.3084H97.1995C96.0389 21.3084 95.098 20.3532 95.098 19.1749V19.1339C95.098 17.9556 96.0389 17.0004 97.1995 17.0004Z"
      fill="#267FFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.5864 4.55704H63.5673C59.0627 4.55704 55.411 8.28175 55.411 12.8764C55.411 17.4711 59.0627 21.1958 63.5673 21.1958H63.5864C68.091 21.1958 71.7427 17.4711 71.7427 12.8764C71.7427 8.28175 68.091 4.55704 63.5864 4.55704ZM58.9107 12.8764C58.9107 10.2509 60.9973 8.12249 63.5714 8.12249H63.5823C66.1564 8.12249 68.2431 10.2509 68.2431 12.8764C68.2431 15.5019 66.1564 17.6303 63.5823 17.6303H63.5714C60.9973 17.6303 58.9107 15.5019 58.9107 12.8764Z"
      fill="#0B254B"
    />
    <path
      d="M48.4117 15.8472C48.4117 18.8009 50.7593 21.1954 53.655 21.1954H53.6612V17.6299C52.6948 17.6299 51.9114 16.8309 51.9114 15.8451V0H48.4117V15.8472Z"
      fill="#0B254B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.9121 6.87387H43.1623V5.35067C43.1623 4.36494 43.9457 3.56585 44.9121 3.56585V0.000398636H44.9059C42.0101 0.000398636 39.6626 2.39486 39.6626 5.34857V6.87387H37.9128V10.4393H39.6626V21.1958H43.1623V10.4393H44.9121V6.87387ZM23.331 25.9497V19.7075C24.6513 20.6457 26.2565 21.1958 27.9876 21.1958H28.0067C32.5113 21.1958 36.163 17.4711 36.163 12.8764C36.163 8.28175 32.5113 4.55704 28.0067 4.55704H27.9876C26.2565 4.55704 24.6513 5.10713 23.331 6.0453V4.55704H19.8313V25.9497H23.331ZM27.9917 8.12249C25.4176 8.12249 23.331 10.2509 23.331 12.8764C23.331 15.5019 25.4176 17.6303 27.9917 17.6303H28.0026C30.5767 17.6303 32.6633 15.5019 32.6633 12.8764C32.6633 10.2509 30.5767 8.12249 28.0026 8.12249H27.9917ZM0 4.55704V12.8764C0 17.4711 3.65168 21.1958 8.15625 21.1958H8.17542C9.90653 21.1958 11.5117 20.6457 12.832 19.7075V21.1958H16.3317V4.55704H12.832V12.8764C12.832 15.5019 10.7454 17.6304 8.17131 17.6304H8.16036C5.58632 17.6304 3.49964 15.5019 3.49964 12.8764V4.55704H0Z"
      fill="#0B254B"
    />
    <g opacity="0.5">
      <path
        d="M109.409 19.9996H113.614C116.364 19.9996 117.5 18.6587 117.5 16.9087C117.5 15.0678 116.227 14.0678 115.159 13.9996V13.886C116.159 13.6133 117.046 12.9542 117.046 11.4542C117.046 9.74965 115.909 8.36328 113.477 8.36328H109.409V19.9996ZM110.818 18.7496V14.7042H113.682C115.205 14.7042 116.159 15.7269 116.159 16.9087C116.159 17.9315 115.455 18.7496 113.614 18.7496H110.818ZM110.818 13.4769V9.61328H113.477C115.023 9.61328 115.705 10.4315 115.705 11.4542C115.705 12.6815 114.705 13.4769 113.432 13.4769H110.818Z"
        fill="#0B254B"
      />
      <path d="M120.975 8.36328H119.634V19.9996H120.975V8.36328Z" fill="#0B254B" />
      <path
        d="M126.976 20.1815C129.34 20.1815 130.93 18.386 130.93 15.6815C130.93 12.9542 129.34 11.1587 126.976 11.1587C124.612 11.1587 123.021 12.9542 123.021 15.6815C123.021 18.386 124.612 20.1815 126.976 20.1815ZM126.976 18.9769C125.18 18.9769 124.362 17.4315 124.362 15.6815C124.362 13.9315 125.18 12.3633 126.976 12.3633C128.771 12.3633 129.59 13.9315 129.59 15.6815C129.59 17.4315 128.771 18.9769 126.976 18.9769Z"
        fill="#0B254B"
      />
      <path
        d="M136.5 23.4542C138.591 23.4542 140.273 22.4996 140.273 20.2496V11.2724H138.977V12.6587H138.841C138.546 12.2042 138 11.1587 136.296 11.1587C134.091 11.1587 132.568 12.9087 132.568 15.5906C132.568 18.3178 134.159 19.8633 136.273 19.8633C137.977 19.8633 138.523 18.8633 138.818 18.386H138.932V20.1587C138.932 21.6133 137.909 22.2724 136.5 22.2724C134.915 22.2724 134.358 21.4371 134 20.9542L132.932 21.7042C133.477 22.619 134.551 23.4542 136.5 23.4542ZM136.455 18.6587C134.773 18.6587 133.909 17.386 133.909 15.5678C133.909 13.7951 134.75 12.3633 136.455 12.3633C138.091 12.3633 138.955 13.6815 138.955 15.5678C138.955 17.4996 138.068 18.6587 136.455 18.6587Z"
        fill="#0B254B"
      />
    </g>
  </Icon>
);
