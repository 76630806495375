import { useState, useEffect } from 'react';
import { Container, Box, Heading, Text, Grid } from '@chakra-ui/react';
import { useViewportScroll, useTransform } from 'framer-motion';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { ButtonGroup } from 'components/common/ButtonGroup';

export default function ScrollGallerySection({ size, title, description, buttons, images }) {
  const [currentPercent, setCurrentPercent] = useState(0);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 1], [0, 100]);

  useEffect(() => {
    yRange.onChange((v) => {
      setCurrentPercent(v);
    });
  }, [yRange]);

  return (
    <Container as="section" size={size} overflow={{ base: 'hidden', desktop: 'visible' }}>
      <Box textAlign="center" mb="88px">
        <Heading as="h1" size="2xl" maxWidth="xl" mx="auto" mb="24px">
          {title}
        </Heading>
        <Text maxWidth="4xl" mx="auto" mb="40px">
          {description}
        </Text>
        <ButtonGroup buttons={buttons} justifyContent="center" />
      </Box>
      <Grid gridGap="5">
        {images.map((image, i) => (
          <Box
            key={i}
            ml={i % 2 ? '-600px' : '-175px'}
            height={{ base: '120px', desktop: '240px' }}
            width={{ base: '1012px', desktop: '2024px' }}
            transform={`translateX(${i % 2 ? currentPercent : -currentPercent}%)`}
            transition="transform 1s cubic-bezier(.215,.61,.355,1)"
          >
            <Image
              src={image.filename}
              alt={image.alt}
              height={imageDimensions(image.filename).height}
              width={imageDimensions(image.filename).width}
            />
          </Box>
        ))}
      </Grid>
    </Container>
  );
}
