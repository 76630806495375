import { Container, Heading, Flex, Box } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import Image from 'next/image';

export default function QuoteSection({ size, title, text, logo, logoHeight, logoWidth }) {
  return (
    <Container as="section" size={size}>
      <Flex mx="auto" maxWidth="4xl" textAlign="center" alignItems="center" flexDirection="column">
        <Heading mb="16px">{title}</Heading>
        <Box py="8px" mb="20px">
          <RichText content={text} />
        </Box>
        <Box as="figure" maxWidth={{ base: '50%', desktop: 'auto' }}>
          <Image src={logo.filename} alt={logo.alt} height={logoHeight} width={logoWidth} />
        </Box>
      </Flex>
    </Container>
  );
}
