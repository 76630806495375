import { Container, Box, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import RichText from 'components/common/RichText';

export default function ContentCardSection({ size, title, description, content, buttons }) {
  return (
    <Container as="section" size={size}>
      <Box boxShadow="xl" borderRadius="4px" p={{ base: '24px', desktop: '80px' }}>
        <Heading as="h2" size="lg" mb="16px">
          {title}
        </Heading>
        <Text mb="32px">{description}</Text>
        {content && <RichText content={content} size="sm" />}
        <ButtonGroup buttons={buttons} _button={{ whiteSpace: 'normal', textAlign: 'left' }} />
      </Box>
    </Container>
  );
}
