export const containerStyle = {
  alignItems: 'flex-start',
  height: '40px',
  mt: '20px',
  _hover: {
    '.animated-icon-rotate': {
      transform: 'rotate(-180deg) translateY(-2px)',
      transition: 'transform 0.2s ease 0s',
    },
  },
};

// Layer style for brands
// hover background = brand color with 6-8% opacity on white background

export const layerStyles = {
  default: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(243, 245, 248)',
        },
        '.icon-container': {
          background: 'rgb(11, 37, 75)',
        },
        '.icon': {
          color: 'white',
        },
        '.tag': {
          bg: 'azure.100',
          color: 'white',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(243, 245, 248)',
        },
        '.icon-container': {
          background: 'rgb(11, 37, 75)',
        },
        '.icon': {
          color: 'white',
        },
        '.tag': {
          bg: 'azure.100',
          color: 'white',
        },
      },
    },
    iconContainer: {
      bg: 'rgb(243, 245, 248)',
    },
    icon: {
      color: 'rgb(11, 37, 75)',
    },
    tag: {
      bg: 'azure.8',
      color: 'azure.100',
    },
  },
  blue: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'azure.8',
        },
        '.icon-container': {
          background: 'azure.100',
        },
        '.icon': {
          color: 'white',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'azure.8',
        },
        '.icon-container': {
          background: 'azure.100',
        },
        '.icon': {
          color: 'white',
        },
      },
    },
    iconContainer: {
      bg: 'azure.8',
    },
    icon: {
      color: 'azure.100',
    },
  },
  green: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'green.12',
        },
        '.icon-container': {
          background: 'green.100',
        },
        '.icon': {
          color: 'white',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'green.12',
        },
        '.icon-container': {
          background: 'green.100',
        },
        '.icon': {
          color: 'white',
        },
      },
    },
    iconContainer: {
      bg: 'green.12',
    },
    icon: {
      color: 'green.100',
    },
  },
  orange: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'orange.8',
        },
        '.icon-container': {
          background: 'orange.100',
        },
        '.icon': {
          color: 'white',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'orange.8',
        },
        '.icon-container': {
          background: 'orange.100',
        },
        '.icon': {
          color: 'white',
        },
      },
    },
    iconContainer: {
      bg: 'orange.8',
    },
    icon: {
      color: 'orange.100',
    },
  },
  quickbooks: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(237, 248, 235)',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(237, 248, 235)',
        },
      },
    },
    iconContainer: {
      bg: '#2CA01C',
      color: 'white',
    },
  },
  xero: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(236, 249, 253)',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(236, 249, 253)',
        },
      },
    },
    iconContainer: {
      bg: '#06b3e8',
      color: 'white',
    },
    icon: {
      fontSize: '26px',
    },
  },
  chargebee: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: '#ffefeb',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: '#ffefeb',
        },
      },
    },
    iconContainer: {
      bg: '#efefef',
      color: 'white',
    },
    icon: {
      fontSize: '18px',
    },
  },
  stripe: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(243, 243, 245)',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(243, 243, 245)',
        },
      },
    },
    iconContainer: {
      bg: '#32325D',
      color: 'white',
    },
  },
  zuora: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: '#f1fcfa',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: '#f1fcfa',
        },
      },
    },
    iconContainer: {
      bg: '#132D23',
      color: '#00D2B9',
    },
    icon: {
      fontSize: '20px',
    },
  },
  sage: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: '#E5FBEB',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: '#E5FBEB',
        },
      },
    },
    iconContainer: {
      bg: '#03D101',
      color: 'white',
    },
  },
  sellsy: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(240, 240, 246)',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(240, 240, 246)',
        },
      },
    },
    iconContainer: {
      bg: '#02016F',
      color: 'white',
    },
    icon: {
      fontSize: '20px',
    },
  },
  netsuite: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(240, 240, 240)',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(240, 240, 240)',
        },
      },
    },
    iconContainer: {
      bg: 'black',
      color: 'white',
    },
    icon: {
      fontSize: '26px',
    },
  },
  pennylane: {
    container: {
      _hover: {
        bg: {
          base: 'none',
          desktop: 'rgb(235, 243, 243)',
        },
      },
      _focus: {
        bg: {
          base: 'none',
          desktop: 'rgb(235, 243, 243)',
        },
      },
    },
    iconContainer: {
      // bg: 'rgb(235, 243, 243)',
      color: 'white',
    },
    icon: {
      fontSize: '32px',
    },
  },
};

export const titleStyle = {
  fontSize: '14px',
  lineHeight: 1,
  fontWeight: 'medium',
};

export const descriptionStyle = {
  fontSize: {
    base: '12px',
    desktop: '14px',
  },
  lineHeight: 1,
  color: 'gray.70',
};
