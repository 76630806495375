import { VFC } from 'react';
import { FormErrorMessage } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { Storyblok } from 'types';

type FormErrorProps = {
  error: FieldError | undefined;
  formConfig: Storyblok.FormConfig;
};

const FormError: VFC<FormErrorProps> = ({ error, formConfig }) => {
  return (
    <FormErrorMessage>
      {error?.type === 'required' && formConfig.requiredFieldMessage}
      {error?.type === 'pattern' && formConfig.invalidEmailMessage}
      {error?.type === 'invalidEmail' && formConfig.invalidEmailMessage}
      {error?.type === 'personalEmail' && formConfig.personalEmailMessage}
      {error?.type === 'botBlocked' && formConfig.botBlockedMessage}
      {error?.type === 'genericError' && formConfig.genericErrorMessage}
    </FormErrorMessage>
  );
};

export default FormError;
