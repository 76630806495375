import { Box, Container, Flex, Grid, Heading } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import container from 'theme/components/container';

type CategoryHeroProps = {
  size: string;
  title: string;
  text: StoryblokRichtext;
};

const CategoryHeroSection = ({ size, title, text }: CategoryHeroProps) => {
  return (
    <Container
      as="section"
      size={size}
      background="url('/assets/hero-bg.jpg') right no-repeat"
      backgroundSize="cover"
      marginY="20px"
      maxWidth="100%"
      width="100%"
    >
      <Grid
        gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }}
        padding={{ base: 'auto', desktop: `0 ${container.baseStyle.px}` }}
        margin="auto"
        gridRowGap="32px"
        maxWidth={container.baseStyle.maxWidth}
      >
        <Flex>
          <Box>
            <Heading as="h1" size="xl" mb="24px">
              {title}
            </Heading>
            <RichText content={text} indentList={false} />
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
};

export default CategoryHeroSection;
