import { Box, Container, Heading } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import Image from 'next/image';
import { VFC } from 'react';
import { theme } from 'theme';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';

type G2BannerProps = {
  title: string;
  image: Storyblok.Image;
  button: Storyblok.Button[];
  layerStyle: string;
};

const G2Banner: VFC<G2BannerProps> = ({ title, image, button, layerStyle = 'default' }) => {
  return (
    <Box backgroundColor={theme.colors.orange[8]} width="100%">
      <Container as="section" layerStyle={layerStyle}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Heading as="h2" textAlign="center">{title}</Heading>
          <Box mt="48px">
            <Image
              src={image.filename}
              width={imageDimensions(image.filename).width}
              height={imageDimensions(image.filename).height}
              alt={image.alt}
            />
          </Box>
          {button?.length > 0 && (
            <ButtonGroup
              width={{ base: 'min-content' }}
              buttons={button}
              alignItems="left"
              position="relative"
              zIndex="1"
              mt="48px"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default G2Banner;
