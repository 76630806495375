import { AspectRatio, Box, Container, Heading, Icon, Text } from '@chakra-ui/react';
import { ArrowRightAnimated } from 'components/common/icons/assets/ArrowRightAnimated';
import { Clock } from 'components/common/icons/assets/Clock';
import Image from "next/image";
import NextLink from "next/link";
import { VFC } from 'react';
import { Storyblok } from 'types';

/* Types */

type Podcast = {
  slug: string;
  title: string;
  time: string;
  image: Storyblok.Image;
}

type PodcastListProps = {
  title: string;
  ratio: number;
  podcasts: Podcast[];
  ctaText: string;
};

/* Widgets */

const Podcasts: VFC<PodcastListProps> = ({
  title,
  ratio,
  ctaText,
  podcasts,
}) => {
  return (
    <Container as="section">
      <Heading
        as="h1"
        size="md"
        fontWeight="bold"
        width="full"
        mb={6}
      >
        {title}
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gap={6}
        width="full"
      >
        {podcasts.map((podcast, i) => (
          <Box
            key={i}
            border="1px"
            borderColor="blackAlpha.100"
            rounded="md"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            width="100%"
            padding={3}
            gap={3}
          >
            <AspectRatio
              width="full"
              ratio={ratio}
              position="relative"
              rounded="md"
              overflow="hidden"
              flexShrink={0}
            >
              <Image
                src={podcast.image.filename}
                alt={podcast.image.alt}
                layout="fill"
                objectFit="cover"
              />
            </AspectRatio>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={1}
              padding={2}
            >
              <Heading
                as="h2"
                size="sm"
                fontWeight="semibold"
                width="full"
                mb={6}
              >
                {podcast.title}
              </Heading>
              <Box
                display="flex"
                justifyContent="space-between"
                width="full"
              >
                <Box
                  bgColor="azure.10"
                  width="fit-content"
                  rounded="full"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  paddingX="2"
                  height="24px"
                >
                  <Icon as={Clock} />
                  <Text
                    fontSize="10px"
                    fontWeight="semibold"
                    textColor="sapphire.100"
                  >
                    {podcast.time}
                  </Text>
                </Box>
                <NextLink href={`/growthmindedcfo/${podcast.slug}`} passHref>
                  <a>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={2}
                      color="azure.100"
                    >
                      <Text
                        size="xs"
                        textColor="azure.100"
                      >
                        {ctaText}
                      </Text>
                      <Icon as={ArrowRightAnimated} fontSize="15px" />
                    </Box>
                  </a>
                </NextLink>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Podcasts;
