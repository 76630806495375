import { useState } from 'react';
import { Storyblok } from 'types';
import { Flex, Icon, IconButton } from '@chakra-ui/react';
import ModalVideo from 'react-modal-video';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { Triangle } from 'components/common/icons/assets/Triangle';

type VideoProps = {
  youtubeId: string;
  thumbnail: Storyblok.Image;
};

export default function Video({ youtubeId, thumbnail }: VideoProps) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Flex as="figure" position="relative" borderRadius="4px" alignItems="center" overflow="hidden">
      <Image
        src={thumbnail.filename}
        alt={thumbnail.alt}
        height={imageDimensions(thumbnail.filename).height}
        width={imageDimensions(thumbnail.filename).width}
      />
      <ModalVideo
        channel="youtube"
        autoplay
        allowFullScreen
        isOpen={isOpen}
        videoId={youtubeId}
        onClose={() => setOpen(false)}
      />
      <IconButton
        aria-label="Play"
        icon={<Icon as={Triangle} fontSize="24px" />}
        onClick={() => setOpen(true)}
        height="96px"
        width="96px"
        borderRadius="full"
        position="absolute"
        top="50%"
        bottom="50%"
        right="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        mx="auto"
        colorScheme="whiteAzure"
      />
    </Flex>
  );
}
