import { Icon } from '@chakra-ui/react';

export const LinkedIn = ({ mb = '4px', ...props }) => (
  <Icon viewBox="0 0 12 12" mb={mb} {...props}>
    <path
      fill="currentColor"
      d="M2.70520231,12 L0.277456647,12 L0.277456647,4.02312139 L2.77456647,4.02312139 L2.77456647,12 L2.70520231,12 Z M1.4566474,2.9132948 C0.693641618,2.9132948 0,2.28901734 0,1.4566474 C0,0.693641618 0.624277457,0 1.4566474,0 C2.21965318,0 2.9132948,0.624277457 2.9132948,1.4566474 C2.9132948,2.28901734 2.28901734,2.9132948 1.4566474,2.9132948 Z M12,12 L9.50289017,12 L9.50289017,8.11560694 C9.50289017,7.21387283 9.50289017,6.03468208 8.25433526,6.03468208 C6.93641618,6.03468208 6.79768786,7.00578035 6.79768786,8.04624277 L6.79768786,12 L4.30057803,12 L4.30057803,4.02312139 L6.65895954,4.02312139 L6.65895954,5.13294798 L6.65895954,5.13294798 C7.00578035,4.50867052 7.76878613,3.88439306 9.01734104,3.88439306 C11.5144509,3.88439306 12,5.54913295 12,7.69942197 L12,12 Z"
    />
  </Icon>
);
