import { Box, HStack, SpaceProps, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { formatDate } from 'utils/formatDate';
import { Locale, Storyblok } from 'types';
import { VFC } from 'react';

type AuthorProps = {
  author: Storyblok.Author;
  date: string;
  locale: Locale;
} & SpaceProps;

const Author: VFC<AuthorProps> = ({ author, date, locale, ...spacing }) => {
  return (
    <HStack spacing="16px" {...spacing}>
      <Box as="figure" w="40px" h="40px" position="relative" rounded="20px" overflow="hidden">
        <Image
          src={author.picture.filename}
          alt={author.picture.alt}
          layout="fill"
          objectFit="cover"
          objectPosition="top"
        />
      </Box>
      <VStack alignItems="flex-start" justifyContent="space-between" spacing="0">
        <Text fontSize="md" fontWeight="medium" color="sapphire.100">
          {author.name}
        </Text>
        <Text fontSize="sm" mt="0">
          {formatDate(date, 'PP', locale)}
        </Text>
      </VStack>
    </HStack>
  );
};

export default Author;
