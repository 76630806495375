import { Box, Button, Grid, Heading } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRef, VFC } from "react";

function getCorrespondingCustomListItem(id, customListItems) {
  return customListItems?.find((customListItem) => customListItem.url.split('#')[1] === id);
}

export const Summary: VFC<any> = ({ activeItemId, title, listItems, customListItems }) => {
  const ref = useRef(null);

  const visibleItems = listItems.filter((listItem) => {
    const customListItem = getCorrespondingCustomListItem(listItem.id, customListItems);
    return !customListItem?.hide;
  });

  const activeItemIndex = Math.max(
    0,
    visibleItems.findIndex((listItem) => listItem.id === activeItemId),
  );

  const activeSummaryItem = ref.current?.childNodes[activeItemIndex + 1];

  if (visibleItems.length === 0) {
    return null;
  }

  return (
    <>
      <Heading as="p" size="sm" mb="12px">
        {title}
      </Heading>
      <Grid gridGap="8px" position="relative" ref={ref}>
        <Box
          as="span"
          height={`${activeSummaryItem?.clientHeight ?? 0}px`}
          bg="azure.100"
          width="4px"
          borderRadius="2px"
          position="absolute"
          left="-24px"
          top={`${activeSummaryItem?.offsetTop ?? 0}px`}
          transition="all .5s cubic-bezier(.215,.61,.355,1)"
        />
        {visibleItems.map((listItem) => {
          const customListItem = getCorrespondingCustomListItem(listItem.id, customListItems);
          const isSelected = activeItemId === listItem.id;

          return (
            <NextLink key={listItem.id} href={`#${listItem.id}`} passHref>
              <Button
                as="a"
                variant="link"
                color={isSelected ? 'sapphire.100' : 'sapphire.50'}
                fontWeight="normal"
                fontSize="sm"
                lineHeight="20px"
                textAlign="left"
                justifyContent="flex-start"
                whiteSpace="normal"
                noOfLines={2}
              >
                {customListItem?.newName || listItem.name}
              </Button>
            </NextLink>
          );
        })}
      </Grid>
    </>
  );
};