import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import SmartButtonLink from 'components/common/SmartButtonLink';
import React from 'react';
import { Storyblok } from 'types';

interface CustomerStoryProps {
  companyLogo: Storyblok.Image;
  title: string;
  storyLink: {
    cached_url: string;
    fieldtype: string;
    id: string;
    linktype: string;
    url: string;
  };
  storyImage: Storyblok.Image;
  cardCta: string;
}

const CustomerStory: React.FC<CustomerStoryProps> = ({ companyLogo, title, storyLink, storyImage, cardCta }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      overflow="hidden"
      bg="white"
      width={{ base: '342px', md: '100%' }}
      boxShadow="md"
      height="400px"
    >
      <Box height="40%" width="100%">
        <Image
          src={storyImage.filename}
          height="100%"
          width="100%"
          objectFit="cover"
          alt={storyImage.alt}
          borderTopRadius="md"
        />
      </Box>
      <VStack justifyContent="space-between" height="60%" overflowY="auto" p="24px">
        <VStack>
          <Flex mb={2} height="24px" width="100%" alignItems="center">
            <Image src={companyLogo.filename} alt={companyLogo.alt} width="auto" height="100%" />
          </Flex>
          <Text fontWeight="500" fontSize="17px" lineHeight="24px" color="#0B254B">
            {title}
          </Text>
        </VStack>
        <SmartButtonLink alignSelf="start" href={storyLink.cached_url} variant="link" color="#267FFD">
          {cardCta}
        </SmartButtonLink>
      </VStack>
    </Box>
  );
};

export default CustomerStory;
