import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useTranslation } from 'lib/hooks/useTranslation';
import { Storyblok } from 'types';

type SeoProps = {
  metadata: Storyblok.Metadata;
  noindex: boolean;
  nofollow: boolean;
  /** By default, all locales will be added to the hreflang
   *  If this prop is defined, it will replace the list of locales supported for this page
   **/
  supportedLocales?: string[];
};

export default function Seo({ metadata, noindex, nofollow, supportedLocales }: SeoProps) {
  const { asPath } = useRouter();
  const { defaultLocale, locale, locales } = useTranslation();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {(supportedLocales ?? locales).map((l) => (
          <link
            key={l}
            rel="alternate"
            hrefLang={l}
            href={stripQueryStringAndHashFromPath(
              `https://upflow.io${l === defaultLocale ? asPath : `/${l}${asPath}`}`,
            )}
          />
        ))}
        <meta name="facebook-domain-verification" content="qf56qutzltzxhm80iegxdwv8gkp9nr" />
      </Head>
      <NextSeo
        noindex={noindex}
        nofollow={nofollow}
        title={metadata.title}
        description={metadata.description}
        canonical={stripQueryStringAndHashFromPath(
          `https://upflow.io${locale === defaultLocale ? asPath : `/${locale}${asPath}`}`,
        )}
        openGraph={{
          site_name: metadata.title,
          type: 'website',
          url: `https://upflow.io${asPath}`,
          title: metadata.og_title,
          description: metadata.og_description,
          images: [
            {
              url: metadata.og_image ? metadata.og_image : 'https://upflow.io/meta/en@2x.png',
              width: 2048,
              height: 1170,
              alt: 'Default OG Image',
            },
          ],
        }}
      />
    </>
  );
}

function stripQueryStringAndHashFromPath(inputUrl: string): string {
  const url = new URL(inputUrl);
  url.search = '';
  url.hash = '';
  return url.toString();
}
