import { VFC } from 'react';
import { Box, Center, Container, Grid, GridItem, Heading } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import Image from 'next/image';
import { Storyblok } from '../../types';
import { imageDimensions } from '../../utils/storyblok';
import { colors } from '../../utils/colorScheme';

type QuoteTwoProps = {
  image: Storyblok.Image;
  blueBackground: boolean;
  text: Storyblok.RichText;
  title: string;
};

const QuoteTwo: VFC<QuoteTwoProps> = ({ image, title, text, blueBackground }) => {
  return (
    <Container as="section" size="py50">
      <Center>
        <Grid
          templateColumns={{ base: '1fr', tablet: 'repeat(3, 1fr)' }}
          px={{ base: '24px', desktop: '44px' }}
          py={{ base: '24px', desktop: '56px' }}
          bg={blueBackground ? colors.blue.lighter : undefined}
          borderRadius="4px"
          gap="16px"
        >
          <GridItem colSpan={1} placeSelf="center">
            <Box as="figure" paddingX={{ base: '0px', desktop: '52px' }}>
              <Image
                src={image.filename}
                alt={image.alt}
                height={imageDimensions(image.filename).height}
                width={imageDimensions(image.filename).width}
              />
            </Box>
          </GridItem>

          <GridItem colSpan={{ base: 1, tablet: 2 }}>
            <Heading mb="24px">{title}</Heading>
            <RichText
              content={text}
              _p={{
                fontSize: {
                  base: '20px',
                  desktop: '24px',
                },
                lineHeight: {
                  base: '32px',
                  desktop: '48px',
                },
              }}
            />
          </GridItem>
        </Grid>
      </Center>
    </Container>
  );
};

export default QuoteTwo;
