import { useTranslation } from 'lib/hooks/useTranslation';
import { useCurrency } from 'lib/hooks/useCurrency';
import { Currency } from 'types';

export function useFormatAmount(): (
  amount: number,
  options?: {
    minimumFractionDigits?: number;
    appendMillionsSuffix?: boolean;
  },
) => string {
  const { locale } = useTranslation();
  const currency = useCurrency();

  return (
    amount: number,
    options: {
      minimumFractionDigits?: number;
      appendMillionsSuffix?: boolean;
    } = {},
  ) => {
    const { minimumFractionDigits = 0, appendMillionsSuffix = false } = options;

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
    });

    let formattedAmount = formatter.format(amount);

    if (currency === Currency.USD) {
      formattedAmount = formattedAmount.replace('US', '');
    } else if (currency === Currency.GBP) {
      formattedAmount = formattedAmount.replace('GB', '');
    }

    if (appendMillionsSuffix) {
      formattedAmount = locale === 'fr' ? formattedAmount.replace(/([\d,]+)/, '$1M') : `${formattedAmount}M`;
    }

    return formattedAmount;
  };
}
