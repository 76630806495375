import { useState } from 'react';
import Header from './header';
import Footer from './footer';
import Seo from './seo';
import Banner from './banner';
import { Box } from '@chakra-ui/react';
import CookiesConsentBanner from './cookies-consent-banner';
import { bannerHeight } from './header/styles';

type LayoutProps = {
  supportedLocales: string[];
};

const Layout = ({
  cookiesConsentBanner,
  banner,
  hideBanner,
  header,
  background,
  footer,
  footerLayout,
  metadata,
  noindex,
  nofollow,
  children,
  supportedLocales,
}) => {
  const [showCookiesConsentBanner, setShowCookiesConsentBanner] = useState(false);

  return (
    <>
      <Seo metadata={metadata} noindex={noindex} nofollow={nofollow} supportedLocales={supportedLocales} />
      <CookiesConsentBanner
        showCookiesConsentBanner={showCookiesConsentBanner}
        setShowCookiesConsentBanner={setShowCookiesConsentBanner}
        {...cookiesConsentBanner}
      />
      {banner && !hideBanner && <Banner {...banner} />}
      {header && <Header topSpace={bannerHeight} {...header} />}
      <Box as="main" background={background}>
        {children}
      </Box>
      <Footer layout={footerLayout} {...footer} />
    </>
  );
};

export default Layout;
