import { VFC } from 'react';
import { Box, Container, Flex, Heading, HStack, Icon, Text } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';
import Image from 'next/image';
import Masonry from 'react-masonry-css';
import { Quote } from 'components/common/icons/assets/Quote';

type TestimonialsGridSectionProps = SectionProps<{
  title: string;
  description: string;
  testimonials: {
    _uid: number;
    avatar: Storyblok.Image;
    name: string;
    jobTitle: string;
    quote: string;
  }[];
}>;

const TestimonialsGridSection: VFC<TestimonialsGridSectionProps> = ({ size, title, description, testimonials }) => {
  return (
    <Container as="section" size={size}>
      <Box bg="neutral.60" p={{ base: '40px 34px', desktop: '80px 68px' }} borderRadius="4px">
        <Box textAlign="center">
          <Icon as={Quote} fontSize="88px" color="azure.100" mb="24px" />
          <Heading as="h2" size="lg" mb="16px" maxWidth="1024px" mx="auto">
            {title}
          </Heading>
          {description && (
            <Text mb="26px" maxWidth="560px" mx="auto">
              {description}
            </Text>
          )}
        </Box>
        <Flex as={Masonry} breakpointCols={{ default: 3, 500: 1 }} ml="-16px">
          {testimonials.map((testimonial) => (
            <Box key={testimonial._uid} bg="white" boxShadow="lg" borderRadius="4px" p="32px" ml="16px" mb="16px">
              <HStack spacing="15px" mb="22px">
                <Flex as="figure" borderRadius="full" overflow="hidden" alignItems="center">
                  <Image src={testimonial.avatar.filename} alt={testimonial.avatar.alt} height={50} width={50} />
                </Flex>
                <Box>
                  <Text fontSize="md" lineHeight="24px" mb="2px" fontWeight="bold" color="black">
                    {testimonial.name}
                  </Text>
                  <Text fontSize="md" lineHeight="24px">
                    {testimonial.jobTitle}
                  </Text>
                </Box>
              </HStack>
              <Text color="black">{testimonial.quote}</Text>
            </Box>
          ))}
        </Flex>
      </Box>
    </Container>
  );
};

export default TestimonialsGridSection;
