import { Icon } from '@chakra-ui/react';

export const ChevronDown = (props) => (
  <Icon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M4.03033 6.21967C3.73744 5.92678 3.26256 5.92678 2.96967 6.21967C2.67678 6.51256 2.67678 6.98744 2.96967 7.28033L6.46967 10.7803C6.76256 11.0732 7.23744 11.0732 7.53033 10.7803L11.0303 7.28033C11.3232 6.98744 11.3232 6.51256 11.0303 6.21967C10.7374 5.92678 10.2626 5.92678 9.96967 6.21967L7 9.18934L4.03033 6.21967Z"
      fill="currentColor"
    />
  </Icon>
);
