import { Box, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { ReactNode } from 'react';

type ResponsiveScrollableWrapperProps = {
  children: ReactNode;
  cardWidth?: string | { base: string; desktop: string };
  desktopColumns?: string;
} & FlexProps;

const ResponsiveScrollableWrapper = ({
  children,
  cardWidth = '70%',
  desktopColumns = '25%',
  ...flexProps
}: ResponsiveScrollableWrapperProps) => {
  return (
    <Flex
      justifyContent={{ base: 'flex-start', lg: 'center' }}
      overflowX={{ base: 'scroll', lg: 'hidden' }}
      flexWrap="nowrap"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}
      {...flexProps}
    >
      {React.Children.map(children, (child, idx) => (
        <Box key={idx} flex={{ base: '0 0 auto', lg: `1 1 calc(${desktopColumns})` }} width={cardWidth}>
          {child}
        </Box>
      ))}
    </Flex>
  );
};

export default ResponsiveScrollableWrapper;
