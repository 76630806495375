import { Box, Divider } from '@chakra-ui/react';
import { FC } from 'react';

interface DividerSectionProps {
  color?: string;
  backgroundColor?: string;
}

const DividerSection: FC<DividerSectionProps> = ({ color = '#000000', backgroundColor }) => {
  return (
    <Box backgroundColor={backgroundColor}>
      <Divider backgroundColor={color} maxWidth="1200px" opacity="1" mx="auto" />
    </Box>
  );
};

export default DividerSection;
