import { Box, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { Waves } from 'components/common/Shapes';
import Image from 'next/image';
import { VFC } from 'react';
import { theme } from 'theme';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';

type Props = {
  title: string;
  description: string;
  buttons: Storyblok.Button[];
  image: Storyblok.Image;
};

const CallToActionTwo: VFC<Props> = ({ title, description, buttons, image }) => {
  return (
    <Box backgroundColor={theme.colors.azure[100]} width="100%">
      <Container as="section" size="ptHeader">
        <Flex direction="column" justifyContent="center" align="center" position="relative">
          <Box position="absolute" top={{ base: '-120px', desktop: '-194px' }}>
            <Image
              src={image.filename}
              width={imageDimensions(image.filename).width}
              height={imageDimensions(image.filename).height}
              alt={image.alt}
            />
          </Box>
          <Heading as="h2" color={theme.colors.white[90]}>
            {title}
          </Heading>
          <Text as="p" color={theme.colors.white[90]} fontSize="17px" lineHeight="24px" mt="8px">
            {description}
          </Text>
          {buttons?.length > 0 && <ButtonGroup width={{ base: 'min-content' }} buttons={buttons} mt="40px" />}
        </Flex>
      </Container>
    </Box>
  );
};

export default CallToActionTwo;
