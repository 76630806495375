import { Icon, BreadcrumbItem, BreadcrumbLink, Breadcrumb } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { useRouter } from 'next/router';
import { BracketRight } from './icons/assets/BracketRight';
import { Home } from './icons/assets/Home';

export default function Breadcrumbs() {
  const router = useRouter();
  const paths = router.query.slug;
  const breadcrumbs =
    Array.isArray(paths) && paths.length > 1
      ? paths.slice(0, -1).map((path, index) => ({
          name: startCase(path),
          href: `/${paths.slice(0, index + 1).join('/')}`,
        }))
      : undefined;

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Breadcrumb separator={<Icon as={BracketRight} fontSize="10px" color="sapphire.100" />}>
      <BreadcrumbItem>
        <BreadcrumbLink href="/">
          <Icon as={Home} fontSize="14px" color="sapphire.100" />
        </BreadcrumbLink>
      </BreadcrumbItem>
      {breadcrumbs.map(({ name, href }) => (
        <BreadcrumbItem key={href}>
          <BreadcrumbLink href={href} fontWeight="semibold" fontSize="xs" color="azure.100">
            {name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}
