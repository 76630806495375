import { Box, Container, Divider, Flex, Grid, Heading, Text, VStack } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

export default function CompanyDetailsSection({ size, numbers, infoLogo, infoItems, content }) {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridGap="5" mb="64px">
        {numbers.map((number, i) => (
          <Number key={i} {...number} />
        ))}
      </Grid>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '3fr 5fr' }} gridGap="20" position="relative">
        <Box>
          <Box
            width="100%"
            // position="sticky"
            // top="calc(80px + 64px)"
            borderRadius="4px"
            p={{ base: '32px 32px 8px', desktop: '40px 40px 16px' }}
            boxShadow="xl"
          >
            <Box mb={{ base: '20px', desktop: '40px' }} width={{ base: '25%', desktop: '50%' }}>
              <Image
                src={infoLogo.filename}
                alt={infoLogo.alt}
                height={imageDimensions(infoLogo.filename).height}
                width={imageDimensions(infoLogo.filename).width}
              />
            </Box>
            <VStack
              divider={<Divider bg="#eef0f3" opacity="1" />}
              spacing={{ base: '12px', desktop: '24px' }}
              mb="16px"
            >
              {infoItems.map((infoItem, i) => (
                <Box key={i} width="100%">
                  <Text mb="6px" fontSize="14px" lineHeight="24px">
                    {infoItem.title}
                  </Text>
                  <RichText
                    content={infoItem.content}
                    indentList={false}
                    size="sm"
                    textSpaces={false}
                    _p={{ fontWeight: 500 }}
                    _ul={{ fontWeight: 500 }}
                  />
                </Box>
              ))}
            </VStack>
          </Box>
        </Box>
        <Box>
          <RichText content={content} indentList={false} _ul={{ fontWeight: 500 }} />
        </Box>
      </Grid>
    </Container>
  );
}

function Number({ title, description, icon }) {
  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
      <DynamicIcon icon={icon} fontSize="48px" color="azure.100" />
      <Heading
        fontSize={{ base: '32px', desktop: '64px' }}
        lineHeight={{ base: '40px', desktop: '64px' }}
        mt="24px"
        mb="16px"
      >
        {title}
      </Heading>
      <Text>{description}</Text>
    </Flex>
  );
}
