import { Container, Flex, Icon, Link, Text } from '@chakra-ui/react';
import CarrouselAuto from 'components/common/CarrouselAuto';
import { FiveStars } from 'components/common/icons/assets/FiveStars';

export default function CustomersSection({
  size,
  title,
  logos,
  showTitle,
  showReviews,
  reviews,
  layerStyle = 'white',
}) {
  const logoFilters = {
    white: 'none',
    'dark-blue': 'brightness(0) invert(1)',
  };

  return (
    <Container as="section" size={size}>
      {showTitle && (
        <Text
          as="h2"
          variant="uppercase"
          size="xs"
          textAlign="center"
          opacity=".5"
          mb={{ base: '24px', desktop: '32px' }}
        >
          {title}
        </Text>
      )}
      {showReviews && reviews?.length > 0 && (
        <Flex justifyContent="center" direction={{ base: 'column', desktop: 'row' }} mb="28px">
          {reviews.map((review, i) => (
            <Link
              key={i}
              href={review.link.url}
              target="_blank"
              rel="noreferrer"
              variant="accent"
              fontWeight="500"
              textTransform="uppercase"
              display="inline-flex"
              justifyContent="center"
              fontSize="sm"
              letterSpacing=".5px"
              mx="20px"
              mb="20px"
            >
              {review.text}
              <Flex as="span" ml="15px">
                <Icon as={FiveStars} width="112px" />
              </Flex>
            </Link>
          ))}
        </Flex>
      )}
      <CarrouselAuto
        logos={logos?.concat(logos)}
        filter={logoFilters[layerStyle]}
      />
    </Container>
  );
}