import { useEffect, useState, VFC } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  computeSliderValueFromTurnover,
  computeTurnoverFromSliderValue,
  getCostOfLatePayments,
  getCostOfUnpaidInvoices,
} from 'components/sections/CostEstimatorSection/helpers';
import { useFormatAmount } from 'lib/hooks/useFormatAmount';
import { Information } from 'components/common/icons/assets/Information';
import { LinkRouter } from 'components/common/link-router';
import { useRouter } from 'next/router';
import { useTranslation } from 'lib/hooks/useTranslation';

const DEFAULT_TURNOVER = 5;
const DEFAULT_AVERAGE_PAYMENT_DELAY = 74;
const DEFAULT_UNPAID_INVOICES_RATE = 1;

type CostEstimatorSectionProps = {
  size: string;
  title: string;
  description: string;
  titleAs: any;
  titleSize: string;
};

const CostEstimatorSection: VFC<CostEstimatorSectionProps> = ({ title, description, size, titleAs, titleSize }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const formatAmount = useFormatAmount();
  const [turnover, setTurnover] = useState<number>(DEFAULT_TURNOVER);
  const [averagePaymentDelay, setAveragePaymentDelay] = useState<number>(DEFAULT_AVERAGE_PAYMENT_DELAY);
  const [unpaidInvoicesRate, setUnpaidInvoicesRate] = useState<number>(DEFAULT_UNPAID_INVOICES_RATE);

  useEffect(() => {
    if (router.query.turnover) {
      setTurnover(Number(router.query.turnover));
    }
    if (router.query.delay) {
      setAveragePaymentDelay(Number(router.query.delay));
    }
    if (router.query.rate) {
      setUnpaidInvoicesRate(Number(router.query.rate));
    }
  }, [router.query?.turnover, router.query?.delay, router.query?.rate]);

  const costOfLatePayment = getCostOfLatePayments(turnover, averagePaymentDelay);
  const costOfUnpaidInvoices = getCostOfUnpaidInvoices(turnover, unpaidInvoicesRate);
  const totalCost = costOfLatePayment + costOfUnpaidInvoices;

  return (
    <Container as="section" size={size}>
      {title && description && (
        <Box textAlign="center" mb="60px" maxWidth="2xl" mx="auto">
          <Heading as={titleAs} size={titleSize} mb="16px">
            {title}
          </Heading>
          <Text>{description}</Text>
        </Box>
      )}
      <Flex width="100%" alignItems="center" direction={{ base: 'column', desktop: 'row' }} maxW="944px" mx="auto">
        <Box flex="1" width={{ base: '90%', desktop: 'auto' }}>
          <Box
            borderRadius="4px"
            bg="white"
            padding={{ base: '32px 24px', desktop: '44px 40px' }}
            boxShadow="xl"
            position="relative"
            zIndex={1}
            left={{ base: 0, desktop: '8px' }}
          >
            {/* Turnover */}
            <LabelValue
              label={t.costEstimator['turnover.label']}
              value={formatAmount(turnover, { appendMillionsSuffix: true })}
            />
            <Slider
              aria-label="slider-turnover"
              value={computeSliderValueFromTurnover(turnover)}
              defaultValue={DEFAULT_TURNOVER}
              min={0}
              max={53}
              step={1}
              onChange={(v) => setTurnover(computeTurnoverFromSliderValue(v))}
              size="lg"
              mb="40px"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb data-focus-visible-added />
            </Slider>
            {/* Average payment delay */}
            <LabelValue
              label={t.costEstimator['paymentDelay.label']}
              tooltipContent={t.costEstimator['paymentDelay.tooltip']}
              value={`${averagePaymentDelay} ${t.costEstimator['paymentDelay.unit']}`}
            />
            <Slider
              aria-label="slider-average-payment-delay"
              value={averagePaymentDelay}
              defaultValue={DEFAULT_AVERAGE_PAYMENT_DELAY}
              min={0}
              max={150}
              step={1}
              onChange={(v) => setAveragePaymentDelay(v)}
              size="lg"
              mb="40px"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb data-focus-visible-added />
            </Slider>
            {/* Unpaid invoices rate */}
            <LabelValue
              label={t.costEstimator['unpaidRate.label']}
              tooltipContent={t.costEstimator['unpaidRate.tooltip']}
              value={`${unpaidInvoicesRate}%`}
            />
            <Slider
              aria-label="slider-unpaid-invoices-rate"
              value={unpaidInvoicesRate}
              defaultValue={DEFAULT_UNPAID_INVOICES_RATE}
              min={0}
              max={5}
              step={0.1}
              onChange={(v) => setUnpaidInvoicesRate(v)}
              size="lg"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb data-focus-visible-added />
            </Slider>
          </Box>
        </Box>
        <Box flex="1" width={{ base: '100%', desktop: 'auto' }}>
          <Box
            borderRadius="4px"
            bg="azure.100"
            boxShadow="xl.primary"
            position="relative"
            right={{ base: 0, desktop: '8px' }}
            top={{ base: '-100px', desktop: 0 }}
            paddingTop={{ base: '128px', desktop: 0 }}
          >
            <Flex direction="column" alignItems="center" padding={{ base: '0 32px 40px', desktop: '40px 80px' }}>
              <Text size="sm" color="white.70" fontWeight="medium" mb="24px" lineHeight="shorter">
                {t.costEstimator['totalCost']}
              </Text>
              <Text fontSize="4xl" fontWeight="semibold" color="white" lineHeight="shorter">
                {formatAmount(totalCost)}
              </Text>
              <Text fontSize="sm" lineHeight="shorter" mt="4px" color="white">
                / {t.costEstimator['costUnit']}
              </Text>
              <Flex
                justifyContent="space-between"
                width="100%"
                mt="32px"
                py="8px"
                borderBottom="1px solid"
                borderBottomColor="white.20"
              >
                <Text color="white.70" fontSize="sm">
                  {t.costEstimator['latePaymentCost']}
                </Text>
                <Text color="white" fontSize="sm" fontWeight="medium">
                  {formatAmount(costOfLatePayment)} / {t.costEstimator['costUnit']}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" width="100%" py="8px">
                <Text color="white.70" fontSize="sm">
                  {t.costEstimator['unpaidInvoicesCost']}
                </Text>
                <Text color="white" fontSize="sm" fontWeight="medium">
                  {formatAmount(costOfUnpaidInvoices)} / {t.costEstimator['costUnit']}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              bg="white.10"
              padding={{ base: '24px 32px 32px', desktop: '24px 56px 32px' }}
            >
              <Text
                size="sm"
                color="white"
                fontWeight="500"
                align="center"
                padding={{ base: '0 0 16px', desktop: '0 36px 24px' }}
              >
                {t.costEstimator['cta.description']}
              </Text>
              <LinkRouter href="/demo">
                <Button variant="solid" colorScheme="lightWhiteAzure" width="100%">
                  {t.costEstimator['cta.button']}
                </Button>
              </LinkRouter>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default CostEstimatorSection;

type LabelValueProps = {
  label: string;
  tooltipContent?: string;
  value: string;
};

const LabelValue: VFC<LabelValueProps> = ({ label, tooltipContent, value }) => {
  return (
    <Flex alignItems="center" mb="12px">
      <Text size="sm" color="sapphire.100">
        {label}
      </Text>
      {tooltipContent && (
        <Tooltip label={tooltipContent} placement="top">
          <Box as="span" color="sapphire.50" ml="8px">
            <Information />
          </Box>
        </Tooltip>
      )}
      <Box flexGrow={1} />
      <Text size="sm" color="azure.100" fontWeight="medium">
        {value}
      </Text>
    </Flex>
  );
};
