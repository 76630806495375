import { Button, Flex, Stack } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { useAnalytics } from 'lib/Analytics';

const COOKIE_CONSENT_KEY = 'consent';

export default function CookiesConsentBanner({
  showCookiesConsentBanner,
  setShowCookiesConsentBanner,
  text,
  acceptButtonText,
  declineButtonText,
}) {
  const { identify, track } = useAnalytics();
  const [cookies, setCookie] = useCookies([COOKIE_CONSENT_KEY]);

  useEffect(() => {
    if (!cookies[COOKIE_CONSENT_KEY]) {
      setShowCookiesConsentBanner(true);
    }
  }, [cookies]);

  const handleCookieConsent = (accept: boolean) => {
    identify({ acceptedCookies: accept });
    track('Cookie Consent Clicked', { accept });
    setCookie(COOKIE_CONSENT_KEY, accept.toString(), { path: '/' });
    setShowCookiesConsentBanner(false);
  };

  if (!showCookiesConsentBanner) {
    return null;
  }

  return (
    <Flex justifyContent="center">
      <Flex
        zIndex="banner"
        bg="white"
        borderTopRadius="4px"
        boxShadow="reverseSm"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        bottom="0"
        padding={{ base: '12px', tablet: '16px' }}
      >
        <RichText
          content={text}
          textSpaces={false}
          _p={{
            fontSize: '14px',
            lineHeight: '18px',
          }}
        />
        <Stack direction={{ base: 'column', tablet: 'row' }} spacing="8px" marginLeft="16px">
          <Button onClick={() => handleCookieConsent(true)} size="sm">
            {acceptButtonText}
          </Button>
          <Button onClick={() => handleCookieConsent(false)} size="sm" colorScheme="lightAzureAzure">
            {declineButtonText}
          </Button>
        </Stack>
      </Flex>
    </Flex>
  );
}
