import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  List,
  ListItem,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Logotype } from 'components/common/logos';
import LocaleSelect from 'components/common/LocaleSelect';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import SmartLink from 'components/common/SmartLink';
import { GridProps, Storyblok } from 'types';
import CustomGrid from 'components/common/Grid';

type NavItem = {
  text?: string;
  link?: Storyblok.Link;
  newWindow?: boolean;
  tag?: string;
};
type NavGroup = { title: string; navItems: NavItem[] };

type Social = {
  link: Storyblok.Link;
  icon: string;
};

type FooterProps = {
  copyright?: string;
  navGroups?: NavGroup[];
  subNavItems?: NavItem[];
  socials?: Social[];
  badges?: GridProps[];
};

export default function Footer({ copyright, navGroups, subNavItems, socials, badges }: FooterProps) {
  const isMobile = useBreakpointValue({ base: true, desktop: false });

  return (
    <Box as="footer" pt={{ base: '38px', desktop: '96px' }} bg="rgba(243, 245, 248, .5)">
      <Container
        size="py0"
        display="grid"
        gridTemplateColumns={{ desktop: '1fr 3fr' }}
        pb={{ base: '24px', desktop: '80px' }}
      >
        <Flex pb="24px">
          <Logotype height="21px" />
        </Flex>
        <Grid
          gridTemplateColumns={{ base: '1fr', desktop: 'repeat(3, auto) 1fr' }}
          gridColumnGap="80px"
          gridRowGap="40px"
        >
          {isMobile ? (
            <Accordion allowToggle>
              {navGroups
                .filter((navGroup) => !!navGroup.title)
                .map((navGroup, i) => (
                  <AccordionItem key={i}>
                    <AccordionButton
                      px="0"
                      py="16px"
                      fontWeight="regular"
                      fontSize="20px"
                      lineHeight="30px"
                      _hover={{ bg: 'none' }}
                    >
                      <Box flex="1" textAlign="left">
                        {navGroup.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel px="0" pb="24px">
                      <NavList navItems={navGroup.navItems} />
                    </AccordionPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          ) : (
            <>
              {navGroups
                .filter((navGroup) => !!navGroup.title)
                .map((navGroup, i) => (
                  <Flex
                    key={i}
                    display={{ base: 'none', desktop: 'flex' }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Heading as="p" fontSize="sm" lineHeight="16px" mb="16px" fontWeight="medium">
                        {navGroup.title}
                      </Heading>
                      <NavList navItems={navGroup.navItems} />
                    </Box>
                  </Flex>
                ))}
            </>
          )}
        </Grid>
      </Container>
      {badges && (
        <Container size="py0" pb={{ base: '24px', desktop: '40px' }}>
          <Grid pt={{ base: '24px', desktop: '40px' }} borderTop="1px solid" borderColor="gray.10">
            {badges.map((badge, index) => (
              <CustomGrid {...badge} key={index} />
            ))}
          </Grid>
        </Container>
      )}
      <Container size="py0">
        <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr auto' }} borderTop="1px solid" borderColor="gray.10">
          <Grid
            gridTemplateColumns={{ base: '1fr', desktop: 'auto 1fr' }}
            gridColumnGap="48px"
            gridRowGap="10px"
            pb="40px"
            pt={{ base: 0, desktop: '40px' }}
            order={{ base: 2, desktop: 1 }}
          >
            <Flex alignItems="center" justifyContent="center">
              <Text color="sapphire.100" fontWeight="medium" fontSize="sm" lineHeight="24px">
                {copyright}
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent={{ base: 'center', desktop: 'flex-start' }}>
              <nav>
                <Stack
                  as={List}
                  direction={{ base: 'column', desktop: 'row' }}
                  spacing={{ base: '10px', desktop: '48px' }}
                  textAlign="center"
                >
                  {subNavItems
                    .filter((subNavItem) => !!subNavItem.text)
                    .map((subNavItem, i) => {
                      return (
                        <ListItem key={i}>
                          <SmartLink
                            href={subNavItem.link.url}
                            target={subNavItem.newWindow ? '_blank' : undefined}
                            variant="accent"
                            fontSize="sm"
                            lineHeight="1"
                            fontWeight="regular"
                          >
                            {subNavItem.text}
                          </SmartLink>
                        </ListItem>
                      );
                    })}
                </Stack>
              </nav>
            </Flex>
          </Grid>
          <Flex
            py={{ base: '24px', desktop: '48px' }}
            alignItems={{ base: 'flex-start', desktop: 'center' }}
            order={{ base: 1, desktop: 2 }}
          >
            <HStack as={List} spacing="8px" width="100%">
              {socials.map((social, i) => (
                <ListItem key={i}>
                  <IconButton
                    aria-label={social.icon}
                    icon={<DynamicIcon icon={social.icon} />}
                    as="a"
                    href={social.link.url}
                    target="_blank"
                    colorScheme="gray"
                  />
                </ListItem>
              ))}
              <ListItem width="100%">
                <LocaleSelect />
              </ListItem>
            </HStack>
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
}

type NavListProps = { navItems: NavItem[] };

function NavList({ navItems }: NavListProps) {
  return (
    <List spacing="16px">
      {navItems.map((navItem, i) => {
        return (
          <ListItem key={i} display="flex" alignItems="center">
            <SmartLink
              href={navItem.link.url}
              target={navItem.newWindow ? '_blank' : undefined}
              variant="accent"
              fontSize="sm"
              lineHeight="1"
              fontWeight="regular"
            >
              {navItem.text}
            </SmartLink>
            {navItem.tag && (
              <Tag ml="6px" size="sm">
                {navItem.tag}
              </Tag>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
