import { Box, Grid } from "@chakra-ui/react";
import { containerHeight } from "components/layout/header/styles";
import { ReactNode, VFC } from "react";

type ThreeColumnGridProps = {
  left?: ReactNode;
  center: ReactNode;
  right?: ReactNode;
};

export const ThreeColumnGrid: VFC<ThreeColumnGridProps> = ({ left = null, center, right = null }) => {
  return (
    <Grid
      gridTemplateColumns={{
        base: '0 minmax(auto, 720px) 0',
        desktop: '0 minmax(auto, 720px) 240px',
        large: '240px minmax(auto, 720px) 240px',
      }}
      gridGap={{ desktop: '24px', large: '40px' }}
      justifyContent="center"
      alignItems="start"
      position="relative"
    >
      <Box position="sticky" top={`calc(${containerHeight.desktop}px + 24px)`}>
        <Box display={{ base: 'none', large: 'block' }}>{left}</Box>
      </Box>
      <Box>{center}</Box>
      <Box position="sticky" top={`calc(${containerHeight.desktop}px + 24px)`}>
        <Box display={{ base: 'none', desktop: 'block' }}>{right}</Box>
      </Box>
    </Grid>
  );
};