import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { VFC } from 'react';
import { Storyblok } from '../../types';

type ExternalImageProps = {
  image: Storyblok.Image;
};

const ExternalImage: VFC<ExternalImageProps> = ({ image }) => {
  const { width, height } = imageDimensions(image.filename);

  return <Image src={image.filename} alt={image.alt} width={width} height={height} />;
};

export default ExternalImage;
