import { VFC } from 'react';
import { Box } from '@chakra-ui/react';
import { containerHeight as headerHeight } from 'components/layout/header/styles';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { Storyblok } from 'types';

type AnchorImageProps = {
  id: string;
  image: Storyblok.Image;
  anchorClassName: string;
};

const AnchorImage: VFC<AnchorImageProps> = ({ id, anchorClassName, image }) => {
  return (
    <Box
      as="figure"
      id={id}
      className={anchorClassName}
      scrollMarginTop={{
        base: `calc(${headerHeight.base}px + 40px)`,
        desktop: `calc(${headerHeight.desktop}px + 40px)`,
      }}
      mb={{ base: '24px', desktop: '32px' }}
      position="relative"
    >
      <Image
        src={image.filename}
        alt={image.alt}
        height={imageDimensions(image.filename).height}
        width={imageDimensions(image.filename).width}
      />
    </Box>
  );
};

export default AnchorImage;
