import { Storyblok } from '../../types';
import { HStack, SpaceProps, Tag } from '@chakra-ui/react';
import BlogCategory = Storyblok.BlogCategory;

const MAX_TAGS_SHOWN = 3;

type BlogArticleTagsProps = {
  primaryCategory: BlogCategory;
  otherCategories?: BlogCategory[];
} & SpaceProps;

export function BlogArticleTags({ primaryCategory, otherCategories = [], ...spacing }: BlogArticleTagsProps) {
  const categories = [primaryCategory, ...otherCategories].slice(0, MAX_TAGS_SHOWN);
  return (
    <HStack alignItems="flex-start" spacing={2} {...spacing}>
      {categories.map(({ slug, name }) => (
        <Tag key={slug} size="md" colorScheme="gray" borderRadius={4} textTransform="unset" fontWeight="normal">
          {name}
        </Tag>
      ))}
    </HStack>
  );
}
