import { useState, VFC } from 'react';
import { Storyblok } from 'types';
import { Button, FormControl, Grid, useDisclosure } from '@chakra-ui/react';
import { EmailInput } from 'components/forms/Inputs';
import FormError from 'components/common/FormError';
import { useFormWithEmailErrorHandler } from 'components/forms/formHooks';
import { useRouter } from 'next/router';
import axios from 'axios';
import { ChiliPiperModal } from 'components/common/ChiliPiperModal';
import { useAnalytics } from 'lib/Analytics';

export type PartnersFormProps = {
  formConfig: Storyblok.FormConfig;
  text: {
    emailPlaceholder: string;
    submitButton: string;
  };
};

type FormInputs = {
  email: string;
};

const PartnersForm: VFC<PartnersFormProps> = ({ formConfig, text }) => {
  const { identify, track } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    formErrorHandler,
  } = useFormWithEmailErrorHandler<FormInputs>();
  const { locale } = useRouter();
  const [chiliPiperUrl, setChiliPiperUrl] = useState<string>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    setChiliPiperUrl(null);
    onClose();
  };

  const onSubmit = async (data: FormInputs) => {
    try {
      const response = await axios.post<{ hasErrors: boolean; chiliPiperUrl: string }>('/api/form/partners', {
        formData: data,
        params: {
          locale,
        },
      });
      setChiliPiperUrl(response.data.chiliPiperUrl);
      onOpen();

      identify({ email: data.email });
      track('Form Submitted', { email: data.email, form_name: 'partnersForm' });
    } catch (e) {
      formErrorHandler(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr auto' }} gridGap="12px">
          <FormControl isInvalid={!!errors['email']}>
            <EmailInput variant="outline" placeholder={text.emailPlaceholder} register={register} size="xl" />
            <FormError error={errors['email']} formConfig={formConfig} />
          </FormControl>
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            size="xl"
            width={{ base: '100%', lg: 'auto' }}
          >
            {text.submitButton}
          </Button>
        </Grid>
      </form>
      <ChiliPiperModal isOpen={isOpen} onClose={handleClose} url={chiliPiperUrl} />
    </>
  );
};

export default PartnersForm;
