import { FC } from 'react';
import { Icon, IconButton, Text, useClipboard } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Link as LinkIcon } from 'components/common/icons/assets/Link';
import { containerHeight as headerHeight } from 'components/layout/header/styles';

type AnchorHeadingProps = {
  id: string;
  anchorClassName: string;
};

const AnchorHeading: FC<AnchorHeadingProps> = ({ id, anchorClassName, children }) => {
  const { asPath } = useRouter();
  const { onCopy } = useClipboard(`${process.env.NEXT_PUBLIC_URL}${asPath}#${id}`);

  return (
    <Text
      className={anchorClassName}
      id={id}
      variant="unstyled"
      position="relative"
      scrollMarginTop={{
        base: `calc(${headerHeight.base}px + 24px)`,
        desktop: `calc(${headerHeight.desktop}px + 24px)`,
      }}
      as="h2"
      sx={{
        svg: {
          visibility: 'hidden',
        },
        _hover: {
          svg: {
            visibility: 'visible',
          },
        },
      }}
    >
      <IconButton
        onClick={onCopy}
        aria-label={`${children} permalink`}
        icon={<Icon as={LinkIcon} />}
        variant="link"
        minWidth="none"
        ml="-24px"
        pr="8px"
        py="14px"
        float="left"
      />
      {children}
    </Text>
  );
};

export default AnchorHeading;
