import { VFC } from 'react';
import { Input, ThemingProps } from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

// Regex coming from this StackOverflow answer https://stackoverflow.com/a/46181
const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type EmailInputProps = {
  variant: ThemingProps<'Input'>['variant'];
  placeholder: string;
  register: UseFormRegister<{ email: string }>;
  size?: ThemingProps<'Input'>['size'];
};

export const EmailInput: VFC<EmailInputProps> = ({ variant, placeholder, register, size }) => {
  return (
    <Input
      type="email"
      {...register('email', {
        required: true,
        pattern: EMAIL_PATTERN,
      })}
      placeholder={placeholder}
      variant={variant}
      size={size}
    />
  );
};
