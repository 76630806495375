import { Icon } from '@chakra-ui/react';

export const Clock = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7178 7.98535V12.6364L15.3738 14.8654"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
