import { VFC } from 'react';
import { Storyblok } from 'types';
import { Box, Flex, Text, theme } from '@chakra-ui/react';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import SmartButtonLink from 'components/common/SmartButtonLink';
import { ArrowRightAnimated } from 'components/common/icons/assets/ArrowRightAnimated';

type CustomerTestimonialProps = {
  companyLogo: Storyblok.Image;
  quote: string;
  avatar: Storyblok.Image;
  customerName: string;
  customerJobTitle: string;
  storyLink: {
    cached_url: string;
    fieldtype: string;
    id: string;
    linktype: string;
    url: string;
  };
};

const CustomerTestimonial: VFC<CustomerTestimonialProps> = ({
  companyLogo,
  quote,
  avatar,
  customerName,
  customerJobTitle,
  storyLink,
}) => {
  const originalWidth = imageDimensions(companyLogo.filename).width;
  const originalHeight = imageDimensions(companyLogo.filename).height;
  const aspectRatio = originalWidth / originalHeight;
  const targetHeight = 16;
  const targetWidth = aspectRatio * targetHeight;

  return (
    <>
      <Box
        backgroundColor={theme.colors.white}
        border="1px solid #0000001A"
        borderRadius="lg"
        p="24px"
        width="100%"
        height="min-content"
        display="flex"
        flexDirection="column"
        justifyContent="left"
        gap="16px"
      >
        <Box height={`${targetHeight}px`} width="auto" position="relative">
          <Image
            src={companyLogo.filename}
            width={targetWidth}
            height={targetHeight}
            objectFit="contain"
            layout="intrinsic"
            alt={companyLogo.alt}
          />
        </Box>
        <Text color={theme.colors.current} lineHeight="28px">
          {quote}
        </Text>
        <Flex gap="12px">
          <Box width="40px" height="40px" borderRadius="4px" overflow="hidden">
            <Image
              src={avatar.filename}
              width={imageDimensions(avatar.filename).width}
              height={imageDimensions(avatar.filename).height}
              objectFit="cover"
              alt={avatar.alt}
              style={{ borderRadius: '4px' }}
            />
          </Box>
          <Box>
            <Text fontSize="16px" fontWeight={700} lineHeight="20px">
              {customerName}
            </Text>
            <Text fontSize="14px" fontWeight={400} lineHeight="16px">
              {customerJobTitle}
            </Text>
          </Box>
        </Flex>
      </Box>
      <SmartButtonLink
        rightIcon={<ArrowRightAnimated />}
        alignSelf="start"
        href={storyLink.cached_url}
        variant="link"
        color="#267FFD"
        mt="25px"
      >
        Read Story
      </SmartButtonLink>
    </>
  );
};

export default CustomerTestimonial;
