export function getCookie(key: string) {
  const fullCookie = decodeURIComponent(document.cookie)
    .split(';')
    .find(function (item) {
      return item.trim().startsWith(key + '=');
    });
  if (fullCookie) {
    return fullCookie.split('=')[1];
  }
  return undefined;
}

/**
 *
 * @param key The key of the cookie
 * @param value The value to set
 * @param expirationDelay The delay of expiration in milliseconds (default to 24 hours)
 */
export function setCookie(key: string, value: string, expirationDelay: number = 60 * 60 * 24 * 1000) {
  const expirationDate = new Date(new Date().getTime() + expirationDelay);
  const expires = 'expires=' + expirationDate.toUTCString();
  document.cookie = key + '=' + value + ';' + expires + ';path=/';
}
