import { Container, Heading, Text, Flex, Box, Grid, Button, useClipboard } from '@chakra-ui/react';
import { imageDimensions } from 'utils/storyblok';
import Image from 'next/image';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { useTranslation } from 'lib/hooks/useTranslation';

const BrandAssetsSection = ({ groups, size }) => {
  return (
    <Container as="section" size={size}>
      <Grid gridGap="88px">
        {groups.map((group, i) => (
          <Box key={i}>
            {group.title && (
              <Heading as="h2" size="lg" mb="16px">
                {group.title}
              </Heading>
            )}
            {group.description && <Text mb="32px">{group.description}</Text>}
            <Grid gridTemplateColumns={{ base: '1fr', lg: group.gridTemplateColumns }} gridGap={group.gridGap}>
              {group.cards.map((card, i) => {
                if (card.component === 'brandAssetsColorCard') {
                  return <ColorCard key={i} {...card} />;
                }
                if (card.component === 'brandAssetsMediaCard') {
                  return <MediaCard key={i} {...card} />;
                }
                return null;
              })}
            </Grid>
          </Box>
        ))}
      </Grid>
    </Container>
  );
};
export default BrandAssetsSection;

function ColorCard({ name, code, layerStyle }) {
  const { hasCopied, onCopy } = useClipboard(code);
  const { t } = useTranslation();

  return (
    <Grid layerStyle={layerStyle} borderRadius="4px" gridTemplateColumns="1fr auto" p="40px">
      <Box>
        <Heading size="md" mb="12px">
          {name}
        </Heading>
        <Text variant="unstyled">{code}</Text>
      </Box>
      <Flex alignItems="center">
        <Button onClick={onCopy} size="sm" colorScheme={layerStyle.includes('white') ? 'gray' : 'whiteAlpha'}>
          {hasCopied ? t['copied'] : t['copy']}
        </Button>
      </Flex>
    </Grid>
  );
}

function MediaCard({ title, image, buttons }) {
  return (
    <Box>
      <Flex as="figure" alignItems="center" borderRadius="4px" overflow="hidden" mb="24px" boxShadow="xl">
        <Image
          src={image.filename}
          alt={image.alt}
          height={imageDimensions(image.filename).height}
          width={imageDimensions(image.filename).width}
        />
      </Flex>
      <Heading as="h2" size="lg" mb="16px">
        {title}
      </Heading>
      <ButtonGroup buttons={buttons} width="auto" alignItems="flex-start" />
    </Box>
  );
}
