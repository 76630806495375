import { Container, Flex, Box, Heading } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import { Logotype } from 'components/common/logos';

export default function Error404Section({ title, description }) {
  return (
    <Container
      as="section"
      size="ptHeader"
      minHeight={{ base: 'calc(60vh - 93px)', desktop: 'calc(100vh - 56px - 290px)' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxWidth="480px" textAlign="center">
        <Heading as="h1" size="lg" mb="16px">
          {title}
        </Heading>
        <RichText content={description} size="sm" />
        <Logotype height="16px" />
      </Box>
    </Container>
  );
}
