import { format, Locale as DateFnsLocale, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { Locale } from 'types';

const locales: Record<Locale, DateFnsLocale> = {
  en: enUS,
  fr: fr,
};

export function formatDate(date: string, formatString = 'PP', locale: Locale): string {
  return format(parseISO(date), formatString, { locale: locales[locale] });
}
