import { AspectRatio, Box, Container, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import ExternalImage from 'components/common/ExternalImage';
import { VFC } from 'react';
import { Storyblok } from 'types';

/* Types */

type Feature = {
  title: string,
  text: string,
  image: Storyblok.Image,
  buttons: Storyblok.Button[],
}

type FrmFeaturesProps = {
  title: string;
  subtitle: string;
  features: Feature[];
};

/* Widgets */

function Bubble(props: {
  color: string,
  size: any,
  style: React.CSSProperties,
}) {
  const {
    color,
    size,
    style,
  } = props;
  return (
    <AspectRatio
      width={size}
      ratio={1}
      position="absolute"
      style={style}
    >
      <Box
        transform="translateZ(0)"
        bgColor={color}
        filter="blur(50px)"
        rounded="full"
      />
    </AspectRatio>
  )
}

const FrmPerformances: VFC<FrmFeaturesProps> = ({
  title,
  subtitle,
  features,
}) => {
  return (
    <Box
      bgColor="sapphire.100"
      width="100%"
    >
      <Container
        as="section"
      >
        <Box
          position="relative"
          paddingY={{ base: "8", desktop: "16" }}
        >

          <Bubble
            color="#EF4313"
            size={{ base: "48", desktop: "350px" }}
            style={{
              left: "50%",
              top: "50%",
              transform: "translateX(-50%) translateY(-50%)"
            }}
          />
          <Bubble
            color="#7560cb"
            size={{ base: "32", desktop: "48" }}
            style={{
              right: "57%",
              top: "50%",
              transform: "translateY(-50%)"
            }}
          />
          <Bubble
            color="#7560cb"
            size={{ base: "32", desktop: "48" }}
            style={{
              left: "57%",
              top: "50%",
              transform: "translateY(-50%)"
            }}
          />
          <Heading
            as="h1"
            size="lg"
            mb="24px"
            fontWeight="bold"
            textAlign="center"
            textColor="white"
            position="relative"
          >
            {title}
          </Heading>
          <Text
            as="h2"
            size="lg"
            mb="64px"
            textAlign="center"
            textColor="white.70"
            position="relative"
          >
            {subtitle}
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="32px"
          position="relative"
        >
          <Box gap="16" display="flex" flexDirection="column">
            {
              features.map((feature, i) => (
                <Box
                  display="flex"
                  flexDirection={{ base: "column", desktop: i % 2 ? "row-reverse" : "row" }}
                  backgroundColor="white"
                  rounded="2xl"
                  padding={{ base: "32px", desktop: "80px" }}
                  gap="8"
                  key={feature.title}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="8"
                    width={{ base: "auto", desktop: "50%" }}
                  >
                    <Heading
                      as="h1"
                      size="md"
                      fontWeight="bold"
                      textAlign="left"
                      textColor="sapphire.100"
                    >
                      {feature.title}
                    </Heading>
                    <Text
                      as="h2"
                      fontSize={{ base: "14px", desktop: "21px" }}
                      textAlign="left"
                      textColor="sapphire.70"
                    >
                      {feature.text}
                    </Text>
                    <Box display={{ base: "none", desktop: "block" }} flex="1" />
                    <ButtonGroup buttons={feature.buttons} />
                  </Box>
                  <Box
                    height="full"
                    width={{ base: "auto", desktop: "50%" }}
                  >
                    <ExternalImage image={feature.image} />
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FrmPerformances;
