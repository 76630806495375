import { Icon } from '@chakra-ui/react';

export const ArrowRightAnimated = (props) => (
  <Icon
    viewBox="0 0 15 10"
    fill="none"
    {...props}
    className="animated-icon-translateX"
    transition="transform 180ms ease-out 0s"
  >
    <path
      d="M1.48 4.98315H14.469"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.483 0.988037L14.52 5.00004L10.483 9.01204"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
