import { useRef, useState } from 'react';
import { Container, Box, Flex, Grid, Heading, Text, HStack, IconButton } from '@chakra-ui/react';
import { PietileCarousel } from 'pietile-carousel';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';

export default function CarouselOneSection({ size, cards }) {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = useRef(null);

  const options = {
    count: 1,
    margin: 20,
    draggable: true,
    autoplayInterval: 5000,
    onChange: (i: number) => setCarouselIndex(i),
  };

  return (
    <Container as="section" size={size}>
      <PietileCarousel ref={carouselRef} {...options}>
        {cards.map((card, i) => (
          <Card key={i} {...card} />
        ))}
      </PietileCarousel>
      <HStack justifyContent="center" mt="12">
        {[...Array(cards.length).keys()].map((_, i) => (
          <IconButton
            key={i}
            aria-label={`Show slide ${i}`}
            variant="link"
            height="14px"
            width="14px"
            borderRadius="full"
            minWidth="unset"
            onClick={() => carouselRef.current?.slideTo(i)}
            bg={carouselIndex === i ? 'rgba(11,37,75,0.7)' : 'rgba(11,37,75,0.3)'}
          />
        ))}
      </HStack>
    </Container>
  );
}

function Card({ image, title, description, authorName, authorRole }) {
  return (
    <Grid gridTemplateColumns={{ base: '1fr', desktop: '368px 1fr' }} gridColumnGap="80px" gridRowGap="40px">
      <Flex justifyContent="center">
        <Flex borderRadius="16px" alignItems="center" overflow="hidden">
          <Image
            src={image.filename}
            alt={image.alt}
            height={imageDimensions(image.filename).height}
            width={imageDimensions(image.filename).width}
          />
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Box textAlign={{ base: 'center', desktop: 'left' }}>
          <Heading
            color="azure.100"
            fontSize={{ base: '16px', desktop: '20px' }}
            lineHeight={{ base: '24px', desktop: '30px' }}
            fontWeight="500"
            mb="16px"
          >
            {title}
          </Heading>
          <Heading size="lg">{description}</Heading>
          <Box mt="24px">
            <Text
              fontSize={{ desktop: '20px' }}
              lineHeight={{ desktop: '30px' }}
              fontWeight="600"
              mb="4px"
              color="sapphire.100"
            >
              {authorName}
            </Text>
            <Text fontSize={{ desktop: '16px' }} lineHeight={{ desktop: '24px' }}>
              {authorRole}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Grid>
  );
}
