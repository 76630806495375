export const colors = {
  green: {
    lighter: '#F2FDF8',
    light: '#00CD6914',
    primary: '#00CD69',
  },
  purple: {
    primary: '#7F30FF',
    light: '#7F30FF14',
  },
  orange: {
    lighter: '#fff9f4',
    light: '#FF832B14',
    primary: '#FF832B',
  },
  blue: {
    lighter: '#f4f9ff',
    light: '#267FFD14',
    primary: '#267FFD',
  },
  white: {
    primary: '#ffffff',
  },
  pink: {
    primary: '#FF4880',
    light: '#ff488014',
  },
};
