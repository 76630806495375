import { Box, Container, Flex, Grid, Heading, HStack, Icon, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { SemiStar } from 'components/common/icons/assets/SemiStar';
import { Star } from 'components/common/icons/assets/Star';

export default function ReviewsSection({ size, title, description, items }) {
  return (
    <Container as="section" size={size}>
      {title && (
        <Box textAlign="center">
          <Heading as="h2" size="lg" mb="16px" maxWidth="1024px" mx="auto">
            {title}
          </Heading>
          {description && (
            <Text mb={{ base: '40px', desktop: '80px' }} maxWidth="560px" mx="auto">
              {description}
            </Text>
          )}
        </Box>
      )}
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridGap="16px">
        {items.map((item, i) => {
          if (item.component === 'reviewItemGroup') {
            return (
              <Grid key={i} gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridGap="16px">
                {item.items.map((item, i) => (
                  <ReviewItem key={i} ratingSize="sm" {...item} />
                ))}
              </Grid>
            );
          }
          return <ReviewItem key={i} ratingSize="md" {...item} />;
        })}
      </Grid>
    </Container>
  );
}

function ReviewItem({ backgroundColor, rating, ratingSize, logo, reward }) {
  return (
    <Flex
      bg={backgroundColor}
      borderRadius="4px"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="48px"
    >
      <Image
        src={logo.filename}
        alt={logo.alt}
        height={imageDimensions(logo.filename).height / 2}
        width={imageDimensions(logo.filename).width / 2}
      />
      <HStack spacing="5px" mt="26px" mb="18px">
        {[...Array(5)].map((_, i) => {
          if (i + 1 === Math.ceil(rating) && i + 1 > rating) {
            return <Icon key={i} as={SemiStar} color="#F7CE46" fontSize="32px" />;
          }
          if (i + 1 <= rating) {
            return <Icon key={i} as={Star} color="#F7CE46" fontSize="32px" />;
          }
          return <Icon key={i} as={Star} color="rgba(247, 206, 70, .5)" fontSize="32px" />;
        })}
      </HStack>
      <Text size={ratingSize} fontWeight="semibold" color="sapphire.100">{`${rating}/5`}</Text>
      {reward.filename && (
        <Box as="figure" mt="24px">
          <Image
            src={reward.filename}
            alt={reward.alt}
            height={imageDimensions(reward.filename).height / 2}
            width={imageDimensions(reward.filename).width / 2}
          />
        </Box>
      )}
    </Flex>
  );
}
