import { Flex, SpaceProps } from '@chakra-ui/react';
import { FC } from 'react';

export type IconBackgroundProps = {
  colorScheme: keyof typeof colorSchemeStyles;
  size: number;
};

const IconBackground: FC<IconBackgroundProps & SpaceProps> = ({ children, colorScheme, size, ...props }) => {
  return (
    <Flex
      {...props}
      h={`${size}px`}
      w={`${size}px`}
      borderRadius={`${Math.ceil(size / 2)}px`}
      {...colorSchemeStyles[colorScheme]}
      alignItems="center"
      justifyContent="center"
      shrink={0}
    >
      {children}
    </Flex>
  );
};

export default IconBackground;

const colorSchemeStyles = {
  blue: {
    color: 'azure.100',
    bg: 'azure.8',
    _groupHover: {
      color: 'white',
      bg: 'azure.100',
    },
  },
  orange: {
    color: 'orange.100',
    bg: 'orange.8',
    _groupHover: {
      color: 'white',
      bg: 'orange.100',
    },
  },
  green: {
    color: 'green.100',
    bg: 'green.12',
    _groupHover: {
      color: 'white',
      bg: 'green.100',
    },
  },
};
