import GridBlock from './Grid';
import { VFC } from 'react';
import { Box } from '@chakra-ui/react';
import { containerHeight as headerHeight } from 'components/layout/header/styles';
import { RichTextProps } from 'components/common/RichText/index';
import { Storyblok } from 'types';

type AnchorGridProps = {
  id: string;
  anchorClassName: string;
  columns: { content: Storyblok.RichText }[];
  richTextProps: Omit<RichTextProps, 'content'>;
};

const AnchorGrid: VFC<AnchorGridProps> = ({ id, anchorClassName, columns, richTextProps }) => {
  return (
    <Box
      id={id}
      className={anchorClassName}
      scrollMarginTop={{
        base: `calc(${headerHeight.base}px + 40px)`,
        desktop: `calc(${headerHeight.desktop}px + 40px)`,
      }}
    >
      <GridBlock columns={columns} richTextProps={richTextProps} />
    </Box>
  );
};

export default AnchorGrid;
