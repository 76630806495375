import { VFC } from 'react';
import { Storyblok } from 'types';
import { Box, Container, Flex, Heading, HStack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { ButtonGroup } from 'components/common/ButtonGroup';
import DividerSection from './Divider';

type CustomerStoryHeroProps = {
  surtitle: string;
  viewMoreStories: Storyblok.Button[];
  title: string;
  date: Date;
  timeToRead: string;
  videoIframeHero: {
    src: string;
    width: string;
    height: string;
  };
  description: string;
  companyLogo: Storyblok.Image;
  industry: string;
  FTEs: string;
  offices: string;
  customerImage: Storyblok.Image;
  customerName: string;
  customerRole: string;
};

const CustomerStoryHero: VFC<CustomerStoryHeroProps> = ({
  surtitle,
  viewMoreStories,
  title,
  date,
  timeToRead,
  videoIframeHero,
  description,
  companyLogo,
  industry,
  FTEs,
  offices,
  customerImage,
  customerName,
  customerRole,
}) => {
  const isMobile = useBreakpointValue({ base: true, desktop: false });

  viewMoreStories[0].leftIcon = 'AmpStories';
  return (
    <Container as="section" mt="40px" pb="0px !important">
      <HStack>
        <Text
          fontWeight={400}
          fontSize="16px"
          lineHeight="20px"
          color="#546681"
          mr="24px"
          display={{ base: 'none', desktop: 'block' }}
        >
          {surtitle}
        </Text>
        <ButtonGroup fontWeight={400} fontSize="16px" lineHeight="20px" buttons={viewMoreStories} />
      </HStack>
      <Flex alignItems="top" direction={{ base: 'column', desktop: 'row' }}>
        <Hero
          title={title}
          date={date}
          timeToRead={timeToRead}
          iframeVideo={videoIframeHero}
          description={description}
        />
        {isMobile && (
          <Box py="32px">
            <DividerSection color="#EEF0F3" />
          </Box>
        )}
        <CompanyInfo
          companyLogo={companyLogo}
          industry={industry}
          FTEs={FTEs}
          offices={offices}
          customerImage={customerImage}
          customerName={customerName}
          customerRole={customerRole}
        />
      </Flex>
    </Container>
  );
};

type HeroProps = {
  title: string;
  date: Date;
  timeToRead: string;
  iframeVideo: {
    src: string;
    width: string;
    height: string;
  };
  description: string;
};

const Hero: VFC<HeroProps> = ({ title, date, timeToRead, iframeVideo, description }) => {
  const dateString = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <VStack alignItems="left" pt="24px">
      <Heading
        fontWeight={700}
        fontSize={{ base: '24px', desktop: '40px' }}
        lineHeight={{ base: '32px', desktop: '52px' }}
      >
        {title}
      </Heading>
      <HStack pt="16px" pb="32px">
        <Text fontWeight={400} fontSize="15px" lineHeight="20px">
          {dateString}
        </Text>
        <Text fontWeight={400} fontSize="15px" lineHeight="20px">
          {timeToRead}
        </Text>
      </HStack>
      <Box
        width="100%"
        height={{ base: '192px', desktop: '450px' }}
        position="relative"
        overflow="hidden"
        borderRadius="16px"
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/ugPxoGo8JE8"
          title="Payments: The last frontier in B2B customer experience (Full Event Replay)"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Box>
      <Text
        fontWeight={400}
        fontSize={{ base: '17px', desktop: '23px' }}
        lineHeight={{ base: '24px', desktop: '32px' }}
        pt="30px"
        color="#0B254B"
      >
        {description}
      </Text>
    </VStack>
  );
};

type CompanyInfoProps = {
  companyLogo: Storyblok.Image;
  industry: string;
  FTEs: string;
  offices: string;
  customerImage: Storyblok.Image;
  customerName: string;
  customerRole: string;
};

const CompanyInfo: VFC<CompanyInfoProps> = ({
  companyLogo,
  industry,
  FTEs,
  offices,
  customerImage,
  customerName,
  customerRole,
}) => {
  const isMobile = useBreakpointValue({ base: true, desktop: false });
  const imageHeight = isMobile ? '44px' : '52px';
  const imageHeightNumber = parseInt(imageHeight, 10);
  return (
    <VStack pl={{ base: '0', desktop: '72px' }} minWidth="328px" alignItems="left">
      <Flex>
        <Image
          src={companyLogo.filename}
          alt={companyLogo.alt}
          height={imageHeight}
          width={Math.round(
            (imageHeightNumber * imageDimensions(companyLogo.filename).width) /
              imageDimensions(companyLogo.filename).height,
          )}
          layout="intrinsic"
          objectFit="contain"
        />
      </Flex>

      <Text fontWeight={400} fontSize="13px" lineHeight="16px" color="#8592A5" pt="24px">
        AT A GLANCE
      </Text>
      <Box pt="24px">
        <Text fontWeight={500} fontSize="15px" lineHeight="20px" color="#0B254B">
          Industry
        </Text>
        <Text fontWeight={400} fontSize="15px" lineHeight="20px" color="#0B254BB2" pt="8px">
          {industry}
        </Text>
      </Box>
      <Box pt="24px">
        <Text fontWeight={500} fontSize="15px" lineHeight="20px" color="#0B254B">
          FTEs
        </Text>
        <Text fontWeight={400} fontSize="15px" lineHeight="20px" color="#0B254BB2" pt="8px">
          {FTEs}
        </Text>
      </Box>
      <Box pt="24px">
        <Text fontWeight={500} fontSize="15px" lineHeight="20px" color="#0B254B">
          Offices
        </Text>
        <Text fontWeight={400} fontSize="15px" lineHeight="20px" color="#0B254BB2" pt="8px">
          {offices}
        </Text>
      </Box>
      <Flex pt="24px" alignItems="center" direction={{ desktop: 'column' }}>
        <Box width={{ base: '100px', desktop: '100%' }} borderRadius="16px">
          <Image
            src={customerImage.filename}
            alt={customerImage.alt}
            width={imageDimensions(customerImage.filename).width}
            height={imageDimensions(customerImage.filename).height}
            style={{ borderRadius: '16px' }}
          />
        </Box>
        {isMobile ? (
          <VStack alignItems="flex-start" pl="16px">
            <Text fontWeight={500} fontSize="15px" lineHeight="20px" color="#0B254B">
              {customerName}
            </Text>
            <Text fontWeight={400} fontSize="15px" lineHeight="20px" mt="4px !important">
              {customerRole}
            </Text>
          </VStack>
        ) : (
          <Text fontWeight={500} fontSize="15px" lineHeight="20px" color="#0B254B">
            {customerName},{' '}
            <Text as="span" fontWeight={400} fontSize="15px" lineHeight="20px">
              {customerRole}
            </Text>
          </Text>
        )}
      </Flex>
    </VStack>
  );
};

export default CustomerStoryHero;
