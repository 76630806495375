import { VFC } from 'react';
import { Box, Container, Heading, LinkBox, LinkOverlay, SimpleGrid, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { Locale, SectionProps, Storyblok } from 'types';
import { useTranslation } from 'lib/hooks/useTranslation';
import NextLink from 'next/link';
import Author from 'components/common/author';
import { normalizeUrl } from 'utils/storyblok';

type ArticlesProps = SectionProps<{
  articles: {
    slug: string;
    content: {
      title: string;
      image: Storyblok.Image;
      description: string;
      date: string;
      author: {
        content: Storyblok.Author;
      };
    };
  }[];
}>;

const Articles: VFC<ArticlesProps> = ({ articles, size }) => {
  const { locale } = useTranslation();

  return (
    <Container as="section" size={size}>
      <SimpleGrid columns={{ base: 1, tablet: 2, desktop: 3 }} spacingX="40px" spacingY="72px">
        {articles.map(({ content, slug }, index) => {
          return (
            <Box key={index}>
              <LinkBox as="article" role="group">
                <Box as="figure" width="100%" pt="51.3%" borderRadius="4px" overflow="hidden" position="relative">
                  <Image
                    src={normalizeUrl(content.image.filename)}
                    alt={content.image.alt}
                    layout="fill"
                    objectFit="cover"
                    priority={index < 3}
                    sizes="619px"
                  />
                </Box>
                <Heading
                  as="h2"
                  fontSize="24px"
                  lineHeight="34px"
                  mt="32px"
                  _groupHover={{ color: 'sapphire.70' }}
                  transition="color 180ms ease-out"
                >
                  <NextLink href={`/blog/${slug}`} passHref>
                    <LinkOverlay>{content.title}</LinkOverlay>
                  </NextLink>
                </Heading>
                <Text
                  mt="16px"
                  noOfLines={4}
                  fontSize={{ base: 'md', tablet: 'xl' }}
                  lineHeight="30px"
                  fontFamily="reading"
                >
                  {content.description}
                </Text>
              </LinkBox>
              <Author mt="32px" author={content.author.content} date={content.date} locale={locale as Locale} />
            </Box>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};

export default Articles;
