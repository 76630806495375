import { Icon } from '@chakra-ui/react';

export const Home = (props) => (
  <Icon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99537 2.88067C7.42917 2.37652 6.57509 2.37652 6.00889 2.88067L3.68335 4.95137V10.9013C3.68335 11.2305 3.95026 11.4975 4.2795 11.4975H4.90576V9.55511C4.90576 8.39744 5.84424 7.45896 7.00191 7.45896C8.15957 7.45896 9.09805 8.39744 9.09805 9.55511V11.4975H9.66406C9.99331 11.4975 10.2602 11.2305 10.2602 10.9013V4.89734L7.99537 2.88067ZM7.59805 11.4975V9.55511C7.59805 9.22586 7.33115 8.95896 7.00191 8.95896C6.67266 8.95896 6.40576 9.22586 6.40576 9.55511V11.4975H7.59805ZM2.04148 6.41331L2.18335 6.28699V10.9013C2.18335 12.059 3.12183 12.9975 4.2795 12.9975H9.66406C10.8217 12.9975 11.7602 12.059 11.7602 10.9013V6.23298L11.9627 6.41331C12.2721 6.68877 12.7462 6.66129 13.0216 6.35194C13.2971 6.04258 13.2696 5.56851 12.9603 5.29305L8.99288 1.76041C7.85805 0.749941 6.14622 0.749936 5.01139 1.7604L1.04398 5.29305C0.734629 5.5685 0.707148 6.04258 0.9826 6.35193C1.25805 6.66129 1.73213 6.68877 2.04148 6.41331Z"
      fill="currentColor"
    />
  </Icon>
);
