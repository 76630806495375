import { VFC } from 'react';
import { Container, Box, Heading, Text, Grid } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';
import Video from 'components/common/Video';

type VideoOneSectionProps = SectionProps<{
  title: string;
  description: string;
  layout: 'default' | 'card';
  cardBackground: string;
  videos: {
    youtubeId: string;
    thumbnail: Storyblok.Image;
    title: string;
    description: string;
  }[];
}>;

const VideoOneSection: VFC<VideoOneSectionProps> = ({ size, cardBackground, layout, title, description, videos }) => {
  return (
    <Container as="section" size={size}>
      <Box
        bg={layout === 'card' && cardBackground}
        p={layout === 'card' && { base: '40px', desktop: '80px' }}
        borderRadius="4px"
      >
        <Box textAlign="center" mb="68px" maxWidth="xl" mx="auto">
          <Heading as="h2" size="xl" mb="16px">
            {title}
          </Heading>
          <Text>{description}</Text>
        </Box>
        <Grid
          gridTemplateColumns={{ base: '1fr', desktop: `repeat(${videos.length}, 1fr)` }}
          gridGap={{ base: '40px', desktop: '80px' }}
        >
          {videos.map((video, i) => (
            <Box key={i}>
              <Video {...video} />
              {video.title && (
                <Heading as="h3" size="md" mb="4px" mt="24px">
                  {video.title}
                </Heading>
              )}
              {video.description && <Text>{video.description}</Text>}
            </Box>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default VideoOneSection;
