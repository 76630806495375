import { VFC } from 'react';
import { SectionProps, Storyblok } from 'types';
import { Box, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';

type GridFourProps = SectionProps<{
  items: GridFourItemProps[];
  columns?: number;
}>;

const GridFour: VFC<GridFourProps> = ({ items, size, columns = 2 }) => {
  return (
    <Container as="section" size={size}>
      <Grid
        gridTemplateColumns={{ base: '1fr', desktop: `repeat(${columns}, 1fr)` }}
        gridColumnGap="184px"
        gridRowGap="96px"
      >
        {items.map((item, i) => (
          <GridFourItem key={i} {...item} />
        ))}
      </Grid>
    </Container>
  );
};

export default GridFour;

type GridFourItemProps = {
  title: string;
  description: string;
  images?: Storyblok.Image[];
};

const GridFourItem: VFC<GridFourItemProps> = ({ title, description, images }) => {
  return (
    <Box>
      <Heading as="p" size="lg">
        {title}
      </Heading>
      <Text mt="16px">{description}</Text>
      {images?.length > 0 && (
        <Flex gap="16px" mt="16px">
          {images.map((image, i) => {
            if (image.filename.endsWith('.svg')) {
              return (
                <svg key={i} width="48" height="48">
                  <image xlinkHref={image.filename} width="48" height="48" />
                </svg>
              );
            }
            return <Image key={i} src={image.filename} alt={image.alt} height="48px" width="48px" />;
          })}
        </Flex>
      )}
    </Box>
  );
};
