import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  IconButton,
  LinkBox,
  LinkOverlay,
  Tag,
  Text
} from '@chakra-ui/react';
import { HamburgerIcon } from 'components/common/hamburger';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import SmartLinkOverlay from 'components/common/SmartLinkOverlay';
import NextLink from 'next/link';
import { useRef } from 'react';
import { descriptionStyle, layerStyles, titleStyle } from './styles';

export default function MobileMenu({
  topSpace,
  yDistance,
  setYMobileMenuDistance,
  mobileMenuIsOpen,
  setMobileMenuIsOpen,
  navItems,
  footerItems,
  color,
}) {
  const btnRef = useRef();
  const intersectionRef = useRef<HTMLDivElement>();

  const onScroll = () => {
    if (intersectionRef.current) {
      const { scrollTop } = intersectionRef.current;
      setYMobileMenuDistance(scrollTop);
    }
  };

  const toggleMenu = () => {
    if (yDistance < topSpace.base) {
      window.scrollTo({ top: topSpace.base });
    }
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  return (
    <>
      <IconButton
        ref={btnRef}
        onClick={toggleMenu}
        aria-label="Menu"
        variant="link"
        icon={<HamburgerIcon isOpen={mobileMenuIsOpen} color={color} />}
      />
      <Drawer
        isOpen={mobileMenuIsOpen}
        placement="top"
        size="full"
        onClose={() => setMobileMenuIsOpen(false)}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent pt="70px">
          <Box ref={intersectionRef} onScroll={onScroll} overflow="auto" height="100%" px="40px" pb="168px">
            <nav>
              {navItems.map((navItem, i) => {
                if (navItem.component === 'menu') {
                  return (
                    <Accordion key={i} allowToggle>
                      <AccordionItem borderTop="none" borderColor="sapphire.10">
                        <AccordionButton
                          px="0"
                          py="16px"
                          fontSize="20px"
                          color="sapphire.100"
                          _hover={{ bg: 'transparent' }}
                        >
                          <Box flex="1" textAlign="left">
                            {navItem.text}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel p="0">
                          {navItem.menuGroups.map((menuGroup, i) => (
                            <Box key={i} mb="16px">
                              {menuGroup.title && (
                                <Text variant="uppercase" opacity=".5" size="xs" mb="16px">
                                  {menuGroup.title}
                                </Text>
                              )}
                              {menuGroup.menuItems.map((menuItem, i) => {
                                if (!menuItem.link.url) {
                                  return null;
                                }
                                return (
                                  <LinkBox
                                    key={i}
                                    display="grid"
                                    gridGap="16px"
                                    gridTemplateColumns="auto 1fr"
                                    py="12px"
                                    borderRadius="4px"
                                    onClick={() => setMobileMenuIsOpen(false)}
                                    {...layerStyles[menuItem.layerStyle]?.container}
                                  >
                                    <Box>
                                      {menuItem.leftIcon && (
                                        <Flex
                                          width="40px"
                                          height="40px"
                                          borderRadius="full"
                                          alignItems="center"
                                          justifyContent="center"
                                          {...layerStyles[menuItem.layerStyle]?.iconContainer}
                                        >
                                          <DynamicIcon
                                            icon={menuItem.leftIcon}
                                            fontSize="24px"
                                            {...layerStyles[menuItem.layerStyle]?.icon}
                                          />
                                        </Flex>
                                      )}
                                    </Box>
                                    <Grid gridGap="6px">
                                      <Flex {...titleStyle} alignItems="center">
                                        <SmartLinkOverlay href={menuItem.link.url}>{menuItem.title}</SmartLinkOverlay>
                                        {menuItem.tag && (
                                          <Tag size="sm" ml="2" {...layerStyles[menuItem.layerStyle]?.tag}>
                                            {menuItem.tag}
                                          </Tag>
                                        )}
                                      </Flex>
                                      <Box {...descriptionStyle}>{menuItem.description}</Box>
                                    </Grid>
                                  </LinkBox>
                                );
                              })}
                            </Box>
                          ))}
                          {navItem.menuFooterItems.map((menuItem, i) => (
                            <LinkBox
                              key={i}
                              display="grid"
                              gridGap="16px"
                              gridTemplateColumns="auto 1fr"
                              py="12px"
                              borderRadius="4px"
                              mt="-12px"
                              {...layerStyles[menuItem.layerStyle]?.container}
                            >
                              <Box>
                                {menuItem.icon && (
                                  <Flex
                                    width="40px"
                                    height="40px"
                                    borderRadius="full"
                                    alignItems="center"
                                    justifyContent="center"
                                    bg="azure.100"
                                  >
                                    <DynamicIcon icon={menuItem.icon} fontSize="24px" color="white" />
                                  </Flex>
                                )}
                              </Box>
                              <Grid gridGap="6px">
                                <Box {...titleStyle}>
                                  <NextLink href={menuItem.link.url} passHref>
                                    <LinkOverlay>{menuItem.title}</LinkOverlay>
                                  </NextLink>
                                </Box>
                                <Box {...descriptionStyle}>{menuItem.description}</Box>
                              </Grid>
                            </LinkBox>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  );
                }
                if (navItem.component === 'button') {
                  return (
                    <NextLink key={i} href={navItem.link.url} passHref>
                      <Button
                        as="a"
                        onClick={() => setMobileMenuIsOpen(false)}
                        variant="link"
                        fontSize="20px"
                        colorScheme="sapphire"
                        width="100%"
                        justifyContent="flex-start"
                        fontWeight="normal"
                        py="16px"
                        borderBottom="1px solid"
                        borderColor="sapphire.10"
                      >
                        {navItem.text}
                      </Button>
                    </NextLink>
                  );
                }
                return null;
              })}
            </nav>
            <Box position="fixed" bottom="0" left="0" right="0">
              <Box height="40px" bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)" />
              <Box bg="white" p="0 40px 40px">
                {footerItems.map((footerItem, i) => (
                  <NextLink key={i} href={footerItem.link.url}>
                    <Button size="lg" width="100%" mt="40px" onClick={() => setMobileMenuIsOpen(false)}>
                      {footerItem.text}
                    </Button>
                  </NextLink>
                ))}
              </Box>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}
