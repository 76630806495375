import { Box, Container, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { Storyblok } from 'types';
import { containerHeight as headerheight } from 'components/layout/header/styles';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

type ProductTourSectionProps = {
  size: string;
  title: string;
  description: string;
  tabs: {
    title: string;
    tabPanelBoxShadow: string;
    tabPanelBackgroundImage: Storyblok.Image;
    tabListItemBackground: string;
    icon: string;
    iconColor: string;
    arcadeDemoUrl: string;
  }[];
};

const ProductTourSection = ({ size, title, description, tabs }: ProductTourSectionProps) => {
  return (
    <Container
      as="section"
      size={size}
      display={{ base: 'none', desktop: 'block' }}
      id="product-tour"
      scrollMarginTop={{
        base: `calc(${headerheight.base}px + 40px)`,
        desktop: `calc(${headerheight.desktop}px + 40px)`,
      }}
    >
      <Box textAlign="center">
        <Heading as="h2" size="lg" mb="24px">
          {title}
        </Heading>
        <Text mb="34px">{description}</Text>
      </Box>
      <Tabs variant="unstyled">
        <TabList mb="24px" justifyContent="center">
          {tabs?.map((tab, i) => (
            <Tab
              key={i}
              fontWeight="medium"
              fontSize="16px"
              borderRadius="4px"
              whiteSpace="nowrap"
              _selected={{ bg: tab.tabListItemBackground }}
            >
              <Box as="span" mr="10px">
                <DynamicIcon icon={tab.icon} fontSize="28px" color={tab.iconColor} />
              </Box>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs?.map((tab, i) => (
            <TabPanel
              key={i}
              position="relative"
              borderRadius="4px"
              overflow="hidden"
              boxShadow={tab.tabPanelBoxShadow}
            >
              <Image
                src={tab.tabPanelBackgroundImage.filename}
                alt={tab.tabPanelBackgroundImage.alt}
                layout="fill"
                priority
              />
              <Box sx={arcadeContainerStyles}>
                <iframe src={tab.arcadeDemoUrl} frameBorder="0" allowFullScreen />
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Container>
  );
};
export default ProductTourSection;

const arcadeContainerStyles = {
  position: 'relative',
  zIndex: 1,
  minHeight: '775px',
  maxWidth: '960px',
  mx: 'auto',
  iframe: {
    position: 'absolute',
    padding: '100px 25px',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};
