export const Information = (props) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C2.25 0 0 2.25 0 5C0 7.75 2.25 10 5 10C7.75 10 10 7.75 10 5C10 2.25 7.75 0 5 0ZM5.625 6.875C5.625 7.22018 5.34518 7.5 5 7.5C4.65482 7.5 4.375 7.22018 4.375 6.875V5C4.375 4.65482 4.65482 4.375 5 4.375C5.34518 4.375 5.625 4.65482 5.625 5V6.875ZM4.375 3.125C4.375 3.5 4.625 3.75 5 3.75C5.375 3.75 5.625 3.5 5.625 3.125C5.625 2.75 5.375 2.5 5 2.5C4.625 2.5 4.375 2.75 4.375 3.125Z"
      fill="currentColor"
    />
  </svg>
);
