import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { Storyblok } from 'types';

type Benefit = {
  title: string,
  text: string,
  color: string,
  icon: string,
}

export default function Benefits(props: {
  smallTitle: string,
  title: string,
  text: string,
  benefits: Benefit[],
  buttons: Storyblok.Button[],
}) {
  const {
    smallTitle,
    title,
    text,
    benefits,
    buttons,
  } = props;
  return (
    <Container
      as="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text
        as="h2"
        variant="uppercase"
        fontSize="12px"
        textAlign="center"
        opacity=".5"
        mb={{ base: '12px', desktop: '16px' }}
      >
        {smallTitle}
      </Text>
      <Heading
        as="h1"
        size="lg"
        textAlign="center"
        mb={{ base: '24px', desktop: '32px' }}
      >
        {title}
      </Heading>
      <Box
        width="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={{ base: '24px', desktop: '32px' }}
      >
        <Text
          as="h2"
          size="md"
          textAlign="center"
          maxWidth="600px"
        >
          {text}
        </Text>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gap="8"
        justifyContent="center"
      >
        {
          benefits.map((benefit: Benefit) => (
            <Box
              key={benefit.title}
              padding="10"
              shadow="xl"
              rounded="md"
              display="flex"
              flexDirection="column"
              alignItems="center"
              maxWidth="1000px"
            >
              <Box
                padding="8"
                mb={{ base: '24px', desktop: '32px' }}
                position="relative"
              >
                <Box
                  height="full"
                  width="full"
                  inset="0"
                  position="absolute"
                  bgColor={benefit.color}
                  opacity={0.075}
                  rounded="full"
                />
                <DynamicIcon
                  icon={benefit.icon}
                  height="8"
                  width="8"
                  color={benefit.color}
                />
              </Box>
              <Heading
                as="h2"
                size="sm"
                textAlign="center"
                mb="8px"
              >
                {benefit.title}
              </Heading>
              <Text
                as="h2"
                size="sm"
                textAlign="center"
                maxWidth="600px"
              >
                {benefit.text}
              </Text>
            </Box>
          ))
        }
      </Box>
      {
          buttons?.length > 0
        && (
          <ButtonGroup
            buttons={buttons}
            mt="48px"
            alignItems="center"
            justifyContent="center"
            width="fit-content"
            bgColor="green"
            rounded="md"
            _button={{
              paddingX: "48px",
              paddingY:"24px"
            }}
          />
        )
        }
    </Container>
  );
}