import { Icon } from '@chakra-ui/react';

export const Quote = (props) => (
  <Icon viewBox="0 0 47 34" fill="none" {...props}>
    <path
      d="M1.38788 33.994C-4.1833 5.31904 8.03045 0.645456 20.0299 0L21.4227 6.34699C13.8159 7.42275 10.816 11.7258 11.3517 17.4273H17.9943V33.994C17.9943 33.994 8.91136 34.006 1.38788 33.994Z"
      fill="currentColor"
    />
    <path
      d="M26.966 33.994C21.3948 5.31904 33.6086 0.645456 45.608 0L47.0008 6.34699C39.394 7.42275 36.3942 11.7258 36.9299 17.4273H43.5724V33.994C43.5724 33.994 34.4895 34.006 26.966 33.994Z"
      fill="currentColor"
    />
  </Icon>
);
