import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import { Storyblok } from '../../types';
import ExternalImage from './ExternalImage';

type TextBlock = {
  subtitle: string;
  title: string;
};

export type CompanyInfoContent = {
  companyLogo: Storyblok.Image;
  textBlock: TextBlock[];
  companyWebsite: Storyblok.Link;
};

type CompanyInfoProps = {
  company: CompanyInfoContent;
};

const CompanyInfo: VFC<CompanyInfoProps> = ({ company }) => {
  return (
    <Flex flexDirection="column" boxShadow="lg" bg="white" borderRadius="4px" py="32px" px="24px" rowGap="16px">
      <Box as="figure" maxW={'180px'}>
        <Link href={company.companyWebsite.url}>
          <ExternalImage image={company.companyLogo} />
        </Link>
      </Box>

      {company.textBlock.map((block, index) => (
        <Flex key={index} rowGap="8px" flexDirection="column">
          <Text size="md" color="sapphire.100" fontWeight="semibold">
            {block.title}
          </Text>
          <Text>{block.subtitle}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default CompanyInfo;
