import { Icon } from '@chakra-ui/react';

export const ArrowRightDash = (props) => (
  <Icon viewBox="0 0 58 16" fill="none" {...props}>
    <path
      d="M57.295 8.35356C57.4902 8.1583 57.4902 7.84172 57.295 7.64645L54.113 4.46447C53.9177 4.26921 53.6011 4.26921 53.4059 4.46447C53.2106 4.65973 53.2106 4.97632 53.4059 5.17158L56.2343 8.00001L53.4059 10.8284C53.2106 11.0237 53.2106 11.3403 53.4059 11.5355C53.6011 11.7308 53.9177 11.7308 54.113 11.5355L57.295 8.35356ZM0.941406 8.5L2.94141 8.5L2.94141 7.5L0.941406 7.5L0.941406 8.5ZM6.94141 8.5L10.9414 8.5L10.9414 7.5L6.94141 7.5L6.94141 8.5ZM14.9414 8.5L18.9414 8.5L18.9414 7.5L14.9414 7.5L14.9414 8.5ZM22.9414 8.5L26.9414 8.5L26.9414 7.5L22.9414 7.5L22.9414 8.5ZM30.9414 8.5L34.9414 8.5L34.9414 7.5L30.9414 7.5L30.9414 8.5ZM38.9414 8.5L42.9414 8.50001L42.9414 7.50001L38.9414 7.5L38.9414 8.5ZM46.9414 8.50001L50.9414 8.50001L50.9414 7.50001L46.9414 7.50001L46.9414 8.50001ZM54.9414 8.50001L56.9414 8.50001L56.9414 7.50001L54.9414 7.50001L54.9414 8.50001ZM57.6485 8.70711C58.039 8.31659 58.039 7.68342 57.6485 7.2929L51.2846 0.928938C50.894 0.538414 50.2609 0.538414 49.8703 0.928938C49.4798 1.31946 49.4798 1.95263 49.8703 2.34315L55.5272 8.00001L49.8703 13.6569C49.4798 14.0474 49.4798 14.6805 49.8703 15.0711C50.2609 15.4616 50.894 15.4616 51.2846 15.0711L57.6485 8.70711ZM0.941406 9L2.94141 9L2.94141 7L0.941406 7L0.941406 9ZM6.94141 9L10.9414 9L10.9414 7L6.94141 7L6.94141 9ZM14.9414 9L18.9414 9L18.9414 7L14.9414 7L14.9414 9ZM22.9414 9L26.9414 9L26.9414 7L22.9414 7L22.9414 9ZM30.9414 9L34.9414 9L34.9414 7L30.9414 7L30.9414 9ZM38.9414 9L42.9414 9.00001L42.9414 7.00001L38.9414 7L38.9414 9ZM46.9414 9.00001L50.9414 9.00001L50.9414 7.00001L46.9414 7.00001L46.9414 9.00001ZM54.9414 9.00001L56.9414 9.00001L56.9414 7.00001L54.9414 7.00001L54.9414 9.00001Z"
      fill="currentColor"
    />
  </Icon>
);
