import { VFC } from 'react';
import { Box, Button, CSSObject, FormControl, Grid } from '@chakra-ui/react';
import { Storyblok } from 'types';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import FormError from 'components/common/FormError';
import { EmailInput } from 'components/forms/Inputs';
import { useFormWithEmailErrorHandler } from 'components/forms/formHooks';
import { useAnalytics } from 'lib/Analytics';

export type AnalyticsFormProps = {
  formConfig: Storyblok.FormConfig;
  text: {
    emailPlaceholder: string;
    submitButton: string;
  };
};

type FormInputs = {
  email: string;
};

const AnalyticsForm: VFC<AnalyticsFormProps> = ({ formConfig, text }) => {
  const { identify, track } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    formErrorHandler,
  } = useFormWithEmailErrorHandler<FormInputs>();
  const { locale } = useRouter();
  const [cookies] = useCookies(['hubspotutk']);
  const hubspotCookie = cookies['hubspotutk'];

  const onSubmit = async (data: FormInputs) => {
    try {
      await axios.post('/api/form/analytics', {
        formData: data,
        params: {
          locale,
          hubspotCookie,
          pageUrl: window.location.href,
          pageName: document.title,
        },
      });

      identify({ email: data.email });
      track('Form Submitted', { email: data.email, form_name: 'analyticsSignUpForm' });

      location.assign(`https://analytics.upflow.io/signup?email=${data.email}`);
    } catch (e) {
      formErrorHandler(e);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={formStyle}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr auto' }} gridGap="12px">
        <FormControl isInvalid={!!errors['email']}>
          <EmailInput variant="filled" placeholder={text.emailPlaceholder} register={register} />
          <FormError error={errors['email']} formConfig={formConfig} />
        </FormControl>
        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          size="md"
          gridColumn="unset"
          width={{ base: '100%', lg: 'auto' }}
        >
          {text.submitButton}
        </Button>
      </Grid>
    </Box>
  );
};

export default AnalyticsForm;

const formStyle: CSSObject = {
  button: {
    borderRadius: 'full',
    position: {
      base: 'intial',
      desktop: 'absolute',
    },
    right: {
      base: 0,
      desktop: '16px',
    },
    top: {
      base: 0,
      desktop: '4px',
    },
  },
  input: {
    borderRadius: 'full',
    height: '48px',
  },
};
