type JobProps = {
  department: string;
};

type JobListProps = {
  department: string;
  jobs: JobProps[];
};

export async function getJobPostDepartments() {
  const res = await fetch('https://api.ashbyhq.com/posting-api/job-board/upflow');
  const data = await res.json();

  const joblist: JobListProps[] = [];
  data.jobs.forEach((job: JobProps) => {
    const dep = joblist.find((entry) => entry.department === job.department);
    if (dep) {
      dep.jobs.push(job);
    } else {
      joblist.push({ department: job.department, jobs: [job] });
    }
  });
  return joblist;
}
