import { VFC } from 'react';
import { Container, Flex, Heading, Text } from '@chakra-ui/react';

type BlogCategoryHeaderProps = {
  category: {
    title: string;
    name: string;
    description: string | null;
  };
};

const BlogCategoryHeader: VFC<BlogCategoryHeaderProps> = ({ category }) => {
  return (
    <Container as={Flex} size="pt50" direction="column" alignItems="center">
      <Heading size="xxl" textAlign="center">
        {category.title}
      </Heading>
      {category.description && (
        <Text mt="24px" maxWidth="700px" textAlign="center">
          {category.description}
        </Text>
      )}
    </Container>
  );
};

export default BlogCategoryHeader;
