import { Grid, Icon, IconButton } from '@chakra-ui/react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { Facebook } from 'components/common/icons/assets/Facebook';
import { LinkedIn } from 'components/common/icons/assets/LinkedIn';
import { Twitter } from 'components/common/icons/assets/Twitter';
import { useRouter } from 'next/router';

type ShareButtonsProps = {
  title: string;
  description: string;
  height?: string;
  color?: string;
};

export function ShareButtons({ title, description, height = '56px', color = 'sapphire.70' }: ShareButtonsProps) {
  const { asPath } = useRouter();
  const url = `${process.env.NEXT_PUBLIC_URL}${asPath}`;

  return (
    <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="8px">
      <IconButton
        as={FacebookShareButton}
        aria-label="Facebook"
        icon={<Icon as={Facebook} fontSize="24px" color={color} transition="color 180ms ease-out" />}
        url={url}
        quote={description}
        boxShadow="sm"
        height={height}
        borderRadius="8px"
        _hover={{ svg: { color: '#4267B2' } }}
      />
      <IconButton
        as={TwitterShareButton}
        aria-label="Twitter"
        icon={<Icon as={Twitter} fontSize="24px" color={color} transition="color 180ms ease-out" />}
        url={url}
        title={title}
        boxShadow="sm"
        height={height}
        borderRadius="8px"
        _hover={{ svg: { color: '#00acee' } }}
      />
      <IconButton
        as={LinkedinShareButton}
        aria-label="LinkedIn"
        icon={<Icon as={LinkedIn} fontSize="24px" color={color} transition="color 180ms ease-out" />}
        url={url}
        title={title}
        summary={description}
        boxShadow="sm"
        height={height}
        borderRadius="8px"
        _hover={{ svg: { color: '#0072b1' } }}
      />
    </Grid>
  );
}
