import { Path, useForm } from 'react-hook-form';
import axios from 'axios';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

export function useFormWithEmailErrorHandler<FormInputs>() {
  const form = useForm<FormInputs>();

  const formErrorHandler = (e: unknown) => {
    if (axios.isAxiosError(e)) {
      const response = e.response.data;

      // Ensure the `email` field is present
      if (Object.keys(form.getValues()).includes('email')) {
        // Check for a captcha blocked error
        if (response.blocked) {
          form.setError('email' as Path<FormInputs>, { type: 'botBlocked' });
          return;
        }

        if (response.email?.isInvalid) {
          form.setError('email' as Path<FormInputs>, { type: 'invalidEmail' });
          return;
        }
        if (response.email?.isPersonal) {
          form.setError('email' as Path<FormInputs>, { type: 'personalEmail' });
          return;
        }
      }
      form.setError('email' as Path<FormInputs>, { type: 'genericError' });
    }
    Sentry.captureException(e);
  };

  return { ...form, formErrorHandler };
}

export function useChiliPiperOnSuccess(successCallback: () => Promise<void>) {
  useEffect(() => {
    const listener = async (event: MessageEvent) => {
      if (event.isTrusted && event.origin === 'https://upflow.chilipiper.com' && event.data.action === 'booked') {
        await successCallback();
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
}
