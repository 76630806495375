import { AspectRatio, Box, Container, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { VFC } from 'react';
import { Storyblok } from 'types';

type FrmCustomersProps = {
  title: string;
  text: string;
  icon: string;
  videoCta: Storyblok.Button[],
  youtubeVideoId: string;
};

const FrmCustomers: VFC<FrmCustomersProps> = ({
  title,
  text,
  icon,
  videoCta,
  youtubeVideoId,
}) => {
  return (
    <Box bgColor="green.12" width="100%">
      <Container as="section">
        <Box
          display={{ base: 'block', desktop: 'flex' }}
          gap="32"
        >
          <Box>
            <DynamicIcon
              icon={icon}
              height="16"
              width="16"
              marginBottom="16px"
            />
            <Heading
              as="h1"
              size="lg"
              mb="16px"
              fontWeight="bold"
            >
              {title}
            </Heading>
            <Text
              as="h3"
              size="sm"
            >
              {text}
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="32px"
          >
            <AspectRatio
              ratio={16 / 9}
              width="full"
              minWidth={{ base: "auto", desktop: "md" }}
              bgColor="green.12"
              rounded="lg"
              marginBottom="16px"
            >
              <Box as="iframe"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                allowFullScreen
                height="full"
                width="full"
                frameBorder="0"
              />
            </AspectRatio>
            <ButtonGroup buttons={videoCta} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FrmCustomers;
