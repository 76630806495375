import { useRouter } from 'next/router';
import { Locale } from 'types';

export const useTranslation = () => {
  const { locale: rawLocale, locales } = useRouter();
  // Trick to remove the "default" locale
  const locale = (rawLocale === 'default' ? 'en' : rawLocale) as Locale;
  const availableLocales = locales.filter((l) => l !== 'default');

  const lang = locale.split('-')[0];
  const countryCode = locale.split('-')[1] || null;

  const t = require(`content/${lang}/common.json`);

  return { t, locale, locales: availableLocales, defaultLocale: 'en', lang, countryCode };
};
