import { Box, Container, Flex, Heading, Tag, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import FormRouter from 'components/common/FormRouter';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import Image from 'next/image';
import { VFC } from 'react';
import { theme } from 'theme';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';

type Props = {
  tag: string;
  title: string;
  topIcon?: string;
  topIconBackgroundColor?: string;
  description?: string;
  form?: Storyblok.Form;
  buttons?: Storyblok.Button[];
  image?: Storyblok.Image;
  layerStyle?: string;
  formConfig: Storyblok.FormConfig;
};

const HeroTwoSection: VFC<Props> = ({
  layerStyle = 'default',
  tag,
  title,
  topIcon,
  topIconBackgroundColor,
  description,
  buttons,
  form,
  image,
  formConfig,
}) => {
  return (
    <Container as="section" layerStyle={layerStyle} size="ptHeader">
      <Box mx="auto" textAlign="center" maxWidth="3xl">
        {topIcon && topIconBackgroundColor && (
          <Flex
            width="72px"
            height="72px"
            as="figure"
            justifyContent="center"
            alignItems="center"
            backgroundColor={theme.colors[topIconBackgroundColor]}
            my="48px"
            boxShadow="lg"
            rounded="50%"
            mx="auto"
          >
            <DynamicIcon icon={topIcon} color="white" fontSize="36px" />
          </Flex>
        )}
        {tag && (
          <Tag colorScheme="whiteAlpha" mb="16px">
            {tag}
          </Tag>
        )}
        <Heading as="h1" size="xl" mb="24px">
          {title}
        </Heading>
        {description && <Text>{description}</Text>}
      </Box>
      {buttons?.length > 0 && <ButtonGroup buttons={buttons} mt="48px" alignItems="center" justifyContent="center" />}
      {form && (
        <Box width={{ base: '100%', desktop: '400px' }} mx="auto" mt="48px" position="relative">
          <FormRouter form={form} formConfig={formConfig} />
        </Box>
      )}
      {image?.filename && (
        <Flex
          as="figure"
          mt="88px"
          p="8px"
          bg="white"
          boxShadow="xl"
          borderRadius="4px"
          alignItems="center"
          mx="auto"
          maxWidth="5xl"
        >
          <Image
            src={image.filename}
            alt={image.alt}
            height={imageDimensions(image.filename).height}
            width={imageDimensions(image.filename).width}
            priority
          />
        </Flex>
      )}
    </Container>
  );
};

export default HeroTwoSection;
