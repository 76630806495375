import { Box, Container, Flex, Grid, Icon } from '@chakra-ui/react';
import { Quote } from 'components/common/icons/assets/Quote';
import RichText from 'components/common/RichText';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';

export default function TestimonialOneSection({ size, quote, author, companyImage, authorImage }) {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '320px 1fr' }} gridGap={{ base: '56px', desktop: '110px' }}>
        <Flex position="relative" px={{ base: '60px', desktop: 0 }}>
          <Image
            src={companyImage.filename}
            alt={companyImage.alt}
            height={companyImage.height || imageDimensions(companyImage.filename).height}
            width={companyImage.width || imageDimensions(companyImage.filename).width}
          />
          <Box
            as="figure"
            position="absolute"
            bottom={{ base: '-16px', desktop: '-25px' }}
            right={{ base: '36px', desktop: '-25px' }}
            borderRadius="4px"
            overflow="hidden"
            height={{ base: '64px', desktop: '110px' }}
            width={{ base: '64px', desktop: '110px' }}
          >
            <Image src={authorImage.filename} alt={authorImage.alt} height={110} width={110} />
          </Box>
        </Flex>
        <Flex px={{ base: '40px', desktop: 0 }}>
          <Box textAlign={{ base: 'center', desktop: 'left' }}>
            <Icon as={Quote} fontSize={{ base: '25px', desktop: '50px' }} mb={{ base: '16px', desktop: '32px' }} />
            <RichText
              content={quote}
              textColor="sapphire.100"
              _p={{
                fontSize: {
                  base: '20px',
                  desktop: '34px',
                },
                lineHeight: {
                  base: '30px',
                  desktop: '48px',
                },
              }}
            />
            <Box
              dangerouslySetInnerHTML={{ __html: author }}
              fontSize={{ base: '14px', desktop: '20px' }}
              color="sapphire.70"
            />
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
}
