import { VFC } from 'react';
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

type ChiliPiperModalProps = {
  isOpen: boolean;
  onClose: () => void;
  url: string | null;
};

export const ChiliPiperModal: VFC<ChiliPiperModalProps> = ({ isOpen, onClose, url }) => {
  if (!url) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size="lg">
      <ModalOverlay />
      <ModalContent width="550px" maxW="none" bg="#F2F5F8">
        <ModalHeader padding="24px">
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody padding={0}>
          <Box as="iframe" src={url} sx={chiliPiperContainerStyles} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const chiliPiperContainerStyles = {
  mx: 'auto',
  position: 'relative',
  width: '550px',
  height: '800px',
  '.chilipiper-popup': {
    background: 'none',
  },
  '.chilipiper-popup-window': {
    height: '100% !important', // using important to override the height limit based on window height
  },
};
