import { Box, Container, Flex, Grid, Heading } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import FormRouter from 'components/common/FormRouter';
import CustomGrid from '../common/Grid';
import { GridProps, SectionProps, Storyblok } from '../../types';

type HeroThreeSectionProps = SectionProps<{
  title: string;
  text: Storyblok.RichText;
  formTitle: string;
  form: Storyblok.Form;
  formConfig: Storyblok.FormConfig;
  underFormGrid?: GridProps[];
}>;

export default function HeroThreeSection({
  size,
  title,
  text,
  formTitle,
  form,
  formConfig,
  underFormGrid,
}: HeroThreeSectionProps) {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridRowGap="32px">
        <Flex maxWidth="500px">
          <Box>
            <Heading as="h1" size="xl" mb="26px">
              {title}
            </Heading>
            <RichText content={text} indentList={false} />
          </Box>
        </Flex>
        <Flex>
          <Box
            bg="white"
            width="100%"
            mx={{ base: 0, desktop: '56px' }}
            p="24px 40px"
            borderRadius="4px"
            boxShadow="md"
          >
            <Heading as="h3" size="md" mb="24px">
              {formTitle}
            </Heading>
            <FormRouter form={form} formConfig={formConfig} />
            {underFormGrid && (
              <Container pt={{ base: '8px', desktop: '24px' }} px={0} py={0}>
                <Grid>
                  {underFormGrid.map((gridProps, index) => (
                    <CustomGrid {...gridProps} key={index} />
                  ))}
                </Grid>
              </Container>
            )}
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
}
