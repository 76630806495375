import { Icon } from '@chakra-ui/react';

export const Star = (props) => (
  <Icon viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M27.5905 10.5748C27.2836 9.58233 26.465 8.85769 25.4782 8.70481L19.1658 7.75253L16.3343 1.79854C15.8924 0.871537 14.9871 0.285645 13.9965 0.285645C13.0059 0.285645 12.1006 0.871537 11.6587 1.79854L8.83597 7.75071L2.52524 8.70481C1.54338 8.858 0.727722 9.57634 0.416446 10.562C0.103627 11.5538 0.362225 12.6453 1.08165 13.3697L5.64055 17.9872L4.56265 24.5147C4.39514 25.5383 4.79731 26.5727 5.60037 27.1837C6.40343 27.7948 7.46841 27.8767 8.34838 27.3952L13.9991 24.3072L19.6551 27.4025C20.5357 27.8847 21.6017 27.8025 22.405 27.1904C23.2084 26.5784 23.6099 25.5426 23.4408 24.5184L22.3577 17.989L26.9166 13.3697C27.6314 12.6479 27.8926 11.5646 27.5905 10.5748Z"
      fill="currentColor"
    />
  </Icon>
);
