import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  List,
  ListItem,
  SystemStyleObject,
  Text,
  useTheme,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { NODE_IMAGE, render } from 'storyblok-rich-text-react-renderer';
import YouTubePlayer from 'react-player/youtube';
import AnchorImageBlock from './AnchorImage';
import GridBlock from './Grid';
import AnchorGridBlock from './AnchorGrid';
import AnchorHeading from './AnchorHeading';
import { DynamicComponent } from 'components/sections';
import { LinkRouter } from 'components/common/link-router';
import HubSpotCTA from 'components/common/RichText/HubSpotCTA';
import { slugify } from 'utils/slugify';
import { Storyblok } from 'types';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import Image from 'next/image';
import { imageDimensions, normalizeUrl } from 'utils/storyblok';
import { Fragment } from 'react';

const SnippetBlock = dynamic(() => import('./Snippet'), {
  ssr: false,
});

export type RichTextProps = {
  content: Storyblok.RichText;
  anchorClassName?: string;
  indentList?: boolean;
  textSpaces?: boolean;
  anchorHeadings?: boolean;
  textFontFamily?: 'reading' | 'body';
  textColor?: 'sapphire.100' | 'sapphire.70' | 'inherit';
  size?: 'lg' | 'md' | 'sm';
  _p?: SystemStyleObject;
  _ul?: SystemStyleObject;
  _ol?: SystemStyleObject;
};

export default function RichText({ content, ...rest }: RichTextProps) {
  const {
    indentList = true,
    textSpaces = true,
    anchorHeadings = false,
    textFontFamily = 'body',
    textColor = 'sapphire.70',
    size = 'md',
    _p,
    _ul,
    _ol,
    anchorClassName,
  } = rest;
  const styles = useRichTextStyles({
    content,
    size,
    textSpaces,
    indentList,
    textFontFamily,
    textColor,
    _p,
    _ul,
    _ol,
  });

  if (!content) return null;

  return (
    <Box sx={styles}>
      {content.content?.map((contentItem, i) => {
        if (anchorHeadings && contentItem.type === 'heading') {
          if (!contentItem.content) {
            return null;
          }
          const level = contentItem.attrs.level;
          if (level === 2) {
            const text = contentItem.content.find((item) => item.type === 'text')?.text;
            if (!text) return null;
            const id = slugify(text);
            return (
              <AnchorHeading key={i} id={id} anchorClassName={anchorClassName}>
                {text}
              </AnchorHeading>
            );
          }
        }
        if (contentItem.type === 'blok') {
          return (
            <Fragment key={i}>
              {contentItem.attrs.body.map((block, j) => {
                const key = `${i}-${j}`;
                if (block.component === 'spaceBlock') {
                  const { height } = block;
                  return <Box key={key} as="hr" height={height} />;
                }
                if (block.component === 'snippetBlock') {
                  const { snippetCode } = block;
                  return <SnippetBlock key={key} snippetCode={snippetCode} />;
                }
                if (block.component === 'listIcon') {
                  const { items } = block;
                  return (
                    <List key={key} spacing={2}>
                      {items.map((item, k) => (
                        <ListItem key={`${key}-${k}`} display="flex">
                          <Flex as="span" alignItems="center" mr="10px">
                            <DynamicIcon icon={item.icon} color={item.iconColor} />
                          </Flex>
                          {item.text}
                        </ListItem>
                      ))}
                    </List>
                  );
                }
                if (block.component === 'quoteBlock') {
                  const { text, signature } = block;
                  return (
                    <Flex
                      key={key}
                      textAlign="center"
                      borderY="1px solid #eef0f3"
                      py="56px"
                      mb="64px"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <DynamicIcon icon="Quote" color="azure.100" fontSize="32px" />
                      <Heading size="lg" fontWeight="500" my="24px">
                        {text}
                      </Heading>
                      <Text>{signature}</Text>
                    </Flex>
                  );
                }
                if (block.component === 'anchorImage') {
                  const { image, id } = block;
                  return <AnchorImageBlock key={key} id={id} image={image} anchorClassName={anchorClassName} />;
                }
                if (block.component === 'anchorGridBlock') {
                  const { id, columns } = block;
                  return (
                    <AnchorGridBlock
                      key={key}
                      id={id}
                      anchorClassName={anchorClassName}
                      columns={columns}
                      richTextProps={rest}
                    />
                  );
                }
                if (block.component === 'gridBlock') {
                  const { columns } = block;
                  return <GridBlock key={key} columns={columns} richTextProps={rest} />;
                }
                if (block.component === 'videoBlock') {
                  const { videoUrl } = block;
                  return (
                    <Box key={key} my="10">
                      <YouTubePlayer url={videoUrl} width="100%" height="410px" />
                    </Box>
                  );
                }
                if (block.component === 'dividerBlock') {
                  const { height } = block;
                  return <Divider key={key} height={height} bg="#eef0f3" mb="5" />;
                }
                if (block.component === 'ctaButton') {
                  if (!['url', 'asset'].includes(block.Link.linktype)) {
                    return 'Unsupported link type, please use "url" or "asset" only';
                  }

                  return (
                    <Box textAlign={block.align} mt="16px" key={key}>
                      <LinkRouter href={block.Link.url} newWindow>
                        <Button variant="solid" size="lg" colorScheme="azure">
                          {block.text}
                        </Button>
                      </LinkRouter>
                    </Box>
                  );
                }
                if (block.component === 'colorBlock') {
                  const { content } = block;
                  return (
                    <Box
                      key={key}
                      bg="neutral.60"
                      p={{ base: '16px', desktop: '32px' }}
                      my={{ base: '24px', desktop: '40px' }}
                      borderRadius="8px"
                    >
                      <RichText
                        content={content}
                        {...rest}
                        _p={{ ...rest._p, _last: { mb: 0 }, '.lighter': { color: 'sapphire.70' } }}
                        textFontFamily="body"
                      />
                    </Box>
                  );
                }
                if (block.component === 'hubspotCta') {
                  return <HubSpotCTA key={key} id={block.id} alt={block.alt} />;
                }
                return <DynamicComponent key={key} block={block} />;
              })}
            </Fragment>
          );
        }
        return render(
          { type: 'doc', content: [contentItem] },
          {
            nodeResolvers: {
              [NODE_IMAGE]: (children, { src, alt }: { src: string; alt: string | null }) => (
                <Box as="figure">
                  <Image src={normalizeUrl(src)} alt={alt} {...imageDimensions(src)} />
                </Box>
              ),
            },
          },
        );
      })}
    </Box>
  );
}

function useRichTextStyles({
  content,
  indentList,
  size,
  textColor,
  textFontFamily,
  textSpaces,
  _p,
  _ul,
  _ol,
}: RichTextProps): SystemStyleObject {
  const { components } = useTheme();

  if (!content) return null;
  const startsWithTitle = content.content?.[0].type === 'heading';

  const sizes = {
    md: {
      h1: components.Heading.sizes.xl,
      h2: components.Heading.sizes.lg,
      h3: components.Heading.sizes.md,
      h4: components.Heading.sizes.sm,
      text: components.Text.sizes.md,
    },
    sm: {
      h1: components.Heading.sizes.lg,
      h2: components.Heading.sizes.md,
      h3: components.Heading.sizes.sm,
      h4: components.Heading.sizes.sm,
      text: components.Text.sizes.sm,
    },
  };
  const fontSize = sizes[size] ?? sizes['md'];

  return {
    ...(startsWithTitle && {
      'h1:first-of-type, h2:first-of-type, h3:first-of-type, h4:first-of-type, h5:first-of-type, h6:first-of-type': {
        mt: 0,
      },
    }),
    'h1, h2, h3, h4, h5, h6': {
      fontFamily: 'heading',
      fontWeight: 'semibold',
    },
    h1: {
      ...fontSize.h1,
      mb: textSpaces && '24px',
    },
    h2: {
      ...fontSize.h2,
      mb: textSpaces && '16px',
    },
    h3: {
      ...fontSize.h3,
      mb: textSpaces && '12px',
    },
    h4: {
      ...fontSize.h4,
      mb: textSpaces && '8px',
    },
    p: {
      ...fontSize.text,
      fontFamily: textFontFamily,
      color: textColor,
      mb: textSpaces && '16px',
      ..._p,
    },
    blockquote: {
      padding: {
        base: '8px 0',
        tablet: '32px',
      },
      p: {
        fontStyle: 'italic',
        color: 'sapphire.70',
        ...fontSize.text,
      },
    },
    ul: {
      ...fontSize.text,
      pl: indentList && 5,
      p: { mb: 0 },
      ..._ul,
    },
    ol: {
      ...fontSize.text,
      pl: indentList && 5,
      p: { mb: 0 },
      ..._ol,
    },
    li: {
      pl: indentList && 5,
    },
    a: {
      color: 'sapphire.100',
      textDecoration: 'underline',
      transition: 'color 200ms ease-out',
      _hover: {
        color: 'sapphire.70',
        textDecoration: 'underline',
      },
    },
    b: {
      fontWeight: 'semibold',
    },
    '.secondary': {
      color: 'sapphire.100',
    },
  };
}
