import { VFC } from 'react';
import { Container, Box, Heading, Text, Grid, Flex } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';
import Video from 'components/common/Video';

type VideoTwoSectionProps = SectionProps<{
  title: string;
  description: string;
  youtubeId: string;
  thumbnail: Storyblok.Image;
}>;

const VideoTwoSection: VFC<VideoTwoSectionProps> = ({ size, title, description, youtubeId, thumbnail }) => {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridGap={{ base: '40px', desktop: '80px' }}>
        <Flex alignItems="center">
          <Video youtubeId={youtubeId} thumbnail={thumbnail} />
        </Flex>
        <Flex>
          <Box>
            <Heading as="h2" size="lg" mb="16px">
              {title}
            </Heading>
            <Text>{description}</Text>
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
};

export default VideoTwoSection;
