import { VFC } from 'react';
import { Box, Container, Flex, Grid, Heading, List, Text } from '@chakra-ui/react';
import { SectionProps, Storyblok } from 'types';
import { ButtonGroup } from 'components/common/ButtonGroup';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';

type FeatureOneSectionProps = SectionProps<{
  title: string;
  description: string;
  buttons: Storyblok.Button[];
  image: Storyblok.Image;
  layerStyle: string;
  listItems: {
    _uid: number;
    icon: string;
    title: string;
    description: string;
  }[];
}>;

const FeatureOneSection: VFC<FeatureOneSectionProps> = ({
  size,
  title,
  description,
  buttons,
  image,
  listItems,
  layerStyle,
}) => {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} layerStyle={layerStyle} borderRadius="4px">
        <Flex alignItems="center" p={{ base: '40px', desktop: '80px' }}>
          <Box maxWidth="lg" mx="auto">
            <Heading as="h2" mb="16px">
              {title}
            </Heading>
            <Text variant="unstyled" mb="16px">
              {description}
            </Text>
            <ButtonGroup buttons={buttons} />
          </Box>
        </Flex>
        <Flex py={{ base: '40px', desktop: '80px' }} px={{ base: '40px', desktop: 0 }} alignItems="center">
          {image.filename && (
            <Image
              src={image.filename}
              alt={image.alt}
              width={imageDimensions(image.filename).width}
              height={imageDimensions(image.filename).height}
            />
          )}
          {listItems?.length > 0 && (
            <List spacing="48px">
              {listItems.map((listItem) => (
                <Box key={listItem._uid} maxWidth="375px">
                  <Flex
                    mb="16px"
                    height="40px"
                    width="40px"
                    borderRadius="full"
                    bg="azure.100"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <DynamicIcon icon={listItem.icon} fontSize="24px" color="white" />
                  </Flex>
                  <Heading size="sm" mb="4px">
                    {listItem.title}
                  </Heading>
                  <Text variant="unstyled" fontSize="md">
                    {listItem.description}
                  </Text>
                </Box>
              ))}
            </List>
          )}
        </Flex>
      </Grid>
    </Container>
  );
};

export default FeatureOneSection;
