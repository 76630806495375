import { Icon } from '@chakra-ui/react';

export const FiveStars = (props) => (
  <Icon viewBox="0 0 112 15" fill="none" {...props}>
    <path
      d="M15.1428 4.96525L10.8267 4.34863L8.90012 0.508474C8.71381 0.193686 8.37106 0 8.00032 0C7.62958 0 7.28683 0.193686 7.10051 0.508474L5.17393 4.34961L0.857874 4.96525C0.480829 5.01836 0.167268 5.27726 0.0492386 5.63294C-0.0687909 5.98861 0.0292192 6.37925 0.301995 6.64035L3.42431 9.63019L2.68748 13.8523C2.62321 14.2206 2.77744 14.5929 3.08532 14.8125C3.3932 15.0322 3.80134 15.0611 4.13816 14.8872L8.00032 12.893L11.8605 14.8852C12.1973 15.0591 12.6054 15.0302 12.9133 14.8106C13.2212 14.5909 13.3754 14.2187 13.3112 13.8503L12.5743 9.62822L15.6986 6.64035C15.9707 6.37945 16.0685 5.98955 15.951 5.63437C15.8335 5.27919 15.521 5.02023 15.1448 4.96623L15.1428 4.96525Z"
      fill="currentColor"
    />
    <path
      d="M39.1428 4.96525L34.8267 4.34863L32.9001 0.508474C32.7138 0.193686 32.3711 0 32.0003 0C31.6296 0 31.2868 0.193686 31.1005 0.508474L29.1739 4.34961L24.8579 4.96525C24.4808 5.01836 24.1673 5.27726 24.0492 5.63294C23.9312 5.98861 24.0292 6.37925 24.302 6.64035L27.4243 9.63019L26.6875 13.8523C26.6232 14.2206 26.7774 14.5929 27.0853 14.8125C27.3932 15.0322 27.8013 15.0611 28.1382 14.8872L32.0003 12.893L35.8605 14.8852C36.1973 15.0591 36.6054 15.0302 36.9133 14.8106C37.2212 14.5909 37.3754 14.2187 37.3112 13.8503L36.5743 9.62822L39.6986 6.64035C39.9707 6.37945 40.0685 5.98955 39.951 5.63437C39.8335 5.27919 39.521 5.02023 39.1448 4.96623L39.1428 4.96525Z"
      fill="currentColor"
    />
    <path
      d="M63.1428 4.96525L58.8267 4.34863L56.9001 0.508474C56.7138 0.193686 56.3711 0 56.0003 0C55.6296 0 55.2868 0.193686 55.1005 0.508474L53.1739 4.34961L48.8579 4.96525C48.4808 5.01836 48.1673 5.27726 48.0492 5.63294C47.9312 5.98861 48.0292 6.37925 48.302 6.64035L51.4243 9.63019L50.6875 13.8523C50.6232 14.2206 50.7774 14.5929 51.0853 14.8125C51.3932 15.0322 51.8013 15.0611 52.1382 14.8872L56.0003 12.893L59.8605 14.8852C60.1973 15.0591 60.6054 15.0302 60.9133 14.8106C61.2212 14.5909 61.3754 14.2187 61.3112 13.8503L60.5743 9.62822L63.6986 6.64035C63.9707 6.37945 64.0685 5.98955 63.951 5.63437C63.8335 5.27919 63.521 5.02023 63.1448 4.96623L63.1428 4.96525Z"
      fill="currentColor"
    />
    <path
      d="M87.1428 4.96525L82.8267 4.34863L80.9001 0.508474C80.7138 0.193686 80.3711 0 80.0003 0C79.6296 0 79.2868 0.193686 79.1005 0.508474L77.1739 4.34961L72.8579 4.96525C72.4808 5.01836 72.1673 5.27726 72.0492 5.63294C71.9312 5.98861 72.0292 6.37925 72.302 6.64035L75.4243 9.63019L74.6875 13.8523C74.6232 14.2206 74.7774 14.5929 75.0853 14.8125C75.3932 15.0322 75.8013 15.0611 76.1382 14.8872L80.0003 12.893L83.8605 14.8852C84.1973 15.0591 84.6054 15.0302 84.9133 14.8106C85.2212 14.5909 85.3754 14.2187 85.3112 13.8503L84.5743 9.62822L87.6986 6.64035C87.9707 6.37945 88.0685 5.98955 87.951 5.63437C87.8335 5.27919 87.521 5.02023 87.1448 4.96623L87.1428 4.96525Z"
      fill="currentColor"
    />
    <path
      d="M111.143 4.96525L106.827 4.34863L104.9 0.508474C104.714 0.193686 104.371 0 104 0C103.63 0 103.287 0.193686 103.101 0.508474L101.174 4.34961L96.8579 4.96525C96.4808 5.01836 96.1673 5.27726 96.0492 5.63294C95.9312 5.98861 96.0292 6.37925 96.302 6.64035L99.4243 9.63019L98.6875 13.8523C98.6232 14.2206 98.7774 14.5929 99.0853 14.8125C99.3932 15.0322 99.8013 15.0611 100.138 14.8872L104 12.893L107.86 14.8852C108.197 15.0591 108.605 15.0302 108.913 14.8106C109.221 14.5909 109.375 14.2187 109.311 13.8503L108.574 9.62822L111.699 6.64035C111.971 6.37945 112.069 5.98955 111.951 5.63437C111.834 5.27919 111.521 5.02023 111.145 4.96623L111.143 4.96525Z"
      fill="currentColor"
    />
  </Icon>
);
