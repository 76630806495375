import {
  Container,
  Box,
  Grid,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import RichText from 'components/common/RichText';

export default function FaqSection({ size, layerStyle = 'ligthAzure', title, accordionGroups }) {
  return (
    <Container as="section" size={size}>
      <Box p={{ base: '24px', desktop: '80px' }} borderRadius="4px" layerStyle={layerStyle}>
        <Heading as="h2" size="lg" mb={{ base: '32px', desktop: '54px' }}>
          {title}
        </Heading>
        <Grid gridGap={{ base: '24px', desktop: '32px' }}>
          {accordionGroups.map((accordionGroup, i) => (
            <Box key={i}>
              <Text variant="uppercase" opacity=".5" as="h3" size="sm" mb={{ base: '20px', desktop: '24px' }}>
                {accordionGroup.title}
              </Text>
              <Accordion allowToggle>
                {accordionGroup.accordionItems.map((accordionItem, i) => (
                  <AccordionItem key={i} mx="-4">
                    <h4>
                      <AccordionButton py="16px" my="2px">
                        <Box flex="1" textAlign="left">
                          {accordionItem.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h4>
                    <AccordionPanel pb={{ base: '4px', desktop: '12px' }}>
                      <RichText content={accordionItem.content} size="sm" textColor="inherit" />
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
