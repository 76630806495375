import { FC } from 'react';
import NextLink from 'next/link';
import { Link, LinkProps } from '@chakra-ui/react';

type SmartLinkProps = {
  href: string;
} & LinkProps;

const SmartLink: FC<SmartLinkProps> = ({ href, ...linkProps }) => {
  if (href.startsWith('http')) {
    return <Link href={href} {...linkProps} />;
  }

  return (
    <NextLink href={href} passHref>
      <Link {...linkProps} />
    </NextLink>
  );
};

export default SmartLink;
