import { Icon } from '@chakra-ui/react';

export const SemiStar = (props) => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M9.24416 1.84903C9.57556 1.15378 10.2546 0.714355 10.9975 0.714355L10.9995 18.7305L6.76141 21.0466C6.10143 21.4077 5.30269 21.3462 4.7004 20.8879C4.09811 20.4297 3.79648 19.6539 3.92211 18.8862L4.73054 13.9905L1.31136 10.5274C0.771791 9.98408 0.577842 9.16547 0.812457 8.42163C1.04591 7.68238 1.65765 7.14362 2.39405 7.02873L7.1271 6.31316L9.24416 1.84903Z"
      fill="currentColor"
    />
    <path
      d="M19.6088 7.02873C20.3489 7.14339 20.9628 7.68687 21.1931 8.43119C21.4196 9.17356 21.2237 9.98606 20.6876 10.5274L17.2684 13.9919L18.0808 18.8889C18.2076 19.6571 17.9065 20.4339 17.3039 20.8929C16.7014 21.352 15.9019 21.4136 15.2415 21.052L10.9995 18.7305L10.9976 0.714355C11.7405 0.714355 12.4195 1.15378 12.7509 1.84903L14.8745 6.31452L19.6088 7.02873Z"
      fill="currentColor"
      fillOpacity="0.5"
    />
  </Icon>
);
