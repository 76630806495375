import { VFC } from 'react';
import { Flex, Grid as ChakraGrid } from '@chakra-ui/react';
import { DynamicComponent } from 'components/sections';
import { GridProps } from 'types';

const alignmentMap = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const Grid: VFC<GridProps> = ({ columns, noWrapInMobile }) => {
  const columnsSetup = columns.map((column) => (column.size ? column.size : 'auto')).join(' ');
  const rowsCount = Math.max(...columns.map((column) => column.content.length));

  return (
    <ChakraGrid
      gridTemplateColumns={{ base: noWrapInMobile ? columnsSetup : '1fr', desktop: columnsSetup }}
      columnGap="8px"
      rowGap="16px"
      width="100%"
    >
      {Array.from(Array(rowsCount)).map((_, rowIndex) => {
        return columns.map((column, columnIndex) => {
          const item = column.content[rowIndex];
          if (item) {
            return (
              <Flex key={columnIndex} justifyContent={alignmentMap[column.alignment]} alignItems="center">
                <DynamicComponent block={item} />
              </Flex>
            );
          }
        });
      })}
    </ChakraGrid>
  );
};
export default Grid;
