import { Box, Container, Heading } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { VFC } from 'react';
import { Storyblok } from 'types';

/* Types */

type FrmCtaProps = {
  title: string;
  buttons: Storyblok.Button[];
  image: Storyblok.Image;
};

/* Widgets */

const FrmCta: VFC<FrmCtaProps> = ({
  title,
  buttons,
  image,
}) => {
  return (
    <Container as="section">
      <Box
        bgColor="azure.10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="full"
        justifyContent="space-between"
        rounded="2xl"
        padding={{ base: "8", desktop: "12" }}
        gap={{ base: "0", desktop: "12" }}
        position="relative"
      >
        <Heading
          as="h3"
          size="lg"
          fontWeight="bold"
          textColor="blue.700"
          textAlign={{ base: "center", desktop: "left" }}
        >
          {title}
        </Heading>
        <ButtonGroup buttons={buttons} />
      </Box>
    </Container>
  );
};

export default FrmCta;
