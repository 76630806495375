import { Box, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/common/ButtonGroup';
import Image from 'next/image';
import { VFC } from 'react';
import { theme } from 'theme';
import { Storyblok } from 'types';
import { imageDimensions } from 'utils/storyblok';

export type CustomerStoryCardProps = {
  _uid: string;
  button: Storyblok.Button[];
  title: string;
  customerName: string;
  customerRole: string;
  backgroundImage: Storyblok.Image;
  companyLogo: Storyblok.Image;
  isLargeCard: boolean;
};

const CustomerStoryCard: VFC<CustomerStoryCardProps> = ({
  button,
  title,
  customerName,
  customerRole,
  backgroundImage,
  companyLogo,
  isLargeCard,
}) => {
  return (
    <Box
      boxShadow="xl"
      borderRadius="4px"
      p="24px"
      backgroundColor={theme.colors.white[90]}
      height="360px"
      position="relative"
      flex={1}
    >
      <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" flexDirection="column" position="relative" zIndex="1">
          <Box width={90} height="40px" alignContent="center">
            <Image
              src={companyLogo.filename}
              height={imageDimensions(companyLogo.filename).height}
              width={imageDimensions(companyLogo.filename).width}
              alt={companyLogo.alt}
              layout="responsive"
            />
          </Box>
          <Text
            width={{ base: '100%', desktop: '50%' }}
            as="h2"
            my="16px"
            fontWeight={700}
            color={theme.colors.sapphire[100]}
            fontSize="22px"
          >
            {title}
          </Text>
          <Box>
            <Text fontWeight="600" fontSize="15px" lineHeight="24px">
              {customerName}
            </Text>
            <Text fontWeight="400" fontSize="15px" lineHeight="24px">
              {customerRole}
            </Text>
          </Box>
        </Box>
        {button?.length > 0 && (
          <ButtonGroup
            width={{ base: 'min-content' }}
            buttons={button}
            alignItems="left"
            position="relative"
            zIndex="1"
          />
        )}
      </Box>
      <Box
        position="absolute"
        top={{ desktop: '0' }}
        bottom="0"
        right="0"
        zIndex="0"
        width={{
          base: imageDimensions(backgroundImage.filename).width / 2,
          desktop: imageDimensions(backgroundImage.filename).width,
        }}
        height={{
          base: imageDimensions(backgroundImage.filename).height / 2,
          desktop: '100%',
        }}
      >
        <Image
          src={backgroundImage.filename}
          width={imageDimensions(backgroundImage.filename).width}
          height={imageDimensions(backgroundImage.filename).height}
          objectFit="contain"
          layout="fill"
          alt={backgroundImage.alt}
        />
      </Box>
    </Box>
  );
};

export default CustomerStoryCard;
