import { Select } from '@chakra-ui/react';
import { useTranslation } from 'lib/hooks/useTranslation';
import { useRouter } from 'next/router';

export default function LocaleSelect() {
  const router = useRouter();
  const { t, locales, locale } = useTranslation();

  function onChange({ target: { value } }) {
    router.push(`${router.asPath}`, null, { locale: value });
  }

  return (
    <Select
      onChange={onChange}
      variant="filled"
      color="sapphire.100"
      fontWeight="medium"
      value={locale}
      focusBorderColor="none"
    >
      {locales.map((locale) => (
        <option key={locale} value={locale}>
          {t.locales[locale]}
        </option>
      ))}
    </Select>
  );
}
