import { FC } from 'react';
import { Storyblok } from 'types';
import { Box, Container, Flex, Grid, GridItem, Heading, Icon } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import ExternalImage from 'components/common/ExternalImage';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { Quote } from '../common/icons/assets/Quote';
import CompanyInfo, { CompanyInfoContent } from '../common/CompanyInfo';
import { colors } from '../../utils/colorScheme';

type ImageBlockProps = {
  component: string;
  image: Storyblok.Image;
};

type SectionLeftBlock = {
  image?: ImageBlockProps;
  companyInfo?: CompanyInfoContent[];
};

type CustomerStoriesBodySectionProps = {
  icon: string;
  sectionTitle: string;
  sectionContent: Storyblok.RichText;
  sectionLeftBlock: SectionLeftBlock;
  quote?: Storyblok.RichText;
  blueBackground?: Boolean;
  accentColor: string;
};

const CustomerStoriesBodySection: FC<CustomerStoriesBodySectionProps> = ({
  icon,
  sectionTitle,
  sectionContent,
  sectionLeftBlock,
  quote,
  blueBackground,
  accentColor,
}) => {
  return (
    <Box bg={blueBackground ? colors.blue.lighter : undefined}>
      <Container as="section" size="py50">
        <Grid templateColumns={{ base: '1fr', desktop: 'repeat(3, 1fr)' }} gap="48px">
          <GridItem
            colSpan={1}
            order={{ base: 2, desktop: 1 }}
            justifySelf="center"
            placeSelf="center"
            alignSelf="center"
          >
            {sectionLeftBlock[0].component === 'customerStoriesCompanyInfo' ? (
              <CompanyInfo company={sectionLeftBlock[0]} />
            ) : (
              <Box padding={{ base: '0px', desktop: '52px' }}>
                <ExternalImage image={sectionLeftBlock[0].image} />
              </Box>
            )}
          </GridItem>
          <GridItem colSpan={{ base: 1, desktop: 2 }} order={{ base: 1, desktop: 2 }}>
            <Box borderRadius="100%" bg="white" padding="16px" mb="24px" width="fit-content" boxShadow="md">
              <DynamicIcon icon={icon} color={colors[accentColor].primary} fontSize="40px" />
            </Box>

            <Heading mb="20px">{sectionTitle}</Heading>
            <RichText content={sectionContent} />

            {quote?.content[0]?.content && (
              <Flex px={{ base: '40px', desktop: 0 }} pt={{ base: '10px', desktop: '20px' }}>
                <Box textAlign={{ base: 'center', desktop: 'left' }} width={{ base: '100%', desktop: '75%' }}>
                  <Icon fontSize="40px" as={Quote} color={colors[accentColor].primary} />
                  <RichText
                    content={quote}
                    textColor={colors[accentColor].primary}
                    _p={{
                      fontSize: {
                        base: '20px',
                        desktop: '32px',
                      },
                      lineHeight: {
                        base: '30px',
                        desktop: '48px',
                      },
                    }}
                  />
                </Box>
              </Flex>
            )}
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default CustomerStoriesBodySection;
