import { Icon } from '@chakra-ui/react';

export const BracketRight = (props) => (
  <Icon viewBox="0 0 10 10" fill="none" {...props}>
    <path
      d="M2.71967 7.96967C2.42678 8.26256 2.42678 8.73744 2.71967 9.03033C3.01256 9.32322 3.48744 9.32322 3.78033 9.03033L7.28033 5.53033C7.57322 5.23744 7.57322 4.76256 7.28033 4.46967L3.78033 0.96967C3.48744 0.676777 3.01256 0.676777 2.71967 0.96967C2.42678 1.26256 2.42678 1.73744 2.71967 2.03033L5.68934 5L2.71967 7.96967Z"
      fill="currentColor"
    />
  </Icon>
);
