import { Flex, Grid, Icon, LinkBox, LinkOverlay, Tag } from '@chakra-ui/react';
import { ArrowRightAnimated } from 'components/common/icons/assets/ArrowRightAnimated';

export default function Banner({ tag, text, linkText, link, backgroundImage, color }) {
  return (
    <LinkBox
      display="flex"
      color={color}
      backgroundImage={`url(${backgroundImage.filename})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center center"
      minHeight={{ base: '93px', desktop: '56px' }}
      justifyContent="center"
      alignItems="center"
      px="18px"
      height={{ base: '100px', desktop: '56px' }}
    >
      <Grid
        gridTemplateColumns={{ base: '1fr', desktop: 'auto 1fr auto' }}
        gridGap={{ base: '10px', desktop: 5 }}
        fontSize={{ base: 'sm', desktop: 'md' }}
      >
        <Tag colorScheme={color} borderRadius="4px" size="sm" display={{ base: 'none', desktop: 'inline-flex' }}>
          {tag}
        </Tag>
        <Flex>{text}</Flex>
        <LinkOverlay href={link.url} fontWeight="medium" display="inline-flex">
          {linkText}
          <Flex as="span" alignItems="center" ml="8px">
            <Icon as={ArrowRightAnimated} fontSize="15px" />
          </Flex>
        </LinkOverlay>
      </Grid>
    </LinkBox>
  );
}
