import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { VFC } from 'react';
import map from 'lodash/map';
import { RichTextProps } from 'components/common/RichText';
import { DynamicComponent } from '.';
import { theme } from 'theme';
import { useScrollSpy } from 'lib/hooks/useScrollSpy';

export type CustomerStoryContentProps = {
  customerStorySummary: any;
  richContent: RichTextProps;
};

const CustomerStoryContent: VFC<CustomerStoryContentProps> = ({ customerStorySummary, richContent }) => {
  const headings = extractHeadings(richContent);
  const sectionIds = ['summary', ...headings.map((heading) => heading.anchor)];
  const activeId = useScrollSpy(sectionIds);

  return (
    <HStack position="relative" alignItems="flex-start">
      <VStack
        position="sticky"
        display={{ base: 'none', desktop: 'flex' }}
        top="110px"
        left="0"
        alignItems="flex-start"
        width="25%"
        mt="104px"
        borderRadius="8px"
        p="16px"
        boxShadow={theme.shadows.lg}
      >
        <Text fontWeight={700} fontSize="17px" lineHeight="24px" color="#0B254B" py="8px" px="12px">
          In this story
        </Text>
        <VStack gap="4px" alignItems="flex-start">
          <Link
            borderRadius="4px"
            width="100%"
            px="16px"
            py="8px"
            href="#summary"
            fontWeight={400}
            fontSize="15px"
            lineHeight="20px"
            color={activeId === 'summary' ? '#0B254BB2' : '#0B254B80'}
            backgroundColor={activeId === 'summary' ? '#F3F5F8' : 'transparent'}
          >
            Summary
          </Link>
          {map(headings, (heading, index) => (
            <Link
              borderRadius="4px"
              px="16px"
              py="8px"
              href={`#${heading.anchor}`}
              fontWeight={400}
              fontSize="15px"
              lineHeight="20px"
              color={activeId === heading.anchor ? '#0B254BB2' : '#0B254B80'}
              backgroundColor={activeId === heading.anchor ? '#F3F5F8' : 'transparent'}
              key={index}
            >
              {heading.text}
            </Link>
          ))}
        </VStack>
      </VStack>
      <VStack width={{ base: '100%', desktop: '70%' }}>
        <Box id="summary">
          {map(customerStorySummary, (component, index) => (
            <DynamicComponent key={index} block={component} />
          ))}
        </Box>
        <Box>
          {map(richContent, (richText, index) => (
            <DynamicComponent key={index} block={richText} />
          ))}
        </Box>
      </VStack>
    </HStack>
  );
};

const extractHeadings = (richContent) => {
  const headings = [];

  richContent.map((richText) => {
    richText.component === 'richText' &&
      richText.content.content.map((block) => {
        if (block.type === 'heading') {
          const text = map(block.content, (item) => item.text).join(' ');
          const anchor = map(block.content, (item) =>
            map(item.marks, (mark) => (mark.type === 'anchor' ? mark.attrs.id : ''))
              .filter(Boolean)
              .join(''),
          ).join('');
          headings.push({ text, anchor });
        }
      });
  });

  return headings;
};

export default CustomerStoryContent;
