import ContainerTheme from 'theme/components/container';

export type Locale = 'en' | 'fr';

export enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
}

export type SectionProps<P = {}> = {
  size?: keyof typeof ContainerTheme['sizes'];
} & P;

export type GridProps = {
  columns: {
    content: unknown[];
    alignment: 'center' | 'left' | 'right';
    size: number;
  }[];
  noWrapInMobile?: boolean;
};

export namespace Storyblok {
  export type Metadata = {
    title: string;
    og_image: string;
    og_title: string;
    description: string;
    twitter_image: string;
    twitter_title: string;
    og_description: string;
    twitter_description: string;
  };
  export type Image = {
    alt: string;
    copyright: string;
    fieldtype: string;
    filename: string;
    id: number;
    name: string;
    title: string;
    height: string;
    width: string;
  };

  export type Video = {
    alt: string;            // Alternative text
    name: string;            // Name of the asset
    title?: string;          // Title for the asset
    filename: string;        // URL of the video file
    mime_type: string;       // MIME type, like 'video/mp4'
    extension: string;       // File extension, like 'mp4'
  };

  export type Button = {
    link: {
      url: string;
      cached_url: string;
      fieldtype: string;
      id: string;
      linktype: string;
      anchor?: string;
    };
    leftIcon: string;
    rightIcon: string;
    text: string;
    variant: 'solid' | 'link' | 'outline' | 'ghost';
    size: 'sm' | 'md' | 'lg';
    colorScheme: string;
  };

  export type HeaderVariant = 'default' | 'blog';

  export type Link = {
    url: string;
  };

  export type Author = {
    name: string;
    picture: Storyblok.Image;
  };

  export type RichText = {
    type: 'doc';
    content: {
      type: string;
      attrs?: any;
      content?: RichText['content'];
      text?: string;
    }[];
  };

  export type BlogCategory = {
    slug: string;
    name: string;
    content: {
      title: string;
    };
  };

  export type FormConfig = {
    requiredFieldMessage: string;
    invalidEmailMessage: string;
    personalEmailMessage: string;
    genericErrorMessage: string;
    botBlockedMessage: string;
  };

  export type Form = {
    slug: string;
    content: {
      text: { key: string; value: string }[];
    };
  };
}

declare global {
  interface Window {
    ChiliPiper?: any;
    hbspt?: any;
    lintrk?: any;
    storyblok?: any;
    grecaptcha?: {
      enterprise: {
        ready: (onReady: () => void) => void;
        execute: (siteKey: string, params: { action: string }) => Promise<string>;
      };
    };
  }
}
