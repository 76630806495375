import { Box, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { imageDimensions, parseBreakpointStr } from 'utils/storyblok';
import RichText from 'components/common/RichText';
import { ButtonGroup } from 'components/common/ButtonGroup';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import { SectionProps, Storyblok } from 'types';

type FeatureThreeProps = SectionProps<{
  title?: string;
  description?: Storyblok.RichText;
  columnOneOrder: string;
  columnTwoOrder: string;
  image: Storyblok.Image;
  gridItems?: FeatureThreeGridItem[];
  buttons?: Storyblok.Button[];
}>;

type FeatureThreeGridItem = {
  _uid: string;
  icon: string;
  title: string;
  description: string;
};

const FeatureThreeSection = ({
  size,
  title,
  description,
  columnOneOrder,
  columnTwoOrder,
  image,
  gridItems,
  buttons,
}: FeatureThreeProps) => {
  return (
    <Container as="section" size={size}>
      <Grid gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gridRowGap="24px">
        <Flex alignItems="center" order={parseBreakpointStr(columnOneOrder)}>
          <Box
            pr={{ base: 0, desktop: parseInt(parseBreakpointStr(columnOneOrder)?.desktop) === 1 && '120px' }}
            pl={{ base: 0, desktop: parseInt(parseBreakpointStr(columnOneOrder)?.desktop) === 2 && '120px' }}
          >
            {title && (
              <Heading as="h2" size="lg" mb="16px">
                {title}
              </Heading>
            )}
            <RichText content={description} indentList={false} />
            {gridItems?.length > 0 && (
              <Grid
                gridTemplateColumns={{ base: '1fr', desktop: '1fr 1fr' }}
                gridGap={{ base: '16px', desktop: '40px' }}
                mt="24px"
              >
                {gridItems.map(({ _uid, icon, title, description }) => {
                  return (
                    <Box key={_uid} mt="16px">
                      <Box as="figure" sx={{ svg: { height: '40px', width: '40px' } }}>
                        <DynamicIcon icon={icon} />
                      </Box>
                      <Heading as="h3" fontSize="md" lineHeight="26px" mt="16px">
                        {title}
                      </Heading>
                      <Text size="sm" mt="4px">
                        {description}
                      </Text>
                    </Box>
                  );
                })}
              </Grid>
            )}
            <ButtonGroup buttons={buttons} alignItems="flex-start" width="auto" mt="34px" />
          </Box>
        </Flex>
        <Flex order={parseBreakpointStr(columnTwoOrder)} borderRadius="4px" alignItems="center">
          <Box as="figure">
            <Image
              src={image.filename}
              alt={image.alt}
              width={imageDimensions(image.filename).width}
              height={imageDimensions(image.filename).height}
            />
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
};

export default FeatureThreeSection;
