import { Box, Grid } from '@chakra-ui/react';
import RichText from 'components/common/RichText';
import { parseBreakpointStr } from 'utils/storyblok';

export default function GridBlock({ columns, richTextProps }) {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', desktop: `repeat(${columns.length}, 1fr)` }}
      gridGap={{ base: '12px', desktop: '48px' }}
    >
      {columns.map((column, i) => (
        <Box key={i} margin={parseBreakpointStr(column.margin)} order={parseBreakpointStr(column.order)}>
          <RichText content={column.content} {...richTextProps} />
        </Box>
      ))}
    </Grid>
  );
}
