export const parseBreakpointStr = (str) => {
  function stringify(string) {
    return string
      .replace('base:', '"base":')
      .replace('tablet:', '"tablet":')
      .replace('desktop:', '"desktop":')
      .replace('large:', '"large":');
  }

  return str?.startsWith('base') ? JSON.parse(`{${stringify(str)}}`) : str;
};

export const normalizeUrl = (url) => (url.startsWith('//') ? 'https:' + url : url);

export const imageDimensions = (url) => {
  return {
    width: +url.split('/')[5]?.split('x')[0],
    height: +url.split('/')[5]?.split('x')[1],
  };
};
